// Classe per  nominare correttamente i campi da/verso le chiamate API
class dataMask {
    
    encode = (payload) => {
            let newPayload = {
                IDUtente: payload.IDUtente, 
                IDPreferito: payload.IDPreferito,
            }
            return newPayload;
        }
    
    decode = (payload) => {
            let newPayload = {
                IDUtente: payload.IDUtente, 
                IDPreferito: payload.IDPreferito,
            }
            return newPayload;
        }    
}

var modificaPreferito = new dataMask();
export default modificaPreferito


