// Componenti React
import React, {Component} from 'react';
// Compose per utilizzare multipli Wrapper (withstyle e withcookies)
import { compose } from 'recompose'

// Componenti Material UI
import { DataGrid, RowsProp, ColDef } from '@material-ui/data-grid';
import { withStyles } from '@material-ui/core/styles';

// Gestione dei Cookies
import {withCookies} from 'react-cookie';


// Stili
const styles = {

};




// Class Component
class WorkInProgress extends Component {

  dbPartite = [];
  dbApiPartite = [
    {
      "id": 1,
      "DataPartita": "2020-11-23",
      "Vincitori": 1,
      "S1_1": 1,
      "S1_2": 2,
      "S2_1": 3,
      "S2_2": 4,
      "games": [
        [
          5,
          3
        ],
        [
          0,
          5
        ],
        [
          5,
          1
        ],
        [
          5,
          2
        ],
        [
          5,
          0
        ]
      ]
    },
    {
      "id": 2,
      "DataPartita": "2020-11-23",
      "Vincitori": 2,
      "S1_1": 1,
      "S1_2": 3,
      "S2_1": 4,
      "S2_2": 2,
      "games": [
        [
          3,
          5
        ],
        [
          0,
          5
        ],
        [
          1,
          5
        ]
      ]
    },
    {
      "id": 3,
      "DataPartita": "2020-12-09",
      "Vincitori": 1,
      "S1_1": 1,
      "S1_2": 2,
      "S2_1": 3,
      "S2_2": 4,
      "games": [
        [
          5,
          3
        ],
        [
          1,
          5
        ],
        [
          5,
          0
        ]
      ]
    }
  ]

  componentDidMount(){
  }

  render(){
    let cookieUserToken ="Non settato" 
    const {login}   =  this.props
    const {datiUtente} = this.props;

    // Verifico se presenti i cookie del Login (se presenti, simulo il login)
    const {cookies} = this.props
    if(cookies.get('userToken') !== null && cookies.get('userToken') !=='' ) {
      cookieUserToken = cookies.get('userToken')
    }


    const rows: RowsProp = [
      { id: 1, col1: 'Login', col2: login , },
      { id: 2, col1: 'Token React', col2: datiUtente.token, },
      { id: 3, col1: 'Nome', col2:  datiUtente.nome , },
      { id: 4, col1: 'Cookies', col2:  cookieUserToken , },
    ];
    
    const columns: ColDef[] = [
      { field: 'col1', headerName: 'Variabile', width: 150  },
      { field: 'col2', headerName: 'Valore',    width: 150  },
    ];
  
    return <div style={{ height: 300, width: '100%' }}>
            <DataGrid rows={rows} columns={columns} hideFooter={true}/>
           </div>


    // Test conversione
  }
}


export default compose(
    withStyles(styles),
    withCookies,  
  )   (WorkInProgress)