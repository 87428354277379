import React, {Component, Fragment} from 'react';
import queryString from 'query-string'

// Chiamate API Rest
import API from "../../../helpers/API/apiCaller/APIManager"

import {Link} from 'react-router-dom'
import { withRouter } from 'react-router-dom';

// Compose per utilizzare multipli Wrapper (withstyle e withcookies)
import { compose } from 'recompose'

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import CheckIcon from '@material-ui/icons/Check';

// Utilizzo tema standard
const theme = createMuiTheme();

const styles = {
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#19d3da',
    // borderColor: '#19d3da',
    // width: theme.spacing(10),
    // height: theme.spacing(10),
  },

  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor:'#373a40',
    "&:hover": {
      backgroundColor: "#373a40"
    },
    color: 'white',

  },

  textField: {
    '& label.Mui-focused': {
      color: '#19d3da',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {

      },
      '&.Mui-focused fieldset': {
        borderColor: '#19d3da',
      },
      
   },
  },

  textFieldSuccess: {
    // Label on focus on
    '& label.Mui-focused': {
      color: '#95d5b2',
    },
    // Campo input 
    '& .MuiOutlinedInput-root': {

      // Default
      '& fieldset': {
        borderColor: '#95d5b2',
        borderWidth: 2,
      },

      // Default mouse over
      '&:hover fieldset': {
        borderColor: '#95d5b2',
        borderWidth: 2,
      },
      // Quando sono dentro al campo
      '&.Mui-focused fieldset': {
        borderColor: '#95d5b2',
        borderWidth: 2,
      },

   },
  },


  link: {
    color:'#373a40',
  },

  snackbar: {
    bottom: 30,
  }

};



class ResetPassword extends Component {

  constructor(props) {
    super(props);
    this.state = {
      input: {
        password: '',
        confermaPassword: '',
      },
      errori: {
        password: false,
        confermaPassword: false, 
      },
      validazione: {
        password: false,
        confermaPassword: false, 
      },
      utility: {
        mostraPassword: false,
        mostraHelper: false,
      },   
      nome: '',
      cognome: '',
      avatarData: '',
      messaggioErrore: '',
      passwordModificata: false,
      token: '',
    }
  

 }

  // Precarico il DB utente
  componentDidMount(){

    // In primis, ffettuo il logout preventivo (qualora fossi logato come altro utente su quel browser)
    this.props.onLogout();
    
    // Leggo i parametri dell'URL 
    //...ResetPassword?k=tokenResetPassword
    const parametriURL = queryString.parse(this.props.location.search)
    
    // Ricavo i dati dell'utente
    this.ricavaDatiUtente(parametriURL.k);
  }

   //Funzione di gestione modifica valore di campo di input 
  onModificaInput = (event) => {
    let {input} = this.state
    input[event.target.name] = event.target.value
    this.setState({input}, () => this.verificaInput())   
  }
  
 
  // Aggiorno i dati utente, in funzione del token di ritorno
  // Aggiorno i dati utente, in funzione del token di ritorno
  ricavaDatiUtente = (token) => {
    let payload = {} 
    let header = {token: token}

    //Effettuo la chiamata al servizio API e aggiorno lo stato con i dati dell'utente
    // Ritorno true se l'utente esiste ed ho aggiornato lo stato con i suoi dati, false se non esiste
    API.get(
      'utente', // dataType (econde/decode API)
      'utente', 
      payload, 
      (datiUtenteAutenticato) => {
        // Ricavo solo i dati che mi servono dalla tabella utente
        this.setState({
          nome: datiUtenteAutenticato.nome,
          cognome: datiUtenteAutenticato.cognome,
          avatarData: datiUtenteAutenticato.avatarUrl,
          token: token, 
          })      
      }, 
      (error) => {
        // Torno alla homepage se l'utente non è riconosciuto
        this.props.history.push("/")     
      }, 
      header
    )
      
  }
  
  // Funzione di gestione cambio visibilità password
  onCambioVisibilitaPsw = () => {
    let {utility} = this.state
    utility['mostraPassword'] = !utility['mostraPassword'] 
    this.setState({utility})
  };

  // Funzione per verificare la correttezza dei campi inseriti
  verificaInput = () => {
    let {input} = this.state
    let {errori} = this.state
    let {validazione} = this.state

    // Password
    if (!this.isPasswordSicura(input.password)) {
      errori['password'] = 'Almeno una lettera maiuscola e un numero'
      validazione['password'] = false
    } else 
    {
      errori['password'] = ''
      validazione['password'] = true
    }

    // Conferma password
    if(validazione['password'] && (input.password === input.confermaPassword)){
      validazione['confermaPassword'] = true
      errori['confermaPassword'] = ''
    }else
    {
      validazione['confermaPassword'] = false
      errori['confermaPassword'] = 'Verifica Password'
    }

    // Aggiorno i campi di validazione del form 
    this.setState({errori})
    this.setState({validazione})

  }

  // Utilità di validazione Password
  isPasswordSicura = (val) => {
    // let regPsw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
    // if(!regPsw.test(val))
    //   return false
    return true
}

  // Funzione per evitare l'utente clicci sul campo password.. forse per motivi di sicurezza?!
  gestioneMouseDownPassword = (event) => {
    event.preventDefault();
  };

    // Funzione di gestione pulsante "Accedi" (tramite API)
  onPressConferma = (event) => {

     // Evito che il form segua il suo comportamento di default, ovvero aprire una pagine di destinazione (<Form action="...">)
    event.preventDefault();
    
    // Verifico che tutti i campi siano correttamente compilati
    // Converto l'oggetto "validazione" in un array
    const validazioneArray = Object.values(this.state.validazione);
    // Riduco l'array ad un unico valore booleano (combinazione &&)
    const reducer = (accumulatore, valore) => accumulatore && valore
  
    // Se tutti i campi sono validati
    if(validazioneArray.reduce(reducer,true)) {
      let {input} = this.state
      //Effettuo la chiamata al servizio API
      let payload = {
        password: input.password,
        confermaPassword: input.confermaPassword
      }
      
      // Passo il token autorizzativo all'API
      let header = {token: this.state.token}

      API.post('reset', 'cambioPassword', payload, this.aggiornamentoDatiAvvenuto, this.aggiornamentoDatiErrore, header) 
    
  } else
    {
      // Non tutti i campi sono validati, quindo mostro tutti gli Helper e messaggio generale
      let {utility} = this.state
      utility['mostraHelper'] = true;
      this.setState({utility})
      this.setState({messaggioErrore: "Compilare correttamente tutti i campi"})
    }

  }

  // Funzione per la gestione della chiusura pop-over di credenziali errate
  onChiusuraMessaggioErrore = (event, reason) => {
    this.setState({messaggioErrore: ''})
  };

  // Funzione di gestione cambio password avvenuta effettuata
  aggiornamentoDatiAvvenuto = (SchedaUtente) => {
    this.setState({passwordModificata:true})

    // Rieffettuo il login 
    let payload = {
      token: this.state.token,
    }
    this.props.onLogin(payload)
    }

  // Funzione di gestione iscrizione errata
  aggiornamentoDatiErrore = (datiErrore) => {
    this.setState({messaggioErrore: datiErrore.error})
  }


  render(){
    const {classes} = this.props;
    const {input, validazione, errori, utility}   = this.state
    const {nome, cognome} = this.state

    return (
      <Fragment>
      {
        (!this.state.passwordModificata)
        ?  
        <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            Reset password
          </Typography>
          <Typography>
            {nome+ ' ' + cognome}
          </Typography>
            
          <Avatar className={classes.avatar} src={this.state.avatarData}>
          </Avatar>

          <form className={classes.form} onSubmit={this.onPressConferma} >
            <TextField
              size="small"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Nuova password"
              type={utility.mostraPassword ? 'text' : 'password'}
              id="password"
              value={input.password}
              onChange={this.onModificaInput}
              classes= {validazione.password ? {root: classes.textFieldSuccess}: {root: classes.textField} }
              autoComplete="off"
              helperText = {utility.mostraHelper ? errori.password : ''}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                        aria-label="Vedi o nascondi password"
                        onClick={this.onCambioVisibilitaPsw}
                        onMouseDown={this.gestioneMouseDownPassword}
                        edge="end"
                    ></IconButton>
                    {utility.mostraPassword ? <VisibilityOff/> : <Visibility />}
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              size="small"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="confermaPassword"
              label="Conferma password"
              type={utility.mostraPassword ? 'text' : 'password'}
              id="confermapassword"
              value={input.confermaPassword}
              onChange={this.onModificaInput}
              helperText = {utility.mostraHelper ? errori.confermaPassword : ''}
              classes= {validazione.confermaPassword ? {root: classes.textFieldSuccess}: {root: classes.textField} }
              autoComplete="off"
            />

            <Button
              disabled = {!validazione.confermaPassword}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Conferma
            </Button>
          </form>
        </div>

        <Snackbar  open={this.state.messaggioErrore !== ''? true : false} autoHideDuration={4000} className={classes.snackbar}  onClose={this.onChiusuraMessaggioErrore} >
                <Alert
                  variant="filled"
                  elevation={6}
                  severity="error"
                  action={
                    <CloseIcon />
                  }
                  onClick={this.onChiusuraMessaggioErrore}
                  >
                  {this.state.messaggioErrore}
                </Alert>
        </Snackbar>
      </Container>
      :
        <Container component="main" maxWidth="xs">
        <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <CheckIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Password modificata
            </Typography>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              component={Link} 
              to="/"
            >
             Vai all'homepage
            </Button>          

          </div>  

        </Container>    
      } 
      </Fragment> 
    );
  }
}

// Esporto anche gli stili presenti in questo file, con la classe Header
export default compose(
  withStyles(styles),
  withRouter, 
) (ResetPassword)