// Classe per  nominare correttamente i campi da/verso le chiamate API
class dataMask {
    
    encode = (payload) => {
                let newPayload = {}
                let NewGames = [];
                payload.Games.map((game, index) => {
                    if(NewGames.length < 3)  {
                        NewGames = [...NewGames, [
                                    game.Squadra1,
                                    game.Squadra2
    ]                                ]     
                    } // Massimo 3 Games per partita    
                    return true
                })

                newPayload = {
                    "DataPartita": payload.DataPartita,
                    "S1_1": payload.Giocatori.ID1,
                    "S1_2": payload.Giocatori.ID2,
                    "S2_1": payload.Giocatori.ID3,
                    "S2_2": payload.Giocatori.ID4,
                    "games": NewGames,
                }

                return newPayload;
        }

    decode = (payload) => {
        let nuovaPartita = {}
        let NewGames = [];
        payload.games.map((game, index) => {
            if(NewGames.length < 3)  {
                NewGames = [...NewGames, {
                            Squadra1: game[0],
                            Squadra2: game[1]
                            } 
                        ]     
            } // Massimo 3 Games per partita    
            return true
        })
        
        nuovaPartita = {          
            ID: payload.id,
            DataPartita: payload.DataPartita,
            Vincitore: payload.Vincitori,
            Giocatori:
            {
                ID1: payload.S1_1,
                ID2: payload.S1_2,
                ID3: payload.S2_1,
                ID4: payload.S2_2,
            },
            Games: NewGames,
        }
        return nuovaPartita       
    }

}

var nuovaPartita = new dataMask();
export default nuovaPartita


