// Classe per  nominare correttamente i campi da/verso le chiamate API
class dataMask {
    
    decode = (payload) => {
        let nuovaPartita = {}
        let newPartite = [];
        let NewGames = [];
        payload.data.map((partita, index) => {
                // Ricreo l'array dei games, secondo la struttura prevista nel frontend
                NewGames = [];
                partita.games.map((game, index) => {
                    if(NewGames.length < 3)  {
                        NewGames = [...NewGames, {
                                    Squadra1: game[0],
                                    Squadra2: game[1]
                                    } 
                                ]     
                    } // Massimo 3 Games per partita    
                    return true
                })
                
                nuovaPartita = {          
                    ID: partita.id,
                    DataPartita: partita.DataPartita,
                    Vincitore: partita.Vincitori,
                    IS_Confermata: partita.IS_Confermata,
                    Giocatori:
                    {
                    ID1: partita.S1_1,
                    Nome1:partita.S1_G1.nome,
                    Cognome1:partita.S1_G1.cognome,
                    Avatar1: partita.S1_G1.Immagine,

                    ID2: partita.S1_2,
                    Nome2:partita.S1_G2.nome,
                    Cognome2:partita.S1_G2.cognome,
                    Avatar2: partita.S1_G2.Immagine,    
                                   
                    ID3: partita.S2_1,
                    Nome3:partita.S2_G1.nome,
                    Cognome3:partita.S2_G1.cognome,
                    Avatar3: partita.S2_G1.Immagine,   

                    ID4: partita.S2_2,
                    Nome4:partita.S2_G2.nome,
                    Cognome4:partita.S2_G2.cognome,
                    Avatar4: partita.S2_G2.Immagine,                       
                    },
                    Games: NewGames,
                    }
                // Ricreo l'array delle partite, secondo la struttura prevista nel frontend
                newPartite = [...newPartite, nuovaPartita]      
                return true              
        })                
        return newPartite
        }
    
       
}

var partite = new dataMask();
export default partite


