// Componenti React
import React,{Component} from 'react';

// Componenti Material UI
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';


const styles = {

  card: {
    minWidth: 275,
    height: 80,
    marginTop: 10,
    marginBottom: 10,
    display:'flex',
    flexGrow: 0,

  },

  card_image: {
    width: 80,
    flexShrink: 0,
  },

  card_datigiocatore:{
    flexGrow: 1,

  },

  card_datigiocatore_titolo:{
    height:25,
    overflow:'hidden',

  },
  card_datigiocatore_sottotitolo: {
    fontSize: 12,
  },

  card_rank_M: {
    width: 70,
    flexShrink: 0,
    backgroundColor: '#eeeeee',
  },

  card_rank_F: {
    width: 70,
    flexShrink: 0,
    backgroundColor: '#fff0f0'
  },

};

class ClassificaCard extends Component {

  render(){

    // Variabili props
    const {giocatore} = this.props;
    const {rank} = this.props;
    const {classes} = this.props;

    return (
              <Card className={classes.card} variant="outlined">
                <CardMedia
                  className={classes.card_image}
                  image={giocatore.Avatar}
                  title={`${giocatore.Nome} ${giocatore.Cognome}`}
                />
              <CardContent className={classes.card_datigiocatore}>
                  <Typography className={classes.card_datigiocatore_titolo}>{`${giocatore.Nome} ${giocatore.Cognome}`}</Typography>
                  <Typography className={classes.card_datigiocatore_sottotitolo} color="textSecondary">Aggiornato al 25-02-2021 </Typography>
                </CardContent>
                <CardContent className={giocatore.Genere === 'F' ? classes.card_rank_F : classes.card_rank_M}>
                  <Typography align='center' variant='caption'> </Typography>
                  <Typography align='center' variant='h6'> {giocatore.Rank} </Typography>
                </CardContent>

              </Card>
    )
  }
}


export default withStyles(styles)(ClassificaCard)
