// Restituisce un DIV di altezza predefinita, utile per distanziamento footer
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

var title=[]
title[-1] = "Login"
title[0] = "Home"
title[1] = "Giocatori"
title[2] = "Partite"
title[3] = "Rank"

var description = []
description[-1] = "Per eventuali richieste di aiuto in questa schermata, scrivere a help@padelrank.it"
description[0] = "Usa il menu inferiore per navigare tra le sezioni. Clicca invece sull'icona in alto a sinistra, a fianco del titolo 'PadelRank', per accedere al tuo menu personale"
description[1] = "Usa questa sezione per cercare i tuoi amici, aggiungerli al tuo elenco dei preferiti (tramite la stellina sulla destra), e poter cosi visualizzarli all'interno della classifica."
description[2] = "Qui trovi l'elenco delle tue partite, ordinate per data."
description[3] = "Qui puoi vedere la classifica tua e dei tuoi giocatori preferiti, aggiornata ogni settimana. Il tuo rank si aggiorna dopo almeno 5 partite giocate."

function Help(props) {
 
  return <Dialog
            open={props.helpAperto}
            onClose={props.gestioneHelp(false)}
            scroll="paper"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <DialogTitle id="scroll-dialog-title">{title[props.contenuto]}</DialogTitle>
            <DialogContent dividers={true}>
              <DialogContentText
                id="scroll-dialog-description"
                tabIndex={-1}
                align="justify"
              >
                {description[props.contenuto]}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={props.gestioneHelp(false)} className={props.buttonClass}>
                Chiudi
              </Button>
            </DialogActions>
          </Dialog>

}
 
export default Help;