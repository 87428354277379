// Componenti React
import React,{Component,} from 'react';
// import sortBy from 'sort-by';
// import serializeForm from 'form-serialize';
import moment from 'moment';
// import {Redirect} from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string'

// Compose per utilizzare multipli Wrapper (withstyle e withcookies)
import { compose } from 'recompose'

// Componenti PadelRank
import DataItaliana from "../../common/DataItaliana"

// Componenti Material UI
// import CheckIcon from '@material-ui/icons/Check';
// import Fab from '@material-ui/core/Fab';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
// import Snackbar from '@material-ui/core/Snackbar';
// import Alert from '@material-ui/lab/Alert';
// import Menu from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';
import Container from '@material-ui/core/Container';
// import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
//import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';
// import Button from '@material-ui/core/Button';
// import TextField from '@material-ui/core/TextField';


// import TextField from '@material-ui/core/TextField';
//import Input from '@material-ui/core/Input';
//import IconButton from '@material-ui/core/IconButton';
//import MoreVertIcon from '@material-ui/icons/MoreVert';
// import CloseIcon from '@material-ui/icons/Close';
// //import RepeatIcon from '@material-ui/icons/Repeat';
// import EventIcon from '@material-ui/icons/Event';

// Componenti per azioni su REST API
import API from "../../../helpers/API/apiCaller/APIManager"

// Stili
const styles = {

  menu: {
    color: 'grey',
  },

  dataPartita: {
      display: 'flex',

      '& label.Mui-focused': {
         color: '#19d3da',
       },

       '& .MuiInput-underline:after': {
         borderBottomColor: '#19d3da',
       },

    },

  inputDataPartita: {
      textAlign: 'center',
    },

  card: {
    marginTop: 10,
    marginBottom: 10,
  },

  card_header: {
    backgroundColor: '',
  },

  avatarA:{
    borderColor: 'grey',
    borderStyle: 'solid',
    height: '50px',
    width: '50px',
  },
  avatarB:{
    borderColor: '#19d3da',
    borderStyle: 'solid',
    height: '50px',
    width: '50px',
  },

  avatarSelezionato:{
    borderColor: '#19d3da',
    borderStyle: 'dashed',
    height: '50px',
    width: '50px',
  },


  gridItem_giocatore: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection:'column',
    backgroundColor:'',
  },

  gridItem_giocatore_nome: {
    fontSize: 12,
  },

  gridItem_punteggio: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection:'column',
    // backgroundColor:'lightgrey',
  },

  gridItem_paper: {
    width: "100%",
    height: "100%",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderStyle: 'dashed',
  },

  gridItem_paper_win: {
    width: "100%",
    height: "100%",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: '#373a40',
    borderStyle: 'dashed',
  },

  gridItem_paper_testo: {
    fontWeight: "lighter",
  },

  inputPunteggio: {
      fontSize: 30,
      textAlign: 'center',
    },

  fab: {
    position: 'fixed',
    bottom: 85,
    right: 15,
    backgroundColor:'#19d3da',
    color: 'white',
  },

  fab_off: {
    position: 'fixed',
    bottom: 70,
    right: 10,
    backgroundColor:'lightgrey',
    color: 'white',
  },

  ricerca: {
      display: 'flex',

      '& label.Mui-focused': {
         color: '#19d3da',
       },

       '& .MuiInput-underline:after': {
         borderBottomColor: '#19d3da',
       },

    },

    snackbar: {
      bottom: 90,
    }

};

// Class Component
class Partita extends Component {

  // Perchè utilizzare il constructor ?!
  constructor(props) {
      super(props);
      this.state = {
          ancoraMenu: '',
          giocatoriInseriti:0,
          punteggiInseriti: 0,
          partitaInserita: false,
          popupModificaData: false,
          messaggioErrore:'',
          ricercaGiocatore:'',
          posizioneGiocatore: '',
          dataPartita: new moment().format('YYYY-MM-DD'),

          giocatoreA1_ID: '',
          giocatoreA1_Label: '...',
          giocatoreA1_AvatarUrl: '',

          giocatoreA2_ID: '',
          giocatoreA2_Label: '...',
          giocatoreA2_AvatarUrl: '',


          giocatoreB1_ID: '',
          giocatoreB1_Label: '...',
          giocatoreB1_AvatarUrl: '',

          giocatoreB2_ID: '',
          giocatoreB2_Label: '...',
          giocatoreB2_AvatarUrl: '',

          punteggio1A:'',
          punteggio2A:'',
          punteggio3A:'',
          punteggio1B:'',
          punteggio2B:'',
          punteggio3B:'',

          dbGiocatori: [],

        }

        
   }

  componentDidMount() {
    let token = ''

    // Se la chiamata arriva da un utente loggato, mostro direttamente la partita
    if(this.props.token){
        token = this.props.token
        this.ricavaDatiPartita(token, this.props.match.params.partitaID)
      }
    else
      {
        // Se invece non sono loggato, faccio prima il login tramite il token
        const parametriURL = queryString.parse(this.props.location.search)
        if(parametriURL.k !== '')
          token = parametriURL.k       
        // Ricavo i dati dell'utente e poi della partita
        this.ricavaDatiUtente(token);
      }
     
  }

  // Aggiorno i dati utente, in funzione del token 
  ricavaDatiUtente = (token) => {
    let payload = {} 
    let header = {token: token}

    //Effettuo la chiamata al servizio API e aggiorno lo stato con i dati dell'utente
    // Ritorno true se l'utente esiste ed ho aggiornato lo stato con i suoi dati, false se non esiste
    API.get(
      'utente', // dataType (econde/decode API)
      'utente', 
      payload, 
      (datiUtenteAutenticato) => {
        //Effettuo il login
        this.props.onLogin({token:token})   
        // Ricavo i dati della partita
        this.ricavaDatiPartita(token, this.props.match.params.partitaID)   
      }, 
      (error) => {
        // Torno alla homepage se l'utente non è riconosciuto
        this.props.history.push("/")     
      }, 
      header
    )
      
  }

  // Aggiorno i dati utente, in funzione del token di ritorno
  ricavaDatiPartita = (token, IDPartita) => {
    let payload = {appendURL: IDPartita}
    let header = {token: token}

    //Effettuo la chiamata al servizio API e aggiorno lo stato con i dati della partita
    API.get(
      'partita', // dataType (econde/decode API)
      'partite', 
      payload, 
      (datiPartita) => {
          this.setState({
            idPartita: IDPartita,
            giocatoriInseriti:true,
            punteggiInseriti: true,
            dataPartita: datiPartita.DataPartita,

            giocatoreA1_ID: datiPartita.Giocatori.ID1,
            giocatoreA1_Label: datiPartita.Giocatori.Nome1 + ' ' + datiPartita.Giocatori.Cognome1[0]+'.',
            giocatoreA1_AvatarUrl: datiPartita.Giocatori.Avatar1,

            giocatoreA2_ID: datiPartita.Giocatori.ID2,
            giocatoreA2_Label: datiPartita.Giocatori.Nome2 + ' ' + datiPartita.Giocatori.Cognome2[0]+'.',
            giocatoreA2_AvatarUrl: datiPartita.Giocatori.Avatar2,

            giocatoreB1_ID: datiPartita.Giocatori.ID3,
            giocatoreB1_Label: datiPartita.Giocatori.Nome3 + ' ' + datiPartita.Giocatori.Cognome3[0]+'.',
            giocatoreB1_AvatarUrl: datiPartita.Giocatori.Avatar3,

            giocatoreB2_ID: datiPartita.Giocatori.ID4,
            giocatoreB2_Label: datiPartita.Giocatori.Nome4 + ' ' + datiPartita.Giocatori.Cognome4[0]+'.',
            giocatoreB2_AvatarUrl: datiPartita.Giocatori.Avatar4,
            
            punteggio1A: datiPartita.Games[0] ? datiPartita.Games[0].Squadra1 : '',
            punteggio2A: datiPartita.Games[1] ? datiPartita.Games[1].Squadra1 : '',
            punteggio3A: datiPartita.Games[2] ? datiPartita.Games[2].Squadra1 : '',
            punteggio1B: datiPartita.Games[0] ? datiPartita.Games[0].Squadra2 : '',
            punteggio2B: datiPartita.Games[1] ? datiPartita.Games[0].Squadra2 : '',
            punteggio3B: datiPartita.Games[2] ? datiPartita.Games[0].Squadra2 : '',
        })     
      }, 
      () => {return false},
      header,
    )
      
  }



  render(){

    const {classes} = this.props;

    return(
          (
            <Container component="main" maxWidth="sm">
            <Card className={classes.card} variant="outlined">
              <CardHeader
                  className={classes.card_header}
                  title={ <Typography> <DataItaliana data={this.state.dataPartita} tipo="TXT"/> </Typography>}
                  />

              <CardContent>
                <Grid container spacing={1}>
                  <Grid xs={3} className={classes.gridItem_giocatore} item>
                    <Avatar className={classes.avatarA} style={ this.state.posizioneGiocatore === 'A1' ? {borderStyle: 'dashed'} : {} } src={this.state.giocatoreA1_AvatarUrl} onClick={()=>this.onRicercaGiocatore('A1')} />
                    <Typography className={classes.gridItem_giocatore_nome}> {this.state.giocatoreA1_Label} </Typography>
                  </Grid>
                  <Grid xs={3} className={classes.gridItem_giocatore} item>
                    <Avatar className={classes.avatarA} style={ this.state.posizioneGiocatore === 'A2' ? {borderStyle: 'dashed'} : {} } src={this.state.giocatoreA2_AvatarUrl} onClick={()=>this.onRicercaGiocatore('A2')} />
                    <Typography className={classes.gridItem_giocatore_nome}> {this.state.giocatoreA2_Label} </Typography>
                  </Grid>
                  <Grid xs={2} className={classes.gridItem_punteggio} item>
                    <Paper variant="outlined" className={classes.gridItem_paper}>
                      <Typography className={classes.inputPunteggio}> {this.state.punteggio1A} </Typography> 
                    </Paper>
                  </Grid>
                  <Grid xs={2} className={classes.gridItem_punteggio} item>
                    <Paper variant="outlined" className={classes.gridItem_paper}>
                      <Typography className={classes.inputPunteggio}> {this.state.punteggio2A} </Typography> 
                    </Paper>
                  </Grid>
                  <Grid xs={2} className={classes.gridItem_punteggio} item>
                    <Paper variant="outlined" className={classes.gridItem_paper}>
                       <Typography className={classes.inputPunteggio}> {this.state.punteggio3A} </Typography>
                    </Paper>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid xs={3} className={classes.gridItem_giocatore} item>
                      <Avatar className={classes.avatarB} style={ this.state.posizioneGiocatore === 'B1' ? {borderStyle: 'dashed'} : {} } src={this.state.giocatoreB1_AvatarUrl} onClick={()=>this.onRicercaGiocatore('B1')}/>
                      <Typography className={classes.gridItem_giocatore_nome}> {this.state.giocatoreB1_Label} </Typography>
                    </Grid>
                    <Grid xs={3} className={classes.gridItem_giocatore} item>
                      <Avatar className={classes.avatarB} style={ this.state.posizioneGiocatore === 'B2' ? {borderStyle: 'dashed'} : {} } src={this.state.giocatoreB2_AvatarUrl} onClick={()=>this.onRicercaGiocatore('B2')}/>
                      <Typography className={classes.gridItem_giocatore_nome}> {this.state.giocatoreB2_Label} </Typography>
                    </Grid>
                    <Grid xs={2} className={classes.gridItem_punteggio} item>
                      <Paper variant="outlined" className={classes.gridItem_paper}>
                        <Typography className={classes.inputPunteggio}> {this.state.punteggio1B} </Typography>
                      </Paper>
                    </Grid>
                    <Grid xs={2} className={classes.gridItem_punteggio} item>
                      <Paper variant="outlined" className={classes.gridItem_paper}>
                        <Typography className={classes.inputPunteggio}> {this.state.punteggio2B} </Typography>
                      </Paper>
                    </Grid>
                    <Grid xs={2} className={classes.gridItem_punteggio} item>
                      <Paper variant="outlined" className={classes.gridItem_paper}>
                        <Typography className={classes.inputPunteggio}> {this.state.punteggio3B} </Typography>
                      </Paper>
                    </Grid>
                  </Grid>
              </CardContent>
            </Card>
          </Container>
          )       
    )
  }
}

export default compose(
  withStyles(styles),
  withRouter, 
) (Partita)

