import React,{Component} from 'react';
import queryString from 'query-string'
import { withRouter } from 'react-router-dom';

// Compose per utilizzare multipli Wrapper (withstyle e withcookies)
import { compose } from 'recompose'

// Chiamate API Rest
import API from "../../../helpers/API/apiCaller/APIManager"

// import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CssBaseline from '@material-ui/core/CssBaseline';
// import LinkUI from '@material-ui/core/Link';
// import Box from '@material-ui/core/Box';
// import SportsTennisIcon from '@material-ui/icons/SportsTennis';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
// import Slider from '@material-ui/core/Slider';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

// import { useEventCallback } from '@material-ui/core';

// // Functional component per visualizzazione Copyright
// function Copyright() {
//   return (
//     <Typography variant="body2" color="textSecondary" align="center">
//       {'Copyright © '}
//       <LinkUI color="inherit" href="https://PadelRank.it">
//         PadelRank.it
//       </LinkUI>{' '}
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// }

// Utilizzo tema standard
const theme = createMuiTheme();

const styles = {
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },


  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor:'#373a40',
    "&:hover": {
      backgroundColor: "#373a40"
    },
    color: 'white',

  },

  textField: {
    '& label.Mui-focused': {
      color: '#19d3da',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {

      },
      '&.Mui-focused fieldset': {
        borderColor: '#19d3da',
      },
      
   },
  },

  textFieldSuccess: {
    // Label on focus on
    '& label.Mui-focused': {
      color: '#95d5b2',
    },
    // Campo input 
    '& .MuiOutlinedInput-root': {

      // Default
      '& fieldset': {
        borderColor: '#95d5b2',
        borderWidth: 2,
      },

      // Default mouse over
      '&:hover fieldset': {
        borderColor: '#95d5b2',
        borderWidth: 2,
      },
      // Quando sono dentro al campo
      '&.Mui-focused fieldset': {
        borderColor: '#95d5b2',
        borderWidth: 2,
      },

   },
  },

  titolo: {
    margin: theme.spacing(2, 0, 1),
  },

  link: {
    color:'#373a40',
  },

  snackbar: {
    bottom: 30,
  }

};


// const RadioMaschio = withStyles({
//   root: {
//     color:  '#19d3da',
//     '&$checked': {
//       color:  '#19d3da',
//     },
//   },
// })(Radio);

// const RadioFemmina = withStyles({
//   root: {
//     color: '#19d3da',
//     '&$checked': {
//       color: '#19d3da',
//     },
//   },
// })(Radio);

// const LevelSlider =  withStyles({
//   root: {
//     color: '#19d3da',
//     height: 8,
//   },
//   thumb: {
//     height: 24,
//     width: 24,
//     backgroundColor: '#fff',
//     border: '2px solid currentColor',
//     marginTop: -8,
//     marginLeft: -12,
//     '&:focus, &:hover, &$active': {
//       boxShadow: 'inherit',
//     },
//   },
//   active: {},
//   valueLabel: {
//     left: 'calc(-50% + 4px)',
//   },
//   track: {
//     height: 8,
//     borderRadius: 0,
//   },
//   rail: {
//     height: 8,
//     borderRadius: 0,
//   },
// })(Slider);


class DatiAnagrafici extends Component {

  constructor(props) {
    super(props);
    this.state = {
      input: {
        nome: '',
        cognome: '',
      }, 
      errori: {
        nome: '',
        cognome: '',
      },
      validazione: {
        nome: '',
        cognome: '',
      },
      utility: {
        mostraHelper: false,
      },
      avatarData: '',
      messaggioErrore: '',
      statoutente: '',
      token: '',
    }

    
 }

   // Precarico il DB utente
  componentDidMount(){

    // In primis, effettuo il logout preventivo (qualora fossi logato come altro utente su quel browser)
    this.props.onLogout();
    
    // Leggo i parametri dell'URL 
    //...ResetPassword?k=tokenResetPassword
    const parametriURL = queryString.parse(this.props.location.search)
    
    // Ricavo i dati dell'utente
    this.ricavaDatiUtente(parametriURL.k);
  }

  // Aggiorno i dati utente, in funzione del token 
  ricavaDatiUtente = (token) => {
    let payload = {} 
    let header = {token: token}

    //Effettuo la chiamata al servizio API e aggiorno lo stato con i dati dell'utente
    // Ritorno true se l'utente esiste ed ho aggiornato lo stato con i suoi dati, false se non esiste
    API.get(
      'utente', // dataType (econde/decode API)
      'utente', 
      payload, 
      (datiUtenteAutenticato) => {
        // Ricavo solo i dati che mi servono dalla tabella utente
        this.setState({
          nome: datiUtenteAutenticato.nome,
          cognome: datiUtenteAutenticato.cognome,
          avatarData: datiUtenteAutenticato.avatarUrl,
          statoutente: datiUtenteAutenticato.stato,
          token: token, 
          })      
      }, 
      (error) => {
        // Torno alla homepage se l'utente non è riconosciuto
        this.props.history.push("/")     
      }, 
      header
    )
      
  }

   //Funzione di gestione modifica valore di campo di input 
  onModificaInput = (event) => {
    let {input} = this.state
    input[event.target.name] = event.target.value
    this.setState({input}, () => this.verificaInput())   
  }

  // //Funzione di gestione modifica slider
  // onModificaSlider = nomeSlider => (event, valoreSlide) => {
  //   let {input} = this.state
  //   input[nomeSlider] = valoreSlide
  //   this.setState({input})   
  // }



  // Funzione per verificare la correttezza dei campi inseriti
  verificaInput = () => {
    let {input} = this.state
    let {errori} = this.state
    let {validazione} = this.state

    // Nome
    if((input.nome.length < 2)){
      errori['nome'] = 'Almeno 2 caratteri'
      validazione['nome'] = false
    } else {
        errori['nome'] = ''
        validazione['nome'] = true
    }

    // Cognome
    if(input.cognome.length < 2){
      errori['cognome'] = 'Almeno 2 caratteri'
      validazione['cognome'] = false    
    } else 
    {
      errori['cognome'] = ''
      validazione['cognome'] = true
    }

    // Aggiorno i campi di validazione del form 
    this.setState({errori})
    this.setState({validazione})

  }

  // Funzione di gestione pulsante "Conferma Iscrizione" (tramite API)
  onPressProsegui = (event) => {

     // Evito che il form segua il suo comportamento di default, ovvero aprire una pagine di destinazione (<Form action="...">)
    event.preventDefault();
    
    // Verifico che tutti i campi siano correttamente compilati
    // Converto l'oggetto "validazione" in un array
    const validazioneArray = Object.values(this.state.validazione);
    // Riduco l'array ad un unico valore booleano (combinazione &&)
    const reducer = (accumulatore, valore) => accumulatore && valore
  
    // Se tutti i campi sono validati
    if(validazioneArray.reduce(reducer,true)) {
      let {input} = this.state
      //Effettuo la chiamata al servizio API
      let payload = {
        nome: input.nome,
        cognome: input.cognome,
      }

      let header = {token: this.state.token}
      
      API.post('utente', 'utenteModifica', payload, this.modificaAvvenuta, this.modificaErrore, header) 
    } else
    {
      // Non tutti i campi sono validati, quindo mostro tutti gli Helper e messaggio generale
      let {utility} = this.state
      utility['mostraHelper'] = true;
      this.setState({utility})
      this.setState({messaggioErrore: "Compilare correttamente tutti i campi"})
    }

  }

  // Funzione per la gestione della chiusura pop-over di credenziali errate
  onChiusuraMessaggioErrore = (event, reason) => {
    this.setState({messaggioErrore: ''})
  };

  // Funzione di gestione iscrizione effettuata
  modificaAvvenuta = (datiUtente) => {
    datiUtente["token"] = this.state.token
    this.props.onInserimentoDatiTecnici(datiUtente)
  }

  // Funzione di gestione iscrizione errata
  modificaErrore = (datiErrore) => {
    this.setState({messaggioErrore: datiErrore.error})
  }

  onUtenteGiaIscritto = () => {
    // Torno in homepage
    this.props.history.push("/login?k=" + this.state.token)
  }


  render(){
    const {classes} = this.props;
    const {input, validazione, errori, utility}   = this.state

    return (
      // Stato Utente 1 = Utente attivo
      ((this.state.statoutente*1) === 1) 
      ?  
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Typography component="h1" variant="h5">
              Attenzione!
            </Typography>
            <Typography>
              Utente già iscritto.
            </Typography>
            <br/>           
            <div className={classes.root}>
              <div height='50px' width='400px'></div>
              <Button variant="contained" component="span" className={classes.button} onClick={this.onUtenteGiaIscritto}>
                Vai all'homepage
              </Button>
            </div>
          </div>  

        </Container>
      :
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">Ciao!</Typography>
          <Typography>Conferma i tuoi dati</Typography>

          <form className={classes.form} onSubmit={this.onPressProsegui} >
             {/* Nome */}           
             <TextField
              size="small"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="nome"
              label="Nome"
              name="nome"
              autoComplete="off"
              autoFocus
              value={input.nome}
              onChange={this.onModificaInput}
              classes= {validazione.nome ? {root: classes.textFieldSuccess}: {root: classes.textField}  }
              helperText = {utility.mostraHelper ? errori.nome : ''}
            />

            {/* Cognome */}            
            <TextField
              size="small"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="cognome"
              label="Cognome"
              name="cognome"
              autoComplete="off"
              value={input.cognome}
              onChange={this.onModificaInput}
              classes= {validazione.cognome ? {root: classes.textFieldSuccess}: {root: classes.textField}}
              helperText = {utility.mostraHelper ? errori.cognome : ''}
            />

           {/* Genere            
            <Typography className={classes.titolo}>
              Genere
            </Typography>
            <RadioGroup row aria-label="genere" name="genere" defaultValue="M" onChange={this.onModificaInput}>
              <FormControlLabel value="M" control={<RadioMaschio  color="default" />} label="Maschietto" />
              <FormControlLabel value="F" control={<RadioFemmina  color="default" />} label="Femminuccia" />
            </RadioGroup> */}

            {/* // Livello
            // <Typography className={classes.titolo}>
            //   Livello di partenza ({input.livello})
            // </Typography>            
            // <LevelSlider
            //   name="livello"
            //   defaultValue={3}
            //   aria-labelledby="discrete-slider"
            //   valueLabelDisplay="auto"
            //   step={0.5}
            //   min={1}
            //   max={7}
            //   onChangeCommitted={this.onModificaSlider("livello")}
            // />  */}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Prosegui e completa
            </Button>
          </form>
        </div>
        <Snackbar  open={this.state.messaggioErrore !== ''? true : false} autoHideDuration={4000} className={classes.snackbar}  onClose={this.onChiusuraMessaggioErrore} >
                <Alert
                  variant="filled"
                  elevation={6}
                  severity="error"
                  action={
                    <CloseIcon />
                  }
                  onClick={this.onChiusuraMessaggioErrore}
                  >
                  {this.state.messaggioErrore}
                </Alert>
        </Snackbar>
        {/* <Box mt={8} style={{position: 'fixed', bottom: 10,  width:'100%', }}>
          <Copyright />
        </Box> */}
      </Container>
    );
  }
}

// Esporto anche gli stili presenti in questo file, con la classe Header
export default compose(
  withStyles(styles),
  withRouter, 
) (DatiAnagrafici)