import login from './dataTypes/login.js';
import fastLogin from './dataTypes/fastLogin.js';
import iscrizione from './dataTypes/iscrizione.js';
import invitoiscrizione from './dataTypes/invitoiscrizione.js';
import utente from './dataTypes/utente.js';
import recuperoPassword from './dataTypes/recuperoPassword.js';
import modificaPassword from './dataTypes/modificaPassword.js';
import nuovaPartita from './dataTypes/nuovaPartita.js';
import partita from './dataTypes/partita.js';
import partite from './dataTypes/partite.js';
import giocatori from './dataTypes/giocatori.js';
import preferiti from './dataTypes/preferiti.js';
import modificaPreferito from './dataTypes/modificaPreferito.js';
import utenteDatiTecnici from './dataTypes/utenteDatiTecnici.js';

// Classe per  nominare correttamente i campi da/verso le chiamate API
class apiDataMask {

    encode = (dataType, payload) => {
        switch(dataType) {
            case "login":               return login.encode(payload);
            case "fastLogin":           return fastLogin.encode(payload);
            case 'utente':              return utente.encode(payload);
            case 'utenteDatiTecnici':   return utenteDatiTecnici.encode(payload);
            case 'iscrizione':          return iscrizione.encode(payload);
            case 'invitoiscrizione':    return invitoiscrizione.encode(payload);
            case 'recover':             return recuperoPassword.encode(payload);
            case 'reset':               return modificaPassword.encode(payload);
            case 'nuovaPartita' :       return nuovaPartita.encode(payload);
            case 'partita' :            return partita.encode(payload);
            case 'modificaPreferito' :  return modificaPreferito.encode(payload);
            case 'preferiti' :          return preferiti.encode(payload);

            default:          return {} 
        } 
    }

    decodeOK = (dataType, payload) => {

        switch(dataType) {
            case 'login':               return login.decode(payload);
            case "fastLogin":           return fastLogin.decode(payload);            
            case 'utente':              return utente.decode(payload);
            case 'utenteDatiTecnici':   return utenteDatiTecnici.decode(payload);           
            case 'iscrizione':          return iscrizione.decode(payload);
            case 'invitoiscrizione':    return invitoiscrizione.decode(payload);
            case 'recover':             return recuperoPassword.decode(payload);
            case 'reset':               return modificaPassword.decode(payload);
            case 'partite':             return partite.decode(payload);  
            case 'partita' :            return partita.decode(payload);
            case 'giocatori':           return giocatori.decode(payload);     
            case 'preferiti':           return preferiti.decode(payload);     
            default: return {}
        } 
    }
    
    decodeKO = (dataType, payload) => {
        let newPayload = '';
        newPayload = {
            error: "Errore Api", 
        }
        return newPayload
    }

}

var apiMask = new apiDataMask();

export default apiMask


