// Componenti React
import React,{Component, Fragment} from 'react';

// Componenti Material UI
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
//import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
// Drawer
import Drawer from '@material-ui/core/Drawer';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import { withStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme();

const styles = {

  card: {
    minWidth: 275,
    height: 50,
    marginTop: 10,
    marginBottom: 10,
    display:'flex',
    flexGrow: 0,

  },

  card_form: {
    marginTop: 15,
    marginBottom: 15,
    marginLeft: 15,
    marginRight: 15,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
  },

  card_contenuto:{
    flexGrow: 1,
  },

  card_contenuto_titolo:{
    overflow:'hidden',
  },

  card_add: {
    width: 50,
    flexShrink: 0,
    backgroundColor: '#eeeeee',
  },

  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),

  },

  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor:'#373a40',
    "&:hover": {
      backgroundColor: "#373a40"
    },
    color: 'white',

  },

  textField: {
    '& label.Mui-focused': {
      color: '#19d3da',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {

      },
      '&.Mui-focused fieldset': {
        borderColor: '#19d3da',
      },
      
   },
  },

  textFieldSuccess: {
    // Label on focus on
    '& label.Mui-focused': {
      color: '#95d5b2',
    },
    // Campo input 
    '& .MuiOutlinedInput-root': {

      // Default
      '& fieldset': {
        borderColor: '#95d5b2',
        borderWidth: 2,
      },

      // Default mouse over
      '&:hover fieldset': {
        borderColor: '#95d5b2',
        borderWidth: 2,
      },
      // Quando sono dentro al campo
      '&.Mui-focused fieldset': {
        borderColor: '#95d5b2',
        borderWidth: 2,
      },

    },
  },

  snackbar: {
    bottom: 30,
  }

};

class NuovoGiocatoreCard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      menuAperto: false,

      input: {
        nome : '',
        cognome: '',
        email: '',
      },
      errori: {
        nome : '',
        cognome: '',
        email: '', 
      },
      validazione: {
        nome : false,
        cognome: false,
        email: false,
      },
      utility: {
        mostraHelper: false,
      }, 
      messaggioErrore: '',
    }
  }
  
  // Gestione apertura form nuovo giocatore
  gestioneMenu = (apertura) => (event) => {
    this.setState({menuAperto: apertura});
  };
  
  //Funzione di gestione modifica valore di campo di input 
  onModificaInput = (event) => {
    let {input} = this.state
    input[event.target.name] = event.target.value
    this.setState({input}, () => this.verificaInput())   
  }
  
  
    // Funzione per verificare la correttezza dei campi inseriti
  verificaInput = () => {
    let {input} = this.state
    let {errori} = this.state
    let {validazione} = this.state

    // Nome
    if((input.nome.length < 2)){
      errori['nome'] = 'Almeno 2 caratteri'
      validazione['nome'] = false
    } else {
        errori['nome'] = ''
        validazione['nome'] = true
    }

    // Cognome
    if(input.cognome.length < 2){
      errori['cognome'] = 'Almeno 2 caratteri'
      validazione['cognome'] = false    
    } else 
    {
      errori['cognome'] = ''
      validazione['cognome'] = true
    }

    // Email
    if (!this.isEmailValida(input.email)) {
      errori['email'] = 'Indirizzo email non valido'
      validazione['email'] = false
    } else 
    {
      errori['email'] = ''
      validazione['email'] = true
    }
  }
  

  // Utilità di validazione indirizzo email
  isEmailValida = (val) => {
      let regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if(!regEmail.test(val))
        return false
      return true
  }
  // Funzione di gestione pulsante "Conferma Iscrizione" (tramite API)
  onPressConferma = (event) => {

    // Evito che il form segua il suo comportamento di default, ovvero aprire una pagine di destinazione (<Form action="...">)
   event.preventDefault();
   
   // Verifico che tutti i campi siano correttamente compilati
   // Converto l'oggetto "validazione" in un array
   const validazioneArray = Object.values(this.state.validazione);
   // Riduco l'array ad un unico valore booleano (combinazione &&)
   const reducer = (accumulatore, valore) => accumulatore && valore
 
   // Se tutti i campi sono validati
   if(validazioneArray.reduce(reducer,true)) {
     let {input} = this.state
     //Effettuo la chiamata al servizio API
     let payload = {
       ID: input.email,
       Nome: input.nome,
       Cognome: input.cognome,
       Email:input.email,
       Avatar: '/images/avatars/NewPlayer.jpg', 
     } 

     this.setState({menuAperto: false}, () => this.props.onAggiungiGiocatore(payload))
     
   } else
   {
     // Non tutti i campi sono validati, quindo mostro tutti gli Helper e messaggio generale
     let {utility} = this.state
     utility['mostraHelper'] = true;
     this.setState({utility})
     this.setState({messaggioErrore: "Compilare correttamente tutti i campi"})
   }

 }

  // Funzione per la gestione della chiusura pop-over di credenziali errate
  onChiusuraMessaggioErrore = (event, reason) => {
    this.setState({messaggioErrore: ''})
  };

  render(){
    const {input, validazione, errori, utility}   = this.state

    // Variabili props
    const {classes} = this.props;

      // Drawer / Menu laterale
      const formNuovoGiocatore =
        <Fragment>
         <Card className={classes.card_form} variant="outlined">

          <Typography variant="subtitle1"> Aggiungi un nuovo giocatore alla partita </Typography>

          <form className={classes.form} onSubmit={this.onPressConferma} >
          {/* Nome */}           
          <TextField
            size="small"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="nome"
            label="Nome"
            name="nome"
            autoComplete="off"
            autoFocus
            value={input.nome}
            onChange={this.onModificaInput}
            classes= {validazione.nome ? {root: classes.textFieldSuccess}: {root: classes.textField}  }
            helperText = {utility.mostraHelper ? errori.nome : ''}
          />

          {/* Cognome */}            
          <TextField
            size="small"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="cognome"
            label="Cognome"
            name="cognome"
            autoComplete="off"
            value={input.cognome}
            onChange={this.onModificaInput}
            classes= {validazione.cognome ? {root: classes.textFieldSuccess}: {root: classes.textField}}
            helperText = {utility.mostraHelper ? errori.cognome : ''}
          />

          {/* Email */}
          <TextField
            size="small"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="off"
            value={input.email}
            onChange={this.onModificaInput}
            classes= {validazione.email ? {root: classes.textFieldSuccess}: {root: classes.textField}  }
            helperText = {utility.mostraHelper ? errori.email : ''}
          />

          <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Aggiungi
            </Button>
        </form>
        </Card>
        <Snackbar  open={this.state.messaggioErrore !== ''? true : false} autoHideDuration={4000} className={classes.snackbar}  onClose={this.onChiusuraMessaggioErrore} >
                <Alert
                  variant="filled"
                  elevation={6}
                  severity="error"
                  action={
                    <CloseIcon />
                  }
                  onClick={this.onChiusuraMessaggioErrore}
                  >
                  {this.state.messaggioErrore}
                </Alert>
        </Snackbar>
      </Fragment>
    


    return (
             <Fragment>
              <Card className={classes.card} variant="outlined">
                <CardActionArea onClick={this.gestioneMenu(true)}>
                  <CardContent className={classes.card_contenuto} >
                        <Typography variant="subtitle2" className={classes.card_contenuto_titolo}>{`..giocatore non trovato? Aggiungilo!`}</Typography>
                  </CardContent>
                </CardActionArea>
                <CardContent className={classes.card_add}>
                    <AddIcon style={{ color: '#000' }}/>
                </CardContent>
              </Card>
              <Drawer anchor={'bottom'} open={this.state.menuAperto}  onClose={this.gestioneMenu(false)}>
                {formNuovoGiocatore}
              </Drawer>
            </Fragment>
    )
  }
}


export default withStyles(styles)(NuovoGiocatoreCard)
