import React,{Component} from 'react';
import {Link} from 'react-router-dom'

// Chiamate API Rest
import API from "../../../helpers/API/apiCaller/APIManager"
import Privacy from "../../common/Privacy"

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LinkUI from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
//import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import { useEventCallback } from '@material-ui/core';

// Functional component per visualizzazione Copyright
// function Copyright() {
//   return (
//     <Typography variant="body2" color="textSecondary" align="center">
//       {'Copyright © '}
//       <LinkUI color="inherit" href="https://PadelRank.it">
//         Stealth Srl
//       </LinkUI>{' '}
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// }

// Utilizzo tema standard
const theme = createMuiTheme();

const styles = {
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },


  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor:'#373a40',
    "&:hover": {
      backgroundColor: "#373a40"
    },
    color: 'white',

  },

  textField: {
    '& label.Mui-focused': {
      color: '#19d3da',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {

      },
      '&.Mui-focused fieldset': {
        borderColor: '#19d3da',
      },
      
   },
  },

  textFieldSuccess: {
    // Label on focus on
    '& label.Mui-focused': {
      color: '#95d5b2',
    },
    // Campo input 
    '& .MuiOutlinedInput-root': {

      // Default
      '& fieldset': {
        borderColor: '#95d5b2',
        borderWidth: 2,
      },

      // Default mouse over
      '&:hover fieldset': {
        borderColor: '#95d5b2',
        borderWidth: 2,
      },
      // Quando sono dentro al campo
      '&.Mui-focused fieldset': {
        borderColor: '#95d5b2',
        borderWidth: 2,
      },

   },
  },

  titolo: {
    margin: theme.spacing(2, 0, 1),
  },

  link: {
    color:'#373a40',
  },

  privacyBox: {
    marginTop:10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
  },

  snackbar: {
    bottom: 30,
  }

};



class DatiUtente extends Component {

  constructor(props) {
    super(props);
    this.state = {
      input: {
        nome : '',
        cognome: '',
        email: '',
        password: '',
        confermaPassword: '',
        privacy: false,
      },
      errori: {
        nome : '',
        cognome: '',
        email: '',
        password: '',
        confermaPassword: '', 
        privacy: '', 
      },
      validazione: {
        nome : false,
        cognome: false,
        email: false,
        password: false,
        confermaPassword: false, 
        privacy: false,
      },
      utility: {
        mostraPassword: false,
        mostraHelper: false,
      },   
      messaggioErrore: '',
    }

    // Istanzio una nuova occorrenza del servizio API
    // this.API = new API();
    
 }

   //Funzione di gestione modifica valore di campo di input 
  onModificaInput = (event) => {
    let {input} = this.state
    input[event.target.name] = event.target.value
    this.setState({input}, () => this.verificaInput())   
  }

  //Funzione di gestione modifica valore di campo checkbox
  onModificaCheckBox = (event) => {
    let {input} = this.state
    input[event.target.name] = event.target.checked
    this.setState({input}, () => this.verificaInput())   
  }

  // Funzione di gestione cambio visibilità password
  onCambioVisibilitaPsw = () => {
    let {utility} = this.state
    utility['mostraPassword'] = !utility['mostraPassword'] 
    this.setState({utility})
  };

  // Funzione per verificare la correttezza dei campi inseriti
  verificaInput = () => {
    let {input} = this.state
    let {errori} = this.state
    let {validazione} = this.state

    // Nome
    if((input.nome.length < 2)){
      errori['nome'] = 'Almeno 2 caratteri'
      validazione['nome'] = false
    } else {
        errori['nome'] = ''
        validazione['nome'] = true
    }

    // Cognome
    if(input.cognome.length < 2){
      errori['cognome'] = 'Almeno 2 caratteri'
      validazione['cognome'] = false    
    } else 
    {
      errori['cognome'] = ''
      validazione['cognome'] = true
    }

    // Email
    if (!this.isEmailValida(input.email)) {
      errori['email'] = 'Indirizzo email non valido'
      validazione['email'] = false
    } else 
    {
      errori['email'] = ''
      validazione['email'] = true
    }

    // Password
    if (!this.isPasswordSicura(input.password)) {
      errori['password'] = 'Almeno una lettera maiuscola e un numero'
      validazione['password'] = false
    } else 
    {
      errori['password'] = ''
      validazione['password'] = true
    }

    // Conferma password
    if(validazione['password'] && (input.password === input.confermaPassword)){
      validazione['confermaPassword'] = true
      errori['confermaPassword'] = ''
    }else
    {
      validazione['confermaPassword'] = false
      errori['confermaPassword'] = 'Verifica Password'
    }

    // Privacy
    if(!input.privacy){
      errori['privacy'] = 'Manca l\'accettazione privacy'
      validazione['privacy'] = false    
    } else 
    {
      errori['privacy'] = ''
      validazione['privacy'] = true
    }

    // Aggiorno i campi di validazione del form 
    this.setState({errori})
    this.setState({validazione})

  }

  // Utilità di validazione indirizzo email
  isEmailValida = (val) => {
      let regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if(!regEmail.test(val))
        return false
      return true
  }
  
  // Utilità di validazione Password
  isPasswordSicura = (val) => {
    // let regPsw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
    // if(!regPsw.test(val))
    //   return false
    return true
}

  // Funzione per evitare l'utente clicci sul campo password.. forse per motivi di sicurezza?!
  gestioneMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // Funzione di gestione pulsante "Conferma Iscrizione" (tramite API)
  onPressIscrizione = (event) => {

     // Evito che il form segua il suo comportamento di default, ovvero aprire una pagine di destinazione (<Form action="...">)
    event.preventDefault();
    
    // Verifico che tutti i campi siano correttamente compilati
    // Converto l'oggetto "validazione" in un array
    const validazioneArray = Object.values(this.state.validazione);
    // Riduco l'array ad un unico valore booleano (combinazione &&)
    const reducer = (accumulatore, valore) => accumulatore && valore
  
    // Se tutti i campi sono validati
    if(validazioneArray.reduce(reducer,true)) {
      let {input} = this.state
      //Effettuo la chiamata al servizio API
      let payload = {
        nome: input.nome,
        cognome: input.cognome,
        email:input.email, 
        password: input.password,
      }
      API.post('iscrizione', 'iscrizione', payload, this.iscrizioneAvvenuta, this.iscrizioneErrore) 
    } else
    {
      // Non tutti i campi sono validati, quindo mostro tutti gli Helper e messaggio generale
      let {utility} = this.state
      utility['mostraHelper'] = true;
      this.setState({utility})
      this.setState({messaggioErrore: "Compilare correttamente tutti i campi"})
    }

  }

  // Funzione per la gestione della chiusura pop-over di credenziali errate
  onChiusuraMessaggioErrore = (event, reason) => {
    this.setState({messaggioErrore: ''})
  };

  // Funzione di gestione iscrizione effettuata
  iscrizioneAvvenuta = (datiUtente) => {
    this.props.onInserimentoUtente(datiUtente)
  }

  // Funzione di gestione iscrizione errata
  iscrizioneErrore = (datiErrore) => {
    this.setState({messaggioErrore: datiErrore.error})
  }


  render(){

    const {classes} = this.props;
    const {input, validazione, errori, utility}   = this.state
    
    //console.log(input)

    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Iscrizione
          </Typography>
          <form className={classes.form} onSubmit={this.onPressIscrizione} >

             {/* Nome */}           
            <TextField
              size="small"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="nome"
              label="Nome"
              name="nome"
              autoComplete="off"
              autoFocus
              value={input.nome}
              onChange={this.onModificaInput}
              classes= {validazione.nome ? {root: classes.textFieldSuccess}: {root: classes.textField}  }
              helperText = {utility.mostraHelper ? errori.nome : ''}
            />

            {/* Cognome */}            
            <TextField
              size="small"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="cognome"
              label="Cognome"
              name="cognome"
              autoComplete="off"
              value={input.cognome}
              onChange={this.onModificaInput}
              classes= {validazione.cognome ? {root: classes.textFieldSuccess}: {root: classes.textField}}
              helperText = {utility.mostraHelper ? errori.cognome : ''}
            />

             {/* Email */}
            <TextField
              size="small"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete='off'
              value={input.email}
              onChange={this.onModificaInput}
              classes= {validazione.email ? {root: classes.textFieldSuccess}: {root: classes.textField}  }
              helperText = {utility.mostraHelper ? errori.email : ''}
            />

            {/* Password */}
            <TextField
              size="small"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type={utility.mostraPassword ? 'text' : 'password'}
              id="password"
              value={input.password}
              onChange={this.onModificaInput}
              classes= {validazione.password ? {root: classes.textFieldSuccess}: {root: classes.textField} }
              autoComplete="off"
              helperText = {utility.mostraHelper ? errori.password : ''}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                        aria-label="Vedi o nascondi password"
                        onClick={this.onCambioVisibilitaPsw}
                        onMouseDown={this.gestioneMouseDownPassword}
                        edge="end"
                    ></IconButton>
                    {utility.mostraPassword ? <VisibilityOff/> : <Visibility />}
                  </InputAdornment>
                ),
              }}
            />

            {/* Conferma Password */}
            <TextField
              size="small"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="confermaPassword"
              label="Conferma password"
              type={utility.mostraPassword ? 'text' : 'password'}
              id="confermapassword"
              value={input.confermaPassword}
              onChange={this.onModificaInput}
              helperText = {utility.mostraHelper ? errori.confermaPassword : ''}
              classes= {validazione.confermaPassword ? {root: classes.textFieldSuccess}: {root: classes.textField} }
              autoComplete='off'
            />
           <div className={classes.privacyBox}> 
            <FormControlLabel
              control={
                <Checkbox
                  name="privacy"
                  value={input.privacy}
                  color="default"
                  inputProps={{ 'aria-label': 'checkbox with default color' }}
                  onChange={this.onModificaCheckBox}
                />}
              />
              <Privacy/>
            </div>
            

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Conferma
            </Button>

            <Grid container>
              <Grid item xs >
                <LinkUI href="#" variant="body2" classes={{ root: classes.link}} component={Link} to="/">
                  Torna in home
                </LinkUI>
              </Grid>
            </Grid>
          </form>
        </div>
        <Snackbar  open={this.state.messaggioErrore !== ''? true : false} autoHideDuration={4000} className={classes.snackbar}  onClose={this.onChiusuraMessaggioErrore} >
                <Alert
                  variant="filled"
                  elevation={6}
                  severity="error"
                  action={
                    <CloseIcon />
                  }
                  onClick={this.onChiusuraMessaggioErrore}
                  >
                  {this.state.messaggioErrore}
                </Alert>
        </Snackbar>
        {/* <Box mt={8} style={{position: 'fixed', bottom: 0,  width:'100%', }}>
          <Copyright />
        </Box> */}
      </Container>
    );
  }
}

export default withStyles(styles) (DatiUtente)