// Componenti React
import React,{Component, Fragment} from 'react';
import sortBy from 'sort-by';
import serializeForm from 'form-serialize';
import moment from 'moment';
import {Redirect} from 'react-router-dom';
import { withRouter } from 'react-router-dom';

// Compose per utilizzare multipli Wrapper (withstyle e withcookies)
import { compose } from 'recompose'

// Componenti PadelRank
import RicercaGiocatore from './RicercaGiocatore';
import DataItaliana from "../../common/DataItaliana"

// Componenti Material UI
import CheckIcon from '@material-ui/icons/Check';
import Fab from '@material-ui/core/Fab';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';


// import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
//import IconButton from '@material-ui/core/IconButton';
//import MoreVertIcon from '@material-ui/icons/MoreVert';
import CloseIcon from '@material-ui/icons/Close';
//import RepeatIcon from '@material-ui/icons/Repeat';
import EventIcon from '@material-ui/icons/Event';

// Componenti per azioni su REST API
import API from "../../../helpers/API/apiCaller/APIManager"

// Stili
const styles = {

  menu: {
    color: 'grey',
  },

  dataPartita: {
      display: 'flex',

      '& label.Mui-focused': {
         color: '#19d3da',
       },

       '& .MuiInput-underline:after': {
         borderBottomColor: '#19d3da',
       },

    },

  inputDataPartita: {
      textAlign: 'center',
    },

  card: {
    marginTop: 10,
    marginBottom: 10,
  },

  card_header: {
    backgroundColor: '',
  },

  avatarA:{
    borderColor: 'grey',
    borderStyle: 'solid',
    height: '50px',
    width: '50px',
  },
  avatarB:{
    borderColor: '#19d3da',
    borderStyle: 'solid',
    height: '50px',
    width: '50px',
  },

  avatarSelezionato:{
    borderColor: '#19d3da',
    borderStyle: 'dashed',
    height: '50px',
    width: '50px',
  },


  gridItem_giocatore: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection:'column',
    backgroundColor:'',
  },

  gridItem_giocatore_nome: {
    fontSize: 12,
  },

  gridItem_punteggio: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection:'column',
    // backgroundColor:'lightgrey',
  },

  gridItem_paper: {
    width: "100%",
    height: "100%",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderStyle: 'dashed',
  },

  gridItem_paper_win: {
    width: "100%",
    height: "100%",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: '#373a40',
    borderStyle: 'dashed',
  },

  gridItem_paper_testo: {
    fontWeight: "lighter",
  },

  inputPunteggio: {
      fontSize: 30,
      textAlign: 'center',
    },

  fab: {
    position: 'fixed',
    bottom: 85,
    right: 15,
    backgroundColor:'#19d3da',
    color: 'white',
  },

  fab_off: {
    position: 'fixed',
    bottom: 70,
    right: 10,
    backgroundColor:'lightgrey',
    color: 'white',
  },

  ricerca: {
      display: 'flex',

      '& label.Mui-focused': {
         color: '#19d3da',
       },

       '& .MuiInput-underline:after': {
         borderBottomColor: '#19d3da',
       },

    },

    snackbar: {
      bottom: 90,
    }

};

// Class Component
class ModificaPartita extends Component {

  // Perchè utilizzare il constructor ?!
  constructor(props) {
      super(props);
      this.state = {
          ancoraMenu: '',
          giocatoriInseriti:0,
          punteggiInseriti: 0,
          partitaInserita: false,
          popupModificaData: false,
          messaggioErrore:'',
          ricercaGiocatore:'',
          posizioneGiocatore: '',
          dataPartita: new moment().format('YYYY-MM-DD'),

          giocatoreA1_ID: '',
          giocatoreA1_Label: '...',
          giocatoreA1_AvatarUrl: '',

          giocatoreA2_ID: '',
          giocatoreA2_Label: '...',
          giocatoreA2_AvatarUrl: '',


          giocatoreB1_ID: '',
          giocatoreB1_Label: '...',
          giocatoreB1_AvatarUrl: '',

          giocatoreB2_ID: '',
          giocatoreB2_Label: '...',
          giocatoreB2_AvatarUrl: '',

          punteggio1A:'',
          punteggio2A:'',
          punteggio3A:'',
          punteggio1B:'',
          punteggio2B:'',
          punteggio3B:'',

          dbGiocatori: [],
        }

   }

  componentDidMount() {
    
    // Ricavo i giocatori per eventuale selezione
    this.ricavaDatiGiocatori(this.props.token);   

    // Ricavo i dati dalla partita dall'URL
    this.ricavaDatiPartita(this.props.token, this.props.match.params.partitaID);     
  }

  // Aggiorno i dati utente, in funzione del token di ritorno
  ricavaDatiPartita = (token, IDPartita) => {
    let payload = {appendURL: IDPartita}
    let header = {token: token}

    //Effettuo la chiamata al servizio API e aggiorno lo stato con i dati della partita
    API.get(
      'partita', // dataType (econde/decode API)
      'partite', 
      payload, 
      (datiPartita) => {
          this.setState({
            idPartita: IDPartita,
            giocatoriInseriti:true,
            punteggiInseriti: true,
            dataPartita: datiPartita.DataPartita,

            giocatoreA1_ID: datiPartita.Giocatori.ID1,
            giocatoreA1_Label: datiPartita.Giocatori.Nome1 + ' ' + datiPartita.Giocatori.Cognome1[0]+'.',
            giocatoreA1_AvatarUrl: datiPartita.Giocatori.Avatar1,

            giocatoreA2_ID: datiPartita.Giocatori.ID2,
            giocatoreA2_Label: datiPartita.Giocatori.Nome2 + ' ' + datiPartita.Giocatori.Cognome2[0]+'.',
            giocatoreA2_AvatarUrl: datiPartita.Giocatori.Avatar2,

            giocatoreB1_ID: datiPartita.Giocatori.ID3,
            giocatoreB1_Label: datiPartita.Giocatori.Nome3 + ' ' + datiPartita.Giocatori.Cognome3[0]+'.',
            giocatoreB1_AvatarUrl: datiPartita.Giocatori.Avatar3,

            giocatoreB2_ID: datiPartita.Giocatori.ID4,
            giocatoreB2_Label: datiPartita.Giocatori.Nome4 + ' ' + datiPartita.Giocatori.Cognome4[0]+'.',
            giocatoreB2_AvatarUrl: datiPartita.Giocatori.Avatar4,
            
            punteggio1A: datiPartita.Games[0] ? datiPartita.Games[0].Squadra1 : '',
            punteggio2A: datiPartita.Games[1] ? datiPartita.Games[1].Squadra1 : '',
            punteggio3A: datiPartita.Games[2] ? datiPartita.Games[2].Squadra1 : '',
            punteggio1B: datiPartita.Games[0] ? datiPartita.Games[0].Squadra2 : '',
            punteggio2B: datiPartita.Games[1] ? datiPartita.Games[0].Squadra2 : '',
            punteggio3B: datiPartita.Games[2] ? datiPartita.Games[0].Squadra2 : '',
        })     
      }, 
      () => {return false},
      header,
    )
      
  }



  // Aggiorno i dati dei giocatori
  ricavaDatiGiocatori = (token) => {

    let payload = ''
    let header = {token: token} 

    //Effettuo la chiamata al servizio API e aggiorno lo stato con i dati giocatori
    API.get(
      'giocatori', // dataType (econde/decode API)
      'giocatori', 
      payload, 
      (datiGiocatori) => {
        this.setState({
          dbGiocatori: datiGiocatori,
          })   
        return true
      }, 
    (errorData) => {this.setState({messaggioErrore: errorData.error})},
    header
    )
      
  }
  // Funzioni di gestione menu card
  aperturaMenu = (event) => {
     this.setState({
       ancoraMenu:event.currentTarget,
     })
   }

  chiusuraMenu = () => {
     this.setState({
       ancoraMenu:'',
     })
   }

  // Funzioni di gestione menu modifica data
  aperturaPopupData = () => {
    this.chiusuraMenu();
    this.setState({
      popupModificaData:true,
    })
  }

  chiusuraPopupData = () => {
      this.setState({
        popupModificaData:false,
      })
    }

  ricavaDatiGiocatore(IDGiocatore) {
    let GiocatoreRichiesto = this.state.dbGiocatori.filter(giocatore => giocatore.ID === IDGiocatore)
    return GiocatoreRichiesto[0]
  }

  // Funzione per la modifica della data Partita
  onModificaData = (event) => {
    this.setState({dataPartita: event.target.value});
  }

  // Azzero i dati della partita
  resetPartita = (event, reason) =>{
    this.setState({
      giocatoriInseriti:0,
      punteggiInseriti: 0,
      partitaInserita: false,
      ricercaGiocatore:false,
      posizioneGiocatore: '',
      dataPartita: new moment().format('YYYY-MM-DD'),

      giocatoreA1_ID: '',
      giocatoreA1_Nome: '',
      giocatoreA1_Cognome: '',          
      giocatoreA1_Label: '...',
      giocatoreA1_AvatarUrl: '',

      giocatoreA2_ID: '',
      giocatoreA2_Nome: '',
      giocatoreA2_Cognome: '',     
      giocatoreA2_Label: '...',
      giocatoreA2_AvatarUrl: '',


      giocatoreB1_ID: '',
      giocatoreB1_Nome: '',
      giocatoreB1_Cognome: '',     
      giocatoreB1_Label: '...',
      giocatoreB1_AvatarUrl: '',

      giocatoreB2_ID: '',
      giocatoreB2_Nome: '',
      giocatoreB2_Cognome: '',     
      giocatoreB2_Label: '...',
      giocatoreB2_AvatarUrl: '',

      punteggio1A:'',
      punteggio2A:'',
      punteggio3A:'',
      punteggio1B:'',
      punteggio2B:'',
      punteggio3B:'',
    })
  }

  // Funzione per avviare la ricerca di un giocatore
  onRicercaGiocatore = (posizioneGiocatore) => {
    // Memorizzo nello stato la posizione del giocatore di cui sto effettuando la scelta
    this.setState({
      ricercaGiocatore: true,
      posizioneGiocatore: posizioneGiocatore,
    })

  }

  // Funzione per la selezione di un giocatore
  onSelezionaGiocatore = (giocatoreScelto) => {
    switch(this.state.posizioneGiocatore){
       case 'A1': this.verificaGiocatoreGiaInserito(giocatoreScelto.ID);
                  this.setState({
                       giocatoreA1_ID: giocatoreScelto.ID,
                       giocatoreA1_Nome: giocatoreScelto.Nome,
                       giocatoreA1_Cognome: giocatoreScelto.Cognome,     
                       giocatoreA1_Label: giocatoreScelto.Nome + " " + giocatoreScelto.Cognome[0] + ".",
                       giocatoreA1_AvatarUrl: giocatoreScelto.Avatar,
                       ricercaGiocatore: false,
                       posizioneGiocatore: '',
                    }, () => this.controlloGiocatoriInseriti());
                  break;
       case 'A2': this.verificaGiocatoreGiaInserito(giocatoreScelto.ID);
                  this.setState({
                    giocatoreA2_ID: giocatoreScelto.ID,
                    giocatoreA2_Nome: giocatoreScelto.Nome,
                    giocatoreA2_Cognome: giocatoreScelto.Cognome,  
                    giocatoreA2_Label: giocatoreScelto.Nome +  " " + giocatoreScelto.Cognome[0] + ".",
                    giocatoreA2_AvatarUrl: giocatoreScelto.Avatar,
                    ricercaGiocatore: false,
                    posizioneGiocatore: '',
                  }, () => this.controlloGiocatoriInseriti());
                  break;
       case 'B1': this.verificaGiocatoreGiaInserito(giocatoreScelto.ID);
                  this.setState({
                    giocatoreB1_ID: giocatoreScelto.ID,
                    giocatoreB1_Nome: giocatoreScelto.Nome,
                    giocatoreB1_Cognome: giocatoreScelto.Cognome,  
                    giocatoreB1_Label: giocatoreScelto.Nome +  " " + giocatoreScelto.Cognome[0] + ".",
                    giocatoreB1_AvatarUrl: giocatoreScelto.Avatar,
                    ricercaGiocatore: false,
                    posizioneGiocatore: '',
                  }, () => this.controlloGiocatoriInseriti());
                  break;
       case 'B2': this.verificaGiocatoreGiaInserito(giocatoreScelto.ID);
                  this.setState({
                    giocatoreB2_ID: giocatoreScelto.ID,
                    giocatoreB2_Nome: giocatoreScelto.Nome,
                    giocatoreB2_Cognome: giocatoreScelto.Cognome,  
                    giocatoreB2_Label: giocatoreScelto.Nome +  " " + giocatoreScelto.Cognome[0] + ".",
                    giocatoreB2_AvatarUrl: giocatoreScelto.Avatar,
                    ricercaGiocatore: false,
                    posizioneGiocatore: '',
                  }, () => this.controlloGiocatoriInseriti());
                  break;
       default:   break;
    }

  }

  // Funzione per la gestione del cambio punteggio
  onCambioPunteggio = (event) => {
    switch (event.target.name) {
      case 'punteggio1A': this.setState({punteggio1A: event.target.value}); break;
      case 'punteggio2A': this.setState({punteggio2A: event.target.value}); break;
      case 'punteggio3A': this.setState({punteggio3A: event.target.value}); break;
      case 'punteggio1B': this.setState({punteggio1B: event.target.value}); break;
      case 'punteggio2B': this.setState({punteggio2B: event.target.value}); break;
      case 'punteggio3B': this.setState({punteggio3B: event.target.value}); break;
      default: break;
    }
  }

  // Funzione per verificare che un giocatore selezionato non sia già stato aggiunto alla partita: eventualmente lo "tolgo" dalla squadra precedente
  verificaGiocatoreGiaInserito (giocatoreID){
    switch(giocatoreID){
      case (this.state.giocatoreA1_ID): this.setState({giocatoreA1_ID: '', giocatoreA1_Label: '...', giocatoreA1_AvatarUrl:''}); break;
      case (this.state.giocatoreA2_ID): this.setState({giocatoreA2_ID: '', giocatoreA2_Label: '...', giocatoreA2_AvatarUrl:''}); break;
      case (this.state.giocatoreB1_ID): this.setState({giocatoreB1_ID: '', giocatoreB1_Label: '...', giocatoreB1_AvatarUrl:''}); break;
      case (this.state.giocatoreB2_ID): this.setState({giocatoreB2_ID: '', giocatoreB2_Label: '...', giocatoreB2_AvatarUrl:''}); break;
      default: break;
    }
  }

  // Funzione per verificare che tutti i giocatori siano stati inseriti
  controlloGiocatoriInseriti(){
    if( this.state.giocatoreA1_ID &&
        this.state.giocatoreA2_ID &&
        this.state.giocatoreB1_ID &&
        this.state.giocatoreB2_ID)
        {
          this.setState({giocatoriInseriti:1})
        }
    else {
          this.setState({giocatoriInseriti:0})
    }
  }

  // Funzione per la modifica  della partita
  // Attenzione: utilizzo i valori del form, quando potrei usare i valori dello stato (poichè coincidono). Lo faccio per "training"
 onModificaPartita = (e) => {

    e.preventDefault() // Evitare il cambio URL del form / submit

    const data = serializeForm(e.target, {hash:true})
    
    // Per facilità di lettura, prima verifico e definisco i punteggi dei giochi.
    let Gioco1 = (Number.isInteger(+data.punteggio1A) && Number.isInteger(+data.punteggio1B)) ? {Squadra1: data.punteggio1A, Squadra2: data.punteggio1B,} : '';
    let Gioco2 = (Number.isInteger(+data.punteggio2A) && Number.isInteger(+data.punteggio2B)) ? {Squadra1: data.punteggio2A, Squadra2: data.punteggio2B,} : '';
    let Gioco3 = (Number.isInteger(+data.punteggio3A) && Number.isInteger(+data.punteggio3B)) ? {Squadra1: data.punteggio3A, Squadra2: data.punteggio3B,} : '';
    
    // Successivamente, aggiorno l'array dei giochi
    let Giochi = []
    Giochi= Gioco1 ? [...Giochi, Gioco1]: Giochi;
    Giochi= Gioco2 ? [...Giochi, Gioco2]: Giochi;
    Giochi= Gioco3 ? [...Giochi, Gioco3]: Giochi;

    // Predispongo il pacchetto per l'inserimento della partita    
    let payloadGiochi = {
      DataPartita: this.state.dataPartita,
      Games: Giochi,
    }

    // Se uno o più dei giocatori non hanno l'ID associato (cioè sono utenti nuovi),
   // fai prima l'inserimento a DB dei nuovi giocatori
    if(
      ((''+this.state.giocatoreA1_ID).indexOf('@')> -1)||
      ((''+this.state.giocatoreA2_ID).indexOf('@')> -1)||
      ((''+this.state.giocatoreB1_ID).indexOf('@')> -1)||
      ((''+this.state.giocatoreB2_ID).indexOf('@')> -1) 
    )
      this.verificaNuoviGiocatori(payloadGiochi)  
    else
      this.aggiornamentoPartitaDB(payloadGiochi)
    
  }

  // Funzione per inserimento nuovi giocatori a DB
  verificaNuoviGiocatori =  (payloadGiochi) => {
    let header = {token: this.props.token}
    // Se vi sono giocatori non presenti in anagrafica (quindi con indirizzo email al posto dell'ID), li aggiungo al database, con relativa chiamata API
    // Giocatore A1
    if((''+this.state.giocatoreA1_ID).indexOf('@')> -1) {
      // Effettuo la chiamata al servizio API e aggiungo l'utente
      let payload = {
        nome: this.state.giocatoreA1_Nome,
        cognome: this.state.giocatoreA1_Cognome,
        email: this.state.giocatoreA1_ID,
      }
      API.post('invitoiscrizione', 
               'iscrizionesuinvito', 
               payload, 
               (nuovoGiocatore)=>{this.setState({giocatoreA1_ID: nuovoGiocatore.id }, () => this.aggiornamentoPartitaDB(payloadGiochi))}, 
               (datiErrore)=>{this.setState({messaggioErrore: datiErrore.error})},
               header) 
    }
    // Giocatore A2
    if((''+this.state.giocatoreA2_ID).indexOf('@')> -1) {
      // Effettuo la chiamata al servizio API e aggiungo l'utente
      let payload = {
        nome: this.state.giocatoreA2_Nome,
        cognome: this.state.giocatoreA2_Cognome,
        email: this.state.giocatoreA2_ID,
      }
      API.post('invitoiscrizione', 
               'iscrizionesuinvito', 
               payload, 
               (nuovoGiocatore)=>{this.setState({giocatoreA2_ID: nuovoGiocatore.id }, () => this.aggiornamentoPartitaDB(payloadGiochi))}, 
               (datiErrore)=>{this.setState({messaggioErrore: datiErrore.error})},
               header) 
    }
    
    // Giocatore B1
    if((''+this.state.giocatoreB1_ID).indexOf('@')> -1) {
      // Effettuo la chiamata al servizio API e aggiungo l'utente
      let payload = {
        nome: this.state.giocatoreB1_Nome,
        cognome: this.state.giocatoreB1_Cognome,
        email: this.state.giocatoreB1_ID,
      }
      API.post('invitoiscrizione', 
               'iscrizionesuinvito', 
               payload, 
               (nuovoGiocatore)=>{this.setState({giocatoreB1_ID: nuovoGiocatore.id }, () => this.aggiornamentoPartitaDB(payloadGiochi))}, 
               (datiErrore)=>{this.setState({messaggioErrore: datiErrore.error})},
               header) 
    }
    // Giocatore B2
    if((''+this.state.giocatoreB2_ID).indexOf('@')> -1) {
      // Effettuo la chiamata al servizio API e aggiungo l'utente
      let payload = {
        nome: this.state.giocatoreB2_Nome,
        cognome: this.state.giocatoreB2_Cognome,
        email: this.state.giocatoreB2_ID,
      }
      API.post('invitoiscrizione', 
               'iscrizionesuinvito', 
               payload, 
               (nuovoGiocatore)=>{this.setState({giocatoreB2_ID: nuovoGiocatore.id }, () => this.aggiornamentoPartitaDB(payloadGiochi))},  
               (datiErrore)=>{this.setState({messaggioErrore: datiErrore.error})},
               header) 
    }

  }

  // Funzione per inserimento partita a DB tramite API Call
  aggiornamentoPartitaDB =  (payloadGiochi) => {
    // Se uno dei giocatori non ha l'ID associato, esci
    if(
        ((''+this.state.giocatoreA1_ID).indexOf('@')> -1)||
        ((''+this.state.giocatoreA2_ID).indexOf('@')> -1)||
        ((''+this.state.giocatoreB1_ID).indexOf('@')> -1)||
        ((''+this.state.giocatoreB2_ID).indexOf('@')> -1) 
      ){
        return; 
      }


    // Se tutti i giocatori hanno l'ID, procedo con inserimento partita
    let header = {token: this.props.token}
    let payloadPartita= payloadGiochi
    payloadPartita["Giocatori"] = 
        {
          ID1: this.state.giocatoreA1_ID,
          ID2: this.state.giocatoreA2_ID,
          ID3: this.state.giocatoreB1_ID,
          ID4: this.state.giocatoreB2_ID,
        }
    payloadPartita["appendURL"] = this.state.idPartita

    // Aggiungo nuova partita
    API.put('nuovaPartita', 'partite', payloadPartita, this.modificaAvvenuta, this.modificaErrore, header) 

  }


  // Funzione di gestione iscrizione effettuata
  modificaAvvenuta = (datiPartita) => {
  // Invio l'utente alla pagina di conferma inserimento
    this.setState({
      partitaInserita:true,
    })  
  }

  // Funzione di gestione iscrizione errata
  modificaErrore = (datiErrore) => {
    this.setState({messaggioErrore: datiErrore.error})
  }


  // Funzione per la gestione della chiusura pop-over di credenziali errate
  onChiusuraMessaggioErrore = (event, reason) => {
    this.setState({messaggioErrore: ''})
  };
    

  render(){

    const {classes} = this.props;
    const {dbGiocatori} = this.state;

    dbGiocatori.sort(sortBy('Cognome', 'Nome'))

    return(
          (
          (this.state.partitaInserita) 
          ?  
            <Redirect to="/partite" />
          :
            <Container component="main" maxWidth="sm">
            <form onSubmit={this.onModificaPartita}>
            <Card className={classes.card} variant="outlined">
              <CardHeader
                  className={classes.card_header}
                  // action={
                  //   <IconButton aria-label="Menu" onClick={this.aperturaMenu}>
                  //     <MoreVertIcon />
                  //   </IconButton>
                  //   }
                  title={ <Typography onClick={this.aperturaPopupData}> <DataItaliana data={this.state.dataPartita} tipo="TXT"/> </Typography>}
                  />
                  <Menu
                    id="menu-nuovapartita"
                    anchorEl={this.state.ancoraMenu}
                    keepMounted
                    open={Boolean(this.state.ancoraMenu)}
                    onClose={this.chiusuraMenu}
                  >
                  <MenuItem classes = {{root: classes.menu}}>
                      <ListItemIcon onClick={this.chiusuraMenu} ><EventIcon fontSize="small" /></ListItemIcon>
                      <ListItemText onClick={this.aperturaPopupData} primary="Cambia data"/>
                  </MenuItem>
                  {/* <MenuItem classes = {{root: classes.menu}}>
                      <ListItemIcon onClick={this.chiusuraMenu} ><RepeatIcon fontSize="small" /></ListItemIcon>
                      <ListItemText onClick={this.chiusuraMenu} primary="Ripeti ultime squadre"/>
                  </MenuItem> */}
                  <MenuItem classes = {{root: classes.menu}}>
                      <ListItemIcon onClick={this.chiusuraMenu} ><CloseIcon fontSize="small" /></ListItemIcon>
                      <ListItemText onClick={this.chiusuraMenu} primary="Chiudi menu"/>
                  </MenuItem>
                  </Menu>


              <CardContent>
                <Grid container spacing={1}>
                  <Grid xs={3} className={classes.gridItem_giocatore} item>
                    <Avatar className={classes.avatarA} style={ this.state.posizioneGiocatore === 'A1' ? {borderStyle: 'dashed'} : {} } src={this.state.giocatoreA1_AvatarUrl} onClick={()=>this.onRicercaGiocatore('A1')} />
                    <Typography className={classes.gridItem_giocatore_nome}> {this.state.giocatoreA1_Label} </Typography>
                  </Grid>
                  <Grid xs={3} className={classes.gridItem_giocatore} item>
                    <Avatar className={classes.avatarA} style={ this.state.posizioneGiocatore === 'A2' ? {borderStyle: 'dashed'} : {} } src={this.state.giocatoreA2_AvatarUrl} onClick={()=>this.onRicercaGiocatore('A2')} />
                    <Typography className={classes.gridItem_giocatore_nome}> {this.state.giocatoreA2_Label} </Typography>
                  </Grid>
                  <Grid xs={2} className={classes.gridItem_punteggio} item>
                    <Paper variant="outlined" className={classes.gridItem_paper}>
                      <Input
                        type="number"
                        id="punteggio1A"
                        name="punteggio1A"
                        disableUnderline
                        value={this.state.punteggio1A}
                        onChange={this.onCambioPunteggio}
                        inputProps={{ inputMode: 'numeric' }}
                        classes={{
                          input: classes.inputPunteggio
                        }}
                      />
                    </Paper>
                  </Grid>
                  <Grid xs={2} className={classes.gridItem_punteggio} item>
                    <Paper variant="outlined" className={classes.gridItem_paper}>
                        <Input
                          type="number"
                          id="punteggio2A"
                          name="punteggio2A"
                          disableUnderline
                          value={this.state.punteggio2A}
                          onChange={this.onCambioPunteggio}
                          inputProps={{ inputMode: 'numeric' }}
                          classes={{
                            input: classes.inputPunteggio
                          }}
                        />
                    </Paper>
                  </Grid>
                  <Grid xs={2} className={classes.gridItem_punteggio} item>
                    <Paper variant="outlined" className={classes.gridItem_paper}>
                        <Input
                          type="number"
                          id="punteggio3A"
                          name="punteggio3A"
                          disableUnderline
                          value={this.state.punteggio3A}
                          onChange={this.onCambioPunteggio}
                          inputProps={{ inputMode: 'numeric' }}
                          classes={{
                            input: classes.inputPunteggio
                          }}
                        />
                    </Paper>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid xs={3} className={classes.gridItem_giocatore} item>
                      <Avatar className={classes.avatarB} style={ this.state.posizioneGiocatore === 'B1' ? {borderStyle: 'dashed'} : {} } src={this.state.giocatoreB1_AvatarUrl} onClick={()=>this.onRicercaGiocatore('B1')}/>
                      <Typography className={classes.gridItem_giocatore_nome}> {this.state.giocatoreB1_Label} </Typography>
                    </Grid>
                    <Grid xs={3} className={classes.gridItem_giocatore} item>
                      <Avatar className={classes.avatarB} style={ this.state.posizioneGiocatore === 'B2' ? {borderStyle: 'dashed'} : {} } src={this.state.giocatoreB2_AvatarUrl} onClick={()=>this.onRicercaGiocatore('B2')}/>
                      <Typography className={classes.gridItem_giocatore_nome}> {this.state.giocatoreB2_Label} </Typography>
                    </Grid>
                    <Grid xs={2} className={classes.gridItem_punteggio} item>
                      <Paper variant="outlined" className={classes.gridItem_paper}>
                        <Input
                          type="number"
                          id="punteggio1B"
                          name="punteggio1B"
                          disableUnderline
                          value={this.state.punteggio1B}
                          onChange={this.onCambioPunteggio}
                          inputProps={{ inputMode: 'numeric' }}
                          classes={{
                            input: classes.inputPunteggio
                          }}
                        />
                      </Paper>
                    </Grid>
                    <Grid xs={2} className={classes.gridItem_punteggio} item>
                      <Paper variant="outlined" className={classes.gridItem_paper}>
                          <Input
                            type="number"
                            id="punteggio2B"
                            name="punteggio2B"
                            value={this.state.punteggio2B}
                            onChange={this.onCambioPunteggio}
                            inputProps={{ inputMode: 'numeric' }}
                            disableUnderline
                            classes={{
                              input: classes.inputPunteggio
                            }}
                          />
                      </Paper>
                    </Grid>
                    <Grid xs={2} className={classes.gridItem_punteggio} item>
                      <Paper variant="outlined" className={classes.gridItem_paper}>
                          <Input
                            type="number"
                            id="punteggio3B"
                            name="punteggio3B"
                            value={this.state.punteggio3B}
                            onChange={this.onCambioPunteggio}
                            inputProps={{ inputMode: 'numeric' }}
                            disableUnderline
                            classes={{
                              input: classes.inputPunteggio
                            }}
                          />
                      </Paper>
                    </Grid>
                  </Grid>
              </CardContent>
            </Card>
              <Fab aria-label="add" variant="extended"
                  className={this.state.giocatoriInseriti? classes.fab : classes.fab_off}
                  type={this.state.giocatoriInseriti ? 'submit' : 'button'}>
                <CheckIcon/>  {this.state.giocatoriInseriti? 'Salva' : ''}
              </Fab>
            </form>
            {this.state.ricercaGiocatore ? 
              <Fragment>
                <RicercaGiocatore dbGiocatori={dbGiocatori} onSelezionaGiocatore={this.onSelezionaGiocatore} /> 
              </Fragment>
              : ''}
            <div style={{height: 60}}> </div>
            {/* Pop up modifica data */}
            <Dialog open={this.state.popupModificaData} onClose={this.chiusuraPopupData} aria-labelledby="form-dialog-title">
              <DialogTitle id="form-dialog-title">Cambio data</DialogTitle>
              <DialogContent>
                {/* <DialogContentText>
                  Data partita
                </DialogContentText> */}
                
                <TextField
                  id="dataPartita"
                  type="date"
                  defaultValue={this.state.dataPartita}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={this.onModificaData}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={this.chiusuraPopupData} color="primary">
                  Conferma
                </Button>
              </DialogActions>
            </Dialog>

            <Snackbar  open={this.state.messaggioErrore !== ''? true : false} autoHideDuration={4000} className={classes.snackbar}  onClose={this.onChiusuraMessaggioErrore} >
                <Alert
                  variant="filled"
                  elevation={6}
                  severity="error"
                  action={
                    <CloseIcon />
                  }
                  onClick={this.onChiusuraMessaggioErrore}
                  >
                  {this.state.messaggioErrore}
                </Alert>
            </Snackbar>

          </Container>
          )       
    )
  }
}

export default compose(
  withStyles(styles),
  withRouter, 
) (ModificaPartita)

