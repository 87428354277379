// Componenti React
import React,{Component, Fragment} from 'react';


import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { withStyles } from '@material-ui/core/styles';


const styles = {

  card_contenuto_titolo:{
    overflow:'hidden',
  },

};

class Privacy extends Component {

  constructor(props) {
    super(props);
    this.state = {
      menuAperto: false,
    }
  }
  
  // Gestione apertura form nuovo giocatore
  gestioneMenu = (apertura) => (event) => {
    this.setState({menuAperto: apertura});
  };

  render(){

    // Variabili props
    const {classes} = this.props;

    return (
             <Fragment>
              <Typography variant="subtitle2" className={classes.card_contenuto_titolo} onClick={this.gestioneMenu(true)}>Accetto l'<u>informativa privacy</u></Typography>

              <Dialog
                  open={this.state.menuAperto}
                  onClose={this.gestioneMenu(false)}
                  scroll="paper"
                  aria-labelledby="scroll-dialog-title"
                  aria-describedby="scroll-dialog-description"
                >
                  <DialogTitle id="scroll-dialog-title">Informativa Privacy</DialogTitle>
                  <DialogContent dividers={true}>
                    <DialogContentText
                      id="scroll-dialog-description"
                      tabIndex={-1}
                    >
                      Cras mattis consectetur purus sit amet fermentum.
                      Cras justo odio, dapibus ac facilisis in, egestas eget quam.
                      Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
                      Praesent commodo cursus magna, vel scelerisque nisl consectetur et.
                      Cras mattis consectetur purus sit amet fermentum.
                      Cras justo odio, dapibus ac facilisis in, egestas eget quam.
                      Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
                      Praesent commodo cursus magna, vel scelerisque nisl consectetur et.
                      Cras mattis consectetur purus sit amet fermentum.
                      Cras justo odio, dapibus ac facilisis in, egestas eget quam.
                      Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
                      Praesent commodo cursus magna, vel scelerisque nisl consectetur et.
                      Cras mattis consectetur purus sit amet fermentum.
                      Cras justo odio, dapibus ac facilisis in, egestas eget quam.
                      Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
                      Praesent commodo cursus magna, vel scelerisque nisl consectetur et.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={this.gestioneMenu(false)} className={classes.button}>
                      Chiudi
                    </Button>
                  </DialogActions>
                </Dialog>
            </Fragment>
    )
  }
}


export default withStyles(styles)(Privacy)
