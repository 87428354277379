import React,{Component} from 'react';

// Chiamate API Rest
import API from "../../../helpers/API/apiCaller/APIManager"
import axios from 'axios';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import LinkUI from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import ImageUploading from 'react-images-uploading';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';

// Resize Avatar
import Resizer from 'react-image-file-resizer';

// Functional component per visualizzazione Copyright
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <LinkUI color="inherit" href="https://PadelRank.it">
        PadelRank.it
      </LinkUI>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// Utilizzo tema standard
const theme = createMuiTheme();

const styles = {
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },

  input: {
    display: 'none',
  },

  avatar:{
    marginTop: 30,
    borderColor: '#19d3da',
    borderStyle: 'solid',
    width: theme.spacing(18),
    height: theme.spacing(18),
  },

  button: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor:'#373a40',
    "&:hover": {
      backgroundColor: "#373a40"
    },
    color: 'white',
  },

  link: {
    color:'#373a40',
  },

  snackbar: {
    bottom: 30,
  }

};

class InserimentoAvatar extends Component {

  constructor(props) {
    super(props);

    this.state = {
      listaImmagini: '',
      avatarFile: '',
      avatarUrl:'',
      titolo: 'Clicca sul cerchio e scatta una fotografia',
      messaggioErrore: '',
    }

    // Istanzio una nuova occorrenza del servizio API
    // this.API = new API();

    
 }

  // Gestione caricamento immagine
  onClickAvatar = (listaImmagini, indiceImmagineAggiornata)  => {
    this.setState({listaImmagini:listaImmagini})
    let avatarData = Object.values(listaImmagini)[0] == null ? '' : Object.values(listaImmagini)[0]

    // Dimensiono il file caricato, usando il componente esterno "Resizer"
    try {
      Resizer.imageFileResizer(
      avatarData.file,
      600, // maxWidth
      600, // maxHeight
      'JPEG', // compressFormat
      90, // quality
      0, // rotation, is the degree of clockwise rotation to apply to uploaded image. 
      uri => {
          // Creo un nuovo file usando l'uri (base64) dell'immagine ridimensionata, e impostando come nome immagine "avatar"
          var fileDimensionato = new File([uri], "avatar.jpg")
          // Ricavo l'url del file ridimensionato
          const urlFileDimensionato = URL.createObjectURL(uri)
          this.setState({avatarUrl:urlFileDimensionato, avatarFile: fileDimensionato})
      }, // responseUriFunc, Is the callBack function of the resized new image URI
      'blob', // outputType,  Is the output type of the resized new image (blob o base64)
      200, // minWidth,  Is the minWidth of the resized new image.
      200, // minHeight,  Is the minHeight of the resized new image
      )
    }
    catch(err) {
      // In caso di errore nella procedura di dimensionamento, utilizzo l'immagine originale
      this.setState({avatarUrl:avatarData.dataUrl, avatarFile: avatarData.file})
    }

    this.setState({titolo: 'Clicca sul cerchio e scatta una fotografia'})

  }

  // Funzione di gestione iscrizione effettuata
  onAvatarCasuale = () => {
    const avatarDisponibili = ["1.jpg", "2.jpg", "3.jpg", "4.jpg"]
    const avatarCasuale = avatarDisponibili[this.getRandomIntInclusive(0,3)]
    const avatarCasualeUrl = `images/avatars/${avatarCasuale}`
  
    // Ricavo un oggetto "File" dall'avatar casuale selezionato, e aggiorno lo stato relativo (come se fosse stato l'utente a selezionare l'Avatar)
    const config = { responseType: 'blob' };
    axios.get(avatarCasualeUrl, config)
          .then(response => {
          this.setState({avatarFile: new File([response.data], "Casuale_" + avatarCasuale)})     
          });

    this.setState({titolo:'Abbiamo scelto una fotografia per te.. puoi cambiarla se vuoi, cliccaci sopra!'})
    this.setState({avatarUrl:avatarCasualeUrl})
    this.setState({messaggioErrore: ''})
  } 

  // Ottenere un intero random tra due valori con estremi inclusi
  getRandomIntInclusive = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min; //Il max è incluso e il min è incluso 
  }

  // Gestione Proseguo
  onClickProsegui = () => {
    let token = this.props.token;
    let header = {token: token}

    if(this.state.avatarUrl !== '' && this.props.token !== ''){
        let payload = {avatarFile: this.state.avatarFile}
        API.post('utente', 'utenteModifica', payload, this.avatarInserito, this.avatarErrore, header) 
      }
    else
     this.setState({messaggioErrore: "Non essere timido!"})
  }

  // Funzione di gestione iscrizione effettuata
  avatarInserito = (avatarData) => {
    this.props.onInserimentoAvatar(avatarData)
  }

  // Funzione di gestione iscrizione errata
  avatarErrore= (datiErrore) => {
    this.setState({messaggioErrore: datiErrore.error})
    console.log("Errore immagine")
  }

  // Funzione per la gestione della chiusura pop-over di credenziali errate
  onChiusuraMessaggioAlert = (event, reason) => {
    this.setState({messaggioErrore: ''})
  };

  render(){
    const {classes} = this.props;
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            Avatar
          </Typography>
          <Typography>
            {this.state.titolo}
          </Typography>
          
          {/* https://codesandbox.io/s/react-images-uploading-demo-forked-wr3nw?file=/src/index.js */}
          <ImageUploading
            value={this.state.listaImmagini}
            onChange={this.onClickAvatar}
            dataURLKey="dataUrl"
          >
            {({onImageUpload, dragProps}) => (
              // Avatar chooser UI
                <Avatar 
                  className={classes.avatar} 
                  src={this.state.avatarUrl}
                  onClick={onImageUpload} {...dragProps} >
                  <PhotoCamera />
                </Avatar>
            )}
          </ImageUploading>
          
          <div className={classes.root}>
            <div height='50px' width='400px'></div>
            <Button variant="contained" component="span" className={classes.button} onClick={this.onClickProsegui}>
              Prosegui..
            </Button>
          </div>
        </div>
        <Snackbar  open={this.state.messaggioErrore !== ''? true : false} autoHideDuration={4000} className={classes.snackbar}  onClose={this.onChiusuraMessaggioAlert} >
                <Alert
                  elevation={6}
                  severity="error"
                  action={
                      <Button color="inherit" size="small">
                        LO SONO..
                      </Button>
                  }
                  onClick={this.onAvatarCasuale}
                  >
                  {this.state.messaggioErrore}
                </Alert>
        </Snackbar>        
        <Box mt={8} style={{position: 'fixed', bottom: 10,  width:'100%', }}>
          <Copyright />
        </Box>
      </Container>
    );
  }
}

export default withStyles(styles) (InserimentoAvatar)