import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom'

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
//import Checkbox from '@material-ui/core/Checkbox';
import LinkUI from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
//import Box from '@material-ui/core/Box';
//import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import DraftsIcon from '@material-ui/icons/Drafts';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

// Componenti per azioni su REST API
import API from "../../../helpers/API/apiCaller/APIManager"

// function Copyright() {
//   return (
//     <Typography variant="body2" color="textSecondary" align="center">
//       {'Copyright © '}
//       <LinkUI color="inherit" href="https://PadelRank.it">
//         Stealth Srl
//       </LinkUI>{' '}
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// }
// Utilizzo tema standard
const theme = createMuiTheme();
const style = {
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#19d3da',
  },

  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor:'#373a40',
    "&:hover": {
      backgroundColor: "#373a40"
    },
    color: 'white',

  },

  textField: {
    '& label.Mui-focused': {
      color: '#19d3da',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {

      },
      '&.Mui-focused fieldset': {
        borderColor: '#19d3da',
      },
      
   },
  },

  textFieldSuccess: {
    // Label on focus on
    '& label.Mui-focused': {
      color: '#95d5b2',
    },
    // Campo input 
    '& .MuiOutlinedInput-root': {

      // Default
      '& fieldset': {
        borderColor: '#95d5b2',
        borderWidth: 2,
      },

      // Default mouse over
      '&:hover fieldset': {
        borderColor: '#95d5b2',
        borderWidth: 2,
      },
      // Quando sono dentro al campo
      '&.Mui-focused fieldset': {
        borderColor: '#95d5b2',
        borderWidth: 2,
      },

   },
  },

  link: {
    color:'#373a40',
  },

  snackbar: {
    bottom: 30,
  }


};

class PasswordDimenticata extends Component {

  constructor(props) {
    super(props);
    this.state = {
      form: {
        input: {
          email: '',
        },
        errori: {
          email: '',  
        },
        validazione: {
          email: false,
        },
        utility: {
          mostraHelper: false,
        }   
      },
      messaggioErrore: '',
      richiestaInviata: false,
    }
    
 }

  //Funzione di gestione modifica valore di campo di input 
  onModificaInput = (event) => {
    let input = this.state.form.input
    input[event.target.name] = event.target.value
    this.setState({input}, () => this.verificaInput())   
  }
  
  // Funzione per verificare la correttezza dei campi inseriti
  verificaInput = () => {
    let input = this.state.form.input
    let errori = this.state.form.errori
    let validazione = this.state.form.validazione


    // Email
    if (!this.isEmailValida(input.email)) {
      errori['email'] = 'Indirizzo email non valido'
      validazione['email'] = false
    } else 
    {
      errori['email'] = ''
      validazione['email'] = true
    }
  
    // Aggiorno i campi di validazione del form 
    this.setState({errori: errori})
    this.setState({validazione: validazione})

  }

  // Utilità di validazione indirizzo email
  isEmailValida = (val) => {
      let regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if(!regEmail.test(val))
        return false
      return true
  }

  // Funzione di gestione pulsante "Recupera password" (tramite API)
  onPressRichiesta = (event) => {
 
  // Verifico che tutti i campi siano correttamente compilati
  // Converto l'oggetto "validazione" in un array
  const validazioneArray = Object.values(this.state.form.validazione);
  // Riduco l'array ad un unico valore booleano (combinazione &&)
  const reducer = (accumulatore, valore) => accumulatore && valore

  // Se tutti i campi sono validati
  if(validazioneArray.reduce(reducer,true)) {
    let input = this.state.form.input
    //Effettuo la chiamata al servizio API
    let payload = {
      email:input.email, 
    }
    API.post('recover', 'recuperoPassword', payload, this.recuperoAvvenuto, this.recuperoErrore) 
  } else
  {
    // Non tutti i campi sono validati, quindo mostro tutti gli Helper e messaggio generale
    let utility = this.state.form.utility
    utility['mostraHelper'] = true;
    this.setState({utility})
    this.setState({messaggioErrore: "Compilare correttamente tutti i campi"})
  }
  }

  // Funzione per la gestione della chiusura pop-over di credenziali errate
  onChiusuraMessaggioErrore = (event, reason) => {
    this.setState({messaggioErrore: ''})
  };

  // Funzione di gestione iscrizione effettuata
  recuperoAvvenuto = () => {
    this.setState({richiestaInviata:true})
  }

  // Funzione di gestione iscrizione errata
  recuperoErrore = (datiErrore) => {
    this.setState({messaggioErrore: datiErrore.error})
  }

  render() {
  const {classes} = this.props;
  const {input, validazione, errori, utility}   = this.state.form

  return (
    <Fragment>
      {
      (!this.state.richiestaInviata)
      ?  
        <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <DraftsIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Recupero password
          </Typography>
          < TextField
              size="small"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              value={input.email}
              onChange={this.onModificaInput}
              classes= {validazione.email ? {root: classes.textFieldSuccess}: {root: classes.textField}  }
              helperText = {utility.mostraHelper ? errori.email : ''}
            />
            <Button
              type="submit"
              disabled = {!validazione.email}
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick = {this.onPressRichiesta}
            >
              Invia istruzioni di recupero
            </Button>

            <Grid container>
              <Grid item xs >
                <LinkUI href="#" variant="body2" classes={{ root: classes.link}} component={Link} to="/">
                  Torna alla home
                </LinkUI>
              </Grid>
              <Grid item>
                <LinkUI href="#" variant="body2" classes={{ root: classes.link}} component={Link} to="/iscrizione">
                  Nuova iscrizione?
                </LinkUI>
              </Grid>
            </Grid>

        </div>
        <Snackbar  open={this.state.messaggioErrore !== ''? true : false} autoHideDuration={4000} className={classes.snackbar}  onClose={this.onChiusuraMessaggioErrore} >
                  <Alert
                    variant="filled"
                    elevation={6}
                    severity="error"
                    action={
                      <CloseIcon />
                    }
                    onClick={this.onChiusuraMessaggioErrore}
                    >
                    {this.state.messaggioErrore}
                  </Alert>
          </Snackbar>
        {/* <Box mt={8} style={{position: 'fixed', bottom: 10,  width:'100%', }}>
          <Copyright />
        </Box> */}
      </Container>
      :
        <Container component="main" maxWidth="xs">
        <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
             <DraftsIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Ripristino effettuato
            </Typography>
            <Typography>
              Controlla la tua email e segui la procedura indicata
            </Typography>

            <Button
              type="submit"
              disabled = {!validazione.email}
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              component={Link} 
              to="/"
            >
             Torna in home
            </Button>          

          </div>  

        </Container>    
      }  
    </Fragment>
    )
  }

}

export default withStyles(style)(PasswordDimenticata)
