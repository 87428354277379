// Classe per  nominare correttamente i campi da/verso le chiamate API
class dataMask {
    
    encode = (payload) => {
            let newPayload = {
                email: payload.email, 
                nome: payload.nome,
                cognome: payload.cognome,
            }
            return newPayload;
        }
    
    decode = (payload) => {
            let newPayload = {
                id: payload.data.user.id,
            }
            return newPayload;
        } 
}

var invitoiscrizione = new dataMask();
export default invitoiscrizione


