import React  from 'react';
import ReactDOM from 'react-dom';
import Main from './components/Main'

// Gestione Router
import {BrowserRouter} from 'react-router-dom'

// Gestione dei Cookies
import { CookiesProvider } from 'react-cookie';

ReactDOM.render(
    <CookiesProvider>
      <BrowserRouter>        
          <Main loginbymail={false}/>
      </BrowserRouter>
    </CookiesProvider>,
  document.getElementById('root'));
