// Componenti React
import React,{Component} from 'react';

// Componenti Material UI
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
//import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
// import StarIcon from '@material-ui/icons/Star';

import { withStyles } from '@material-ui/core/styles';


const styles = {

  card: {
    minWidth: 275,
    height: 50,
    marginTop: 10,
    marginBottom: 10,
    display:'flex',
    flexGrow: 0,

  },

  card_image: {
    width: 50,
    flexShrink: 0,
  },

  card_datigiocatore:{
    flexGrow: 1,
  },

  card_datigiocatore_titolo:{
    overflow:'hidden',
  },

  card_select_M: {
    width: 50,
    flexShrink: 0,
    backgroundColor: '#eeeeee',
  },

  card_select_F: {
    width: 50,
    flexShrink: 0,
    backgroundColor: '#fff0f0'
  },

};

class RicercaGiocatoreCard extends Component {

  render(){

    // Variabili props
    const {giocatore} = this.props;
    const {classes} = this.props;
    const {onSelezionaGiocatore}=this.props;
    return (
              <Card className={classes.card} variant="outlined">
                  <CardMedia
                    className={classes.card_image}
                    image={giocatore.Avatar}
                    title={`${giocatore.Nome} ${giocatore.Cognome}`}
                  />
                <CardActionArea onClick={()=>onSelezionaGiocatore(giocatore)}>
                    <CardContent className={classes.card_datigiocatore} >
                        <Typography className={classes.card_datigiocatore_titolo}>{`${giocatore.Nome} ${giocatore.Cognome}`}</Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
    )
  }
}


export default withStyles(styles)(RicercaGiocatoreCard)
