import React,{Component} from 'react';

// Chiamate API Rest
import API from "../../../helpers/API/apiCaller/APIManager"

import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';

import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import ImageUploading from 'react-images-uploading';
import Fab from '@material-ui/core/Fab';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

// Resize Avatar
import Resizer from 'react-image-file-resizer';


// Utilizzo tema standard
const theme = createMuiTheme();

const styles = {
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  avatar:{
    marginTop: 20,
    borderColor: '#19d3da',
    borderStyle: 'solid',
    width: theme.spacing(14),
    height: theme.spacing(14),
  },

  avatar_modificabile:{
    marginTop: 20,
    borderColor: '#19d3da',
    borderStyle: 'dashed',
    width: theme.spacing(14),
    height: theme.spacing(14),
  },

  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor:'#373a40',
    "&:hover": {
      backgroundColor: "#373a40"
    },
    color: 'white',

  },

  textField: {
    '& label.Mui-focused': {
      color: '#19d3da',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {

      },
      '&.Mui-focused fieldset': {
        borderColor: '#19d3da',
      },
      
   },
  },

  textFieldSuccess: {
    // Label on focus on
    '& label.Mui-focused': {
      color: '#95d5b2',
    },
    // Campo input 
    '& .MuiOutlinedInput-root': {

      // Default
      '& fieldset': {
        borderColor: '#95d5b2',
        borderWidth: 2,
      },

      // Default mouse over
      '&:hover fieldset': {
        borderColor: '#95d5b2',
        borderWidth: 2,
      },
      // Quando sono dentro al campo
      '&.Mui-focused fieldset': {
        borderColor: '#95d5b2',
        borderWidth: 2,
      },

   },
  },


  link: {
    color:'#373a40',
  },

  snackbar: {
    bottom: 30,
  },

  fab: {
    position: 'fixed',
    bottom: 85,
    right: 15,
    color: 'white',
    backgroundColor:'#19d3da',
    '&:hover': {
      background: "#19d3da",
    }
   }, 

};

const RadioMaschio = withStyles({
  root: {
    color:  'grey',

  },
})(Radio);

const RadioFemmina = withStyles({
  root: {
    color:  'grey',
  },
})(Radio);

class SchedaUtente extends Component {

  constructor(props) {
    super(props);
    this.state = {
      input: {
        nome : '',
        cognome: '',
        email: '',
        genere: '',
      },
      errori: {
        nome : '',
        cognome: '',
        email: '',
        genere: '',
      },
      validazione: {
        nome : true,
        cognome: true,
        email: true,
        genere: true,
      },
      utility: {
        mostraHelper: false,
      },   
      listaImmagini: '',
      avatarFile: '',
      avatarUrl:'',
      messaggioErrore: '',
      abilitaModifica : false,
      token: this.props.token,
    }

 }

  // Precarico il DB utente
  componentDidMount(){
    // Ricavo i dati dell'utente
    this.ricavaDatiUtente(this.props.token);
  }

   //Funzione di gestione modifica valore di campo di input 
  onModificaInput = (event) => {
    let {input} = this.state
    input[event.target.name] = event.target.value
    this.setState({input}, () => this.verificaInput())   
  }
  
 
  // Aggiorno i dati utente, in funzione del token di ritorno
  ricavaDatiUtente = (token) => {
    let payload = {} 
    let header = {token: token}

    //Effettuo la chiamata al servizio API e aggiorno lo stato con i dati dell'utente
    // Ritorno true se l'utente esiste ed ho aggiornato lo stato con i suoi dati, false se non esiste
    API.get(
      'utente', // dataType (econde/decode API)
      'utente', 
      payload, 
      (datiUtenteAutenticato) => {
        // Ricavo solo i dati che mi servono dalla tabella utente
        let {input} = this.state
        input['nome'] = datiUtenteAutenticato.nome
        input['cognome'] = datiUtenteAutenticato.cognome
        input['email'] = datiUtenteAutenticato.email
        input['genere'] = datiUtenteAutenticato.genere
        
        this.setState({
          input,
          avatarUrl: datiUtenteAutenticato.avatarUrl,
          })      
        return true
      }, 
      () => {return false},
      header
    )
      
  }

  // Funzione per verificare la correttezza dei campi inseriti
  verificaInput = () => {
    let {input} = this.state
    let {errori} = this.state
    let {validazione} = this.state

    // Nome
    if((input.nome.length < 2)){
      errori['nome'] = 'Almeno 2 caratteri'
      validazione['nome'] = false
    } else {
        errori['nome'] = ''
        validazione['nome'] = true
    }

    // Cognome
    if(input.cognome.length < 2){
      errori['cognome'] = 'Almeno 2 caratteri'
      validazione['cognome'] = false    
    } else 
    {
      errori['cognome'] = ''
      validazione['cognome'] = true
    }

    // Email
    if (!this.isEmailValida(input.email)) {
      errori['email'] = 'Indirizzo email non valido'
      validazione['email'] = false
    } else 
    {
      errori['email'] = ''
      validazione['email'] = true
    }

    // Aggiorno i campi di errore e validazione del form 
    this.setState({errori})
    this.setState({validazione})

  }

  // Utilità di validazione indirizzo email
  isEmailValida = (val) => {
      let regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if(!regEmail.test(val))
        return false
      return true
  }
  
    // Funzione di gestione pulsante "Accedi" (tramite API)
  onPressRichiestaModifica = (event) => {
      this.setState({abilitaModifica: true})
    }
 
  // Funzione di gestione pulsante "Accedi" (tramite API)
  onPressSalvaModifica = (event) => {
    // Evito che il form segua il suo comportamento di default, ovvero aprire una pagine di destinazione (<Form action="...">)
    event.preventDefault();
    
    // Verifico che tutti i campi siano correttamente compilati
    // Converto l'oggetto "validazione" in un array
    const validazioneArray = Object.values(this.state.validazione);
    // Riduco l'array ad un unico valore booleano (combinazione &&)
    const reducer = (accumulatore, valore) => accumulatore && valore

  
    // Se tutti i campi sono validati
    if(validazioneArray.reduce(reducer,true)) {
      const {input} = this.state

      //Effettuo la chiamata al servizio API
      const payload = {
        nome: input.nome,
        cognome: input.cognome,
        email:input.email, 
        avatarFile: this.state.avatarFile,
        genere: input.genere,
      }


      let header = {token: this.state.token}
      API.post('utente', 'utenteModifica', payload, this.aggiornamentoDatiAvvenuto, this.aggiornamentoDatiErrore, header) 
    } else
    {
      // Non tutti i campi sono validati, quindo mostro tutti gli Helper e messaggio generale
      let {utility} = this.state
      utility['mostraHelper'] = true;
      this.setState({utility})
      this.setState({messaggioErrore: "Compilare correttamente tutti i campi"})
    }
  }
  
  // Funzione per la gestione della chiusura pop-over di credenziali errate
  onChiusuraMessaggioErrore = (event, reason) => {
    this.setState({messaggioErrore: ''})
  };

  // Funzione di gestione iscrizione effettuata
  aggiornamentoDatiAvvenuto = (SchedaUtente) => {
    let {input} = this.state
    input['nome'] = SchedaUtente.nome
    input['cognome'] = SchedaUtente.cognome
    input['email'] = SchedaUtente.email
    this.setState({input});
    this.setState({
      avatarUrl: SchedaUtente.avatarUrl,
      abilitaModifica: false
     })   
    
     // Aggiorno anche i dati nell'header

    const datiUtente = {
      id: SchedaUtente.id,
      nome: SchedaUtente.nome,
      cognome:SchedaUtente.cognome,
      email: SchedaUtente.email,
      avatarUrl:SchedaUtente.avatarUrl,
      token: this.props.token,
    }

    this.props.onModificaDatiUtente(datiUtente)
  }

  // Funzione di gestione iscrizione errata
  aggiornamentoDatiErrore = (datiErrore) => {
    this.setState({messaggioErrore: datiErrore.error})
    console.log("Errore API Modifica scheda utente")
  }

  // Gestione caricamento immagine
  onChangeAvatar = (listaImmagini, indiceImmagineAggiornata)  => {
    // listaImmagini contiene la lista dei file caricati tramite il componente ImageUploader (in questo caso, una sola immagine)
    // Ciascun elemento di listaImmagini ha due proprietà: "dataUrl" (l'URL locale del file), e "file", ovvero il file vero e proprio.
    this.setState({listaImmagini:listaImmagini})
    let avatarData = Object.values(listaImmagini)[0] == null ? '' : Object.values(listaImmagini)[0]

    // Dimensiono il file caricato, usando il componente esterno "Resizer"
    try {
      Resizer.imageFileResizer(
      avatarData.file,
      600, // maxWidth
      600, // maxHeight
      'JPEG', // compressFormat
      90, // quality
      0, // rotation, is the degree of clockwise rotation to apply to uploaded image. 
      uri => {
          // Creo un nuovo file usando l'uri (base64) dell'immagine ridimensionata, e impostando come nome immagine "avatar"
          var fileDimensionato = new File([uri], "avatar.jpg")
          // Ricavo l'url del file ridimensionato
          const urlFileDimensionato = URL.createObjectURL(uri)
          this.setState({avatarUrl:urlFileDimensionato, avatarFile: fileDimensionato})
      }, // responseUriFunc, Is the callBack function of the resized new image URI
      'blob', // outputType,  Is the output type of the resized new image (blob o base64)
      200, // minWidth,  Is the minWidth of the resized new image.
      200, // minHeight,  Is the minHeight of the resized new image
      )
    }
    catch(err) {
      // In caso di errore nella procedura di dimensionamento, utilizzo l'immagine originale
      this.setState({avatarUrl:avatarData.dataUrl, avatarFile: avatarData.file})
    }
    
  }

  onClickAvatarBloccato = () => {
    this.setState({messaggioErrore: 'Premere sul pulsante modifica ;)'})
  }
  

  render(){
    const {classes} = this.props;
    const {input, validazione, errori, utility}   = this.state
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
              Profilo Utente
            </Typography>
            <Typography>
              {input.nome+ ' ' + input.cognome}
            </Typography>
            
            {/* https://codesandbox.io/s/react-images-uploading-demo-forked-wr3nw?file=/src/index.js */}
            <ImageUploading
              value={this.state.listaImmagini}
              onChange={this.onChangeAvatar}
              dataURLKey="dataUrl"
            >
              {({onImageUpload, dragProps}) => (
                // Avatar chooser UI
                  <Avatar 
                    className={!this.state.abilitaModifica ? classes.avatar : classes.avatar_modificabile} 
                    src={this.state.avatarUrl}
                    onClick={this.state.abilitaModifica ?  onImageUpload : this.onClickAvatarBloccato } {...dragProps}>
                    <PhotoCamera />
                  </Avatar>
              )}
            </ImageUploading>
            <TextField
              disabled= {!this.state.abilitaModifica}
              size="small"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="nome"
              label="Nome"
              name="nome"
              autoComplete="off"
              autoFocus
              value={input.nome}
              onChange={this.onModificaInput}
              classes= {validazione.nome ? {root: classes.textFieldSuccess}: {root: classes.textField}  }
              helperText = {utility.mostraHelper ? errori.nome : ''}
            />
            <TextField
              disabled= {!this.state.abilitaModifica}
              size="small"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="cognome"
              label="Cognome"
              name="cognome"
              autoComplete="off"
              autoFocus
              value={input.cognome}
              onChange={this.onModificaInput}
              classes= {validazione.cognome ? {root: classes.textFieldSuccess}: {root: classes.textField}}
              helperText = {utility.mostraHelper ? errori.cognome : ''}

            />
            <TextField
              disabled= {!this.state.abilitaModifica}
              size="small"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="off"
              autoFocus
              value={input.email}
              onChange={this.onModificaInput}
              classes= {validazione.email ? {root: classes.textFieldSuccess}: {root: classes.textField}  }
              helperText = {utility.mostraHelper ? errori.email : ''}
           />
        </div>
        {/* Genere */}          
        <RadioGroup row aria-label="genere" name="genere" value={input.genere} onChange={this.onModificaInput}>
          <FormControlLabel disabled= {!this.state.abilitaModifica}  value="M" control={<RadioMaschio  color="default" />} label="Maschietto" />
          <FormControlLabel disabled= {!this.state.abilitaModifica}  value="F" control={<RadioFemmina  color="default" />} label="Femminuccia" />
        </RadioGroup>



        {
        this.state.abilitaModifica ?
           <Fab variant="extended" aria-label="Modifica" className={classes.fab} onClick={this.onPressSalvaModifica}>
            <CheckIcon /> Salva
          </Fab>
        :
          <Fab variant="extended" aria-label="Modifica" className={classes.fab} onClick={this.onPressRichiestaModifica}>
            <EditIcon /> Modifica
          </Fab>          
        }
        <Snackbar  open={this.state.messaggioErrore !== ''? true : false} autoHideDuration={4000} className={classes.snackbar}  onClose={this.onChiusuraMessaggioErrore} >
                <Alert
                  variant="filled"
                  elevation={6}
                  severity="error"
                  action={
                    <CloseIcon />
                  }
                  onClick={this.onChiusuraMessaggioErrore}
                  >
                  {this.state.messaggioErrore}
                </Alert>
        </Snackbar>
      </Container>

    );
  }
}

export default withStyles(styles) (SchedaUtente)