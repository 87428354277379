import React, {Component, Fragment} from 'react';
import queryString from 'query-string'

// Chiamate API Rest
import API from "../../../helpers/API/apiCaller/APIManager"

import { withRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';

// Compose per utilizzare multipli Wrapper (withstyle e withcookies)
import { compose } from 'recompose'

import { withStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';

// Utilizzo tema standard
const theme = createMuiTheme();

const styles = {
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#19d3da',
  },


  link: {
    color:'#373a40',
  },

  snackbar: {
    bottom: 30,
  }

};



class FastLogin extends Component {

  constructor(props) {
    super(props);
    this.state = {
      token: '',
    }
  

 }

  // Precarico il DB utente
  componentDidMount(){

    // In primis, effettuo il logout preventivo (qualora fossi logato come altro utente su quel browser)
    this.props.onLogout();
    
    // Leggo i parametri dell'URL 
    //...ResetPassword?k=tokenResetPassword
    const parametriURL = queryString.parse(this.props.location.search)
    
    // Ricavo i dati dell'utente
    this.ricavaDatiUtente(parametriURL.k);
  }


  // Leggo i dati utente, in funzione del token di ritorno
  ricavaDatiUtente = (token) => {
    let payload = {} 
    let header = {token: token}

    //Effettuo la chiamata al servizio API e aggiorno lo stato con i dati dell'utente
    // Ritorno true se l'utente esiste ed ho aggiornato lo stato con i suoi dati, false se non esiste
    API.get(
      'utente', // dataType (econde/decode API)
      'utente', 
      payload, 
      (datiUtenteAutenticato) => {
        // Effettuo il login
        this.props.onLogin({token:token})     
      }, 
      (error) => {
        // Torno alla homepage se l'utente non è riconosciuto
        this.props.history.push("/")     
      }, 
      header
    )
      
  }
  

  render(){
    const {classes} = this.props;
    return (
      <Fragment>
        <CssBaseline />
          <div className={classes.paper}>
            <Typography component="h1" variant="h5">Attendi</Typography>
            <Typography>Login in corso..</Typography>
          </div>
      </Fragment> 
    );
  }
}

// Esporto anche gli stili presenti in questo file, con la classe Header
export default compose(
  withStyles(styles),
  withRouter, 
) (FastLogin)