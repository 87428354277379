// Componenti React
import React,{Component} from 'react';
import sortBy from 'sort-by';
import {Link} from 'react-router-dom'

// Componenti Padel Rank
import PartitaCard from './PartitaCard';

// Componenti Material UI
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';

// Componenti per azioni su REST API
import API from "../../../helpers/API/apiCaller/APIManager"

// Stili
const styles = {
  fab: {
    position: 'fixed',
    bottom: 85,
    right: 15,
    backgroundColor:'#19d3da',
    color: 'white',
  },
};

// Class Component
class Partite extends Component {

  constructor(props) {
    super(props);
    // Definizione dello stato
    this.state = {
      dbPartite: [],
      dbGiocatori:[],
      messaggioErrore: '',
    }

    // Istanzio una nuova occorrenza del servizio API
    // this.API = new API();

 }

   componentDidMount(){
    // Precarico il DB giocatori
    this.ricavaDatiPartite(this.props.token);
    //this.ricavaDatiGiocatori(this.props.token);
  }


  // Aggiorno i dati utente, in funzione del token di ritorno
  ricavaDatiPartite = (token) => {

    let payload = {}
    let header = {token: token}

    //Effettuo la chiamata al servizio API e aggiorno lo stato con i dati delle partite
    API.get(
      'partite', // dataTypes
      'partite', 
      payload, 
      (datiPartite) => {
        this.setState({
          dbPartite:datiPartite
          })      
      }, 
      (errorData) => {this.setState({messaggioErrore: errorData.error})}, 
      header,
    )
     
  }

  // Aggiorno i dati dei giocatori
  ricavaDatiGiocatori = (token) => {

    let payload = ''
    let header = {token: token} 

    //Effettuo la chiamata al servizio API e aggiorno lo stato con i dati giocatori
    API.get(
      'giocatori', // dataTypes
      'giocatori', // Api Url
      payload, 
      (datiGiocatori) => {
        this.setState({
          dbGiocatori: datiGiocatori,
          })      
        return true
      }, 
    (errorData) => {this.setState({messaggioErrore: errorData.error})},
    header
    )
      
  }

  render(){
    const {classes} = this.props;
    const {dbPartite} = this.state;

    // Ordino le partite per Data Desc
    dbPartite.sort(sortBy('-DataPartita', '-ID'))


    return(
      <Container component="main" maxWidth="sm">
        {
          // Mappo la singola partita sulla card
          dbPartite.map((partita, index) => (<PartitaCard key={index} partita={partita} />))
        }
        <div style={{height: 60}}> </div>
        <Fab variant= "extended" aria-label="add" className={classes.fab} component={Link} to="/nuovapartita">
          <AddIcon /> Nuova
        </Fab>
      </Container>
    )
  }
}

export default withStyles(styles)(Partite)
