// Componenti React
import React, {Component, Fragment} from 'react';
import {Route, Switch,} from 'react-router-dom';


// Componenti di navigazione
import Header from './layout/Header';
import Footer from './layout/Footer';
import WorkInProgress from './layout/WorkInProgress';

// Componenti Padel Rank
import Home from './home/HomeMenu';
import Login from './utente/login/Login';
import LoginByMail from './utente/loginbymail/Login';

import FastLogin from './utente/login/FastLogin';
import PasswordDimenticata from './utente/login/PasswordDimenticata';
import CambioPassword from './utente/profiloutente/CambioPassword';
import ResetPassword from './utente/login/ResetPassword';
import GestioneIscrizione from './utente/iscrizione/Gestione';
import GestioneIscrizioneSuinvito from './utente/iscrizionesuinvito/Gestione';
import GestioneIscrizioneByMail from './utente/iscrizionebymail/Gestione';

import SchedaUtente from './utente/profiloutente/SchedaUtente';
import Giocatori from './giocatori/Giocatori';
import Partite from './partite/elencopartite/Partite';
import Classifica from './classifica/Classifica';
import NuovaPartita from './partite/nuovapartita/NuovaPartita';
import ModificaPartita from './partite/modificapartita/ModificaPartita';
import Partita from './partite/partita/Partita';

//import Test from './classifica/FileUpload';

// Gestione dei Cookies
import {withCookies} from 'react-cookie';


// Componenti per azioni su REST API
import API from "../helpers/API/apiCaller/APIManager"

class Main extends Component{
  
  constructor(props) {
    super(props);
    this.state = {
      loginbymail: this.props.loginbymail,
      login: false,    
      menuFooterSelezionato:0,
      datiUtente: {
        id: '',
        nome: '',
        cognome:'',
        email:'',
        avatarUrl:'',
        token: '',
        partiteMancanti: '',
      },

      WIP: false,
    }
  }

  componentDidMount(){
      // Verifico se ho già effettuato il login in precedenza
      this.checkCookies();   
  }

  checkCookies() {
    // // Verifico se presenti i cookie del Login (se presenti, simulo il login)
    const {cookies} = this.props
    if(cookies.get('userToken') == null || cookies.get('userToken')==='' )
        this.setState({
            login: false,
        }) 
    else {
        this.onLogin({token: cookies.get('userToken')})
      }
  }

  // Funzione di accesso all'applicazione
  onLogin = (datiLogin) => {
    // Di Default, i cookies durano 30gg
    let dataScadenza = datiLogin.ricordami ? new Date(Date.now()+2592000) : 0

    // Verifico se il token corrisponde ad un utente, ed aggiorno lo stato con i dati dell'utente relativo
    let payload = {}
    let header = {token:datiLogin.token}

    // Effettuo la chiamata al servizio API e aggiorno lo stato con i dati dell'utente
    // Se l'utente non esiste, effettuo logout per sicurezza
    API.get(
      'utente', // dataType (econde/decode API)
      'utente', // Url
      payload,  // Dati 
      (datiUtenteAutenticato) => {
        // Ricavo solo i dati che mi servono dalla tabella utente
        let {datiUtente} = this.state;
        datiUtente.id = datiUtenteAutenticato.id
        datiUtente.nome = datiUtenteAutenticato.nome
        datiUtente.cognome = datiUtenteAutenticato.cognome
        datiUtente.email = datiUtenteAutenticato.email
        datiUtente.avatarUrl = datiUtenteAutenticato.avatarUrl
        datiUtente.rank = datiUtenteAutenticato.rank
        datiUtente.partiteMancanti = datiUtenteAutenticato.partiteMancanti
        datiUtente.token = datiLogin.token
        
        // Aggiorno lo stato con i dati dell'utente, e subito dopo aggiorno i Cookies
        // Attenzione: così, dopo ogni login aggiorno di 30 giorni la scadenza dei token. Forse è anche giusto così :D
        this.setState({datiUtente})
        this.setState({
            login: true,
          }, () =>{const {cookies} = this.props; cookies.set('userToken',datiLogin.token, { expires: dataScadenza, path: "/" })} )
        }, 
      () => {this.setState({login: false}, this.onLogout() )}, // Errore Login

      header // Passo il token alla chiamata API
    )

    // In ogni caso (login positivo o meno), posiziono il menu alla base, per sicurezza (nel caso faccia logout e rientri)
    this.setState({menuFooterSelezionato:0,})    
  }

  onLogout = () => {
    const {cookies} = this.props;
    if(cookies.get('userToken')){
      cookies.remove('userToken')
      }
    

    // Ripulisco i dati dell'utente
    let datiUtente = {
      id: '',
      nome: '',
      cognome: '',
      email: '',
      avatarUrl: '',
      token: '',
      rank: '',
    }

    this.setState({
        login: false,
        menuFooterSelezionato:0,
        datiUtente
      })


  }

  onModificaDatiUtente = (datiUtente) => {
      // Aggiorno lo stato con i dati dell'utente (per header)
      this.setState({datiUtente})
  }
  // Funzioni di Navigazione 
  aggiornaMenuFooterSelezionato = voceMenu => {
    this.setState({
      menuFooterSelezionato: voceMenu,
    })
  }

  render(){
      const token = this.state.datiUtente.token;
      return (
          <Fragment>
            <Header loginbymail={this.state.loginbymail}  login={this.state.login} onLogout={this.onLogout} datiUtente = {this.state.datiUtente} aggiornaMenuSelezionato={this.aggiornaMenuFooterSelezionato} helper={this.state.menuFooterSelezionato} />
            {
            (this.state.login) 
            ?            
              <Switch>
                <Route exact path="/"               ><Home partiteMancanti={this.state.datiUtente.partiteMancanti}/> </Route>
                <Route       path="/profiloutente"  ><SchedaUtente token={token} onModificaDatiUtente={this.onModificaDatiUtente}/></Route>
                <Route       path="/cambiopassword" ><CambioPassword /> </Route>
                <Route       path="/resetpassword"  ><ResetPassword   onLogout={this.onLogout} onLogin = {this.onLogin}/> </Route>
                <Route       path="/passworddimenticata"               ><PasswordDimenticata/> </Route>
                <Route       path="/giocatori"      ><Giocatori userID={this.state.datiUtente.id } token={token} /> </Route>
                <Route exact path="/partite/"       ><Partite  token={token}/> </Route>
                <Route       path="/partite/:partitaID"><Partita token={token}/> </Route>
                <Route       path="/nuovapartita"   ><NuovaPartita token={token}/> </Route>
                <Route       path="/modificapartita/:partitaID"><ModificaPartita token={token}/> </Route>
                <Route       path="/classifica"     ><Classifica onLogin = {this.onLogin} token={token}/> </Route>
                <Route                              ><Home partiteMancanti={this.state.datiUtente.partiteMancanti}/></Route>
              </Switch>
            :
              <Switch>
                <Route exact path="/"                                  >{this.state.loginbymail ? <LoginByMail onLogin = {this.onLogin}/> : <Login onLogin = {this.onLogin}/>} </Route>
                <Route       path="/login"                             >{this.state.loginbymail ? <LoginByMail onLogin = {this.onLogin}/> : <Login onLogin = {this.onLogin}/>} </Route>
                <Route       path="/fastlogin"                         ><FastLogin onLogout={this.onLogout} onLogin = {this.onLogin}/> </Route>
                <Route       path="/iscrizione"                        >{this.state.loginbymail ? <GestioneIscrizioneByMail onLogout={this.onLogout} onLogin = {this.onLogin}/> : <GestioneIscrizione onLogin = {this.onLogin}/>} </Route>   
                <Route       path="/iscrizionesuinvito"                ><GestioneIscrizioneSuinvito onLogout={this.onLogout} onLogin = {this.onLogin}/> </Route>       
                <Route       path="/partite/:partitaID"                ><Partita onLogin = {this.onLogin}/> </Route>
                <Route       path="/passworddimenticata"               >{this.state.loginbymail ? <Home/> :<PasswordDimenticata /> }</Route>
                <Route       path="/resetpassword"                     >{this.state.loginbymail ? <Home/> : <ResetPassword   onLogout={this.onLogout} onLogin = {this.onLogin} /> } </Route>
                {/* Attenzione a questo passaggio: se digito un indirizzo manualmente nell'URL, il sistema ricarica il componente "home", facendo ripartire lo stato di login da false, gestendo lo switch, e quindi mandando al default quando non c'è login
                <Route                                  ><Redirect to="/accessononconsentito" /> </Route>                 */}
              </Switch>
            }  
            {this.state.WIP ? <WorkInProgress login={this.state.login} datiUtente={this.state.datiUtente}/> : ''}
            <Footer login={this.state.login}  menuSelezionato={this.state.menuFooterSelezionato} aggiornaMenuSelezionato={this.aggiornaMenuFooterSelezionato}/>
          </Fragment>
           
    )
  }
}

export default withCookies(Main)
