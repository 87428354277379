// Classe per  nominare correttamente i campi da/verso le chiamate API
class dataMask {
    
    encode = (payload) => {
                let newPayload = payload
                return newPayload;
        }

    decode = (payload) => {
        let nuovaPartita = {}
        let NewGames = [];
        payload.data.games.map((game, index) => {
            if(NewGames.length < 3)  {
                NewGames = [...NewGames, {
                            Squadra1: game[0],
                            Squadra2: game[1]
                            } 
                        ]     
            } // Massimo 3 Games per partita    
            return true
        })
        nuovaPartita = {          
            ID: payload.data.id,
            DataPartita: payload.data.DataPartita,
            Vincitore: payload.data.Vincitori,
            Giocatori:
            {
                ID1: payload.data.S1_1,
                Nome1:payload.data.S1_G1.nome,
                Cognome1:payload.data.S1_G1.cognome,
                Avatar1: payload.data.S1_G1.Immagine,

                ID2: payload.data.S1_2,
                Nome2:payload.data.S1_G2.nome,
                Cognome2:payload.data.S1_G2.cognome,
                Avatar2: payload.data.S1_G2.Immagine,    
                               
                ID3: payload.data.S2_1,
                Nome3:payload.data.S2_G1.nome,
                Cognome3:payload.data.S2_G1.cognome,
                Avatar3: payload.data.S2_G1.Immagine,   

                ID4: payload.data.S2_2,
                Nome4:payload.data.S2_G2.nome,
                Cognome4:payload.data.S2_G2.cognome,
                Avatar4: payload.data.S2_G2.Immagine, 
            },
            Games: NewGames,
        }       
        return nuovaPartita      
    }

}

var partita = new dataMask();
export default partita


