// Componenti React
import React, {Component, Fragment} from 'react';

//import DatiPassword from './DatiPassword';
import DatiAnagrafici from './DatiAnagrafici';
import Avatar from './Avatar';
import IscrizioneAvvenuta from './IscrizioneAvvenuta'


class GestioneIscrizioneByMail extends Component{
 
  constructor(props) {
    super(props);
    this.state = {
      //  0 = Utente inserimento dati anagrafici e tecnici
      //  1 = Inserimento Avatar, 
      // 2 = iscrizione completa
      faseIscrizione : 0,
      datiUtente: {
        token: '',
        avatarUrl:'',
        ricordami: false,
      },
    }
  }

  // Gestione dell'avvenuto inserimento password
  onInserimentoDatiPassword = (datiNuovoUtente) => {
    let datiUtente = this.state.datiUtente;
    datiUtente['token'] = datiNuovoUtente.token;
    this.setState({datiUtente})
    this.setState({faseIscrizione:1})
  }

  // Gestione dell'avvenuto inserimento dati tecnici
  onInserimentoDatiTecnici= (datiNuovoUtente) => {
    let datiUtente = this.state.datiUtente;
    datiUtente['token'] = datiNuovoUtente.token;
    this.setState({datiUtente})
    this.setState({faseIscrizione:1})
  }


  // Gestione dell'avvenuto inserimento dell'avatar
  onInserimentoAvatar = (avatarData) => {
    let datiUtente = this.state.datiUtente;
    datiUtente['avatarUrl'] = avatarData.avatarUrl;
    this.setState({datiUtente})
    this.setState({faseIscrizione:2})
  }


  // Gestione del login dopo l'iscrizione completata
  loginRedirect = (token) => {
    let datiUtente = this.state.datiUtente;
    datiUtente['ricordami'] = true;
    this.props.onLogin(datiUtente)
  }

  // Mostro lo stato avanzamento 
  render(){
    return (
          <Fragment>
            {/*this.state.faseIscrizione === 0 &&  <DatiPassword       onLogout={this.props.onLogout} onInserimentoDatiPassword = {this.onInserimentoDatiPassword} />*/}
            {this.state.faseIscrizione === 0 &&  <DatiAnagrafici     onLogout={this.props.onLogout} onInserimentoDatiTecnici = {this.onInserimentoDatiTecnici}/>}
            {this.state.faseIscrizione === 1 &&  <Avatar             token = {this.state.datiUtente.token} onInserimentoAvatar = {this.onInserimentoAvatar}/>}
            {this.state.faseIscrizione === 2 &&  <IscrizioneAvvenuta token = {this.state.datiUtente.token} onloginRedirect = {this.loginRedirect}/>}
          </Fragment>
    )
  }
}

export default GestioneIscrizioneByMail
