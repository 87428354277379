// Classe per  nominare correttamente i campi da/verso le chiamate API
class dataMask {
    
    encode = (payload) => {
            let newPayload = {
                email: payload.email, 
            }
            return newPayload;
        }
    
    decode = (payload) => {
            let newPayload = {
                token: payload.data.token, 
            }
            return newPayload;
        }    
}

var fastLogin = new dataMask();
export default fastLogin


