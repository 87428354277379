// Restituisce la data espressa come
// Lunedi, 26 ottobre 2020

function DataItaliana(props) {
  var dataDaConvertire=props.data;
  var tipo = props.tipo;

  var mesi = ['Gennaio','Febbraio','Marzo','Aprile','Maggio','Giugno', 'Luglio','Agosto','Settembre','Ottobre','Novembre','Dicembre'];
  var nomigiorni = ['Domenica','Lunedì','Martedì','Mercoledì','Giovedì','Venerdì','Sabato'];
  //Test
  if(dataDaConvertire.length!==10){
        return "";
  }

  var anno = dataDaConvertire.substring(0,4);
  var mese = dataDaConvertire.substring(5,7);
  var giorno = dataDaConvertire.substring(8,10);
  var datafinale = '';
  var giornosettimana = '';

  if(anno>1900 && anno<3000 && mese>0 && mese<13 && giorno>0 && giorno<32){
      if(tipo==='/' || tipo==='-'){
           return giorno+tipo+mese+tipo+anno;
      }
      else {
        if(tipo==='TXT'){
             datafinale = new Date(anno+'-'+mese+'-'+giorno);
             giornosettimana = nomigiorni[parseInt(datafinale.getDay())];
             return giornosettimana + ", " + giorno+" "+mesi[parseInt(mese)-1]+" "+anno;
           }
        return "";
      }
   }
   else {
     return "";
   }

}

export default DataItaliana
