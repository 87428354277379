import React,{Component} from 'react';
//import {Link} from 'react-router-dom'
import queryString from 'query-string'
import { withRouter } from 'react-router-dom';

// Compose per utilizzare multipli Wrapper (withstyle e withcookies)
import { compose } from 'recompose'

//import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';

// import InputAdornment from '@material-ui/core/InputAdornment';
// import IconButton from '@material-ui/core/IconButton';
// import Visibility from '@material-ui/icons/Visibility';
// import VisibilityOff from '@material-ui/icons/VisibilityOff';
import CloseIcon from '@material-ui/icons/Close';

// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
// import LinkUI from '@material-ui/core/Link';
// import Grid from '@material-ui/core/Grid';
// import Box from '@material-ui/core/Box';
//import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Checkbox from '@material-ui/core/Checkbox';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
// Componenti per azioni su REST API
import API from "../../../helpers/API/apiCaller/APIManager"
//import Privacy from "../../common/Privacy"

// Utilizzo tema standard
const theme = createMuiTheme();

// Stili
const styles = {
  paper: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#19d3da',
  },

  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor:'#373a40',
    "&:hover": {
      backgroundColor: "#373a40"
    },
    color: 'white',

  },

  button: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor:'#373a40',
    "&:hover": {
      backgroundColor: "#373a40"
    },
    color: 'white',

  },

  textField: {
    '& label.Mui-focused': {
      color: '#19d3da',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {

      },
      '&.Mui-focused fieldset': {
        borderColor: '#19d3da',
      },
   },
  },

  link: {
    color:'#373a40',
  },

  snackbar: {
    bottom: 30,
  },

  privacyBox: {
    marginTop:10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
  },

};

// // Functional component per visualizzazione Copyright
// function Copyright() {
//   return (
//     <Typography variant="body2" color="textSecondary" align="center">
//       {'Copyright © '}
//       <LinkUI color="inherit" href="https://PadelRank.it">
//         Stealth Srl
//       </LinkUI>{' '}
//     </Typography>
//   );
// }


class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      privacy: false,
      messaggioErrore:'',
      validazione: false,
      emailInviata: false,
    }
   
 }


  // Verifico se presente l'utente da token
  componentDidMount(){
  
    // Leggo i parametri dell'URL 
    //...Login?k=tokenResetPassword
    const parametriURL = queryString.parse(this.props.location.search)
    
    if(parametriURL.k)
      // Ricavo i dati dell'utente
     this.ricavaDatiUtente(parametriURL.k);
  }

  //Funzione di gestione campo di input email
  onCambioEmail = (event) => {
    // Aggiorno il contenuto del campo Input
    this.setState({
        email: event.target.value,
        messaggioErrore:'',
      }, () => this.verificaInput())
  }
  
  //Funzione di gestione modifica valore di campo checkbox
  onModificaCheckBox = (event) => {
    // Aggiorno il contenuto del campo Input
    this.setState({
        privacy: event.target.checked,
        messaggioErrore:'',
      }, () => this.verificaInput())
  }

  // Funzione per verificare la correttezza dei campi inseriti
  verificaInput = () => {
      // Disattivo la privacy
      //if (this.isEmailValida(this.state.email) && this.state.privacy)
      if (this.isEmailValida(this.state.email))
     
        this.setState({validazione: true,})
      else
        this.setState({validazione: false,})  
    }

  
  // Utilità di validazione indirizzo email
  isEmailValida = (val) => {
    let regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(!regEmail.test(val))
      return false
    return true
  }
  


  // Funzione di gestione pulsante "Accedi" (tramite API)
  onPressLogin = (event) => {
  
    // Evito che il form segua il suo comportamento di default, ovvero aprire una pagine di destinazione (<Form action="...">)
    event.preventDefault();
    
    //Effettuo la chiamata al servizio API
    let payload = {
                    email:this.state.email, 
                  }
    
    // Chiamata per invio email 
    API.post('fastLogin', 'fastlogin', payload, this.emailInviata, this.emailErrore) 
  }

  // Funzione di gestione login effettuato
  emailInviata = (datiLogin) => {
    this.setState({emailInviata: true})
  }

  // Funzione di gestione login errato
  emailErrore = (datiErrore) => {
    this.setState({messaggioErrore: datiErrore.error})
  }

  // Funzione per la gestione della chiusura pop-over di credenziali errate
  onChiusuraMessaggioErrore = (event, reason) => {
    this.setState({messaggioErrore: ''})
  };


   
  // Aggiorno i dati utente, in funzione del token 
  ricavaDatiUtente = (token) => {
    let payload = {} 
    let header = {token: token}

    //Effettuo la chiamata al servizio API e aggiorno lo stato con i dati dell'utente
    // Ritorno true se l'utente esiste ed ho aggiornato lo stato con i suoi dati, false se non esiste
    API.get(
      'utente', // dataType (econde/decode API)
      'utente', 
      payload, 
      (datiUtenteAutenticato) => {
        // console.log("Utente esistente")
        this.props.onLogin({token:token})
      }, 
      (error) => {
        // Se l'utente non esiste, non fare nulla
        // Rimani nella schermata di login classica
        return 
      }, 
      header
    )
      
  }

  tornaHome = () => {
    // Torno in homepage
    this.setState({
      email: '',
      privacy: false,
      messaggioErrore:'',
      validazione: false,
      emailInviata: false,
    })
    //this.props.history.push("/")
  }


  render(){
    const {classes} = this.props;
    return (
      this.state.emailInviata ?
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Typography component="h1" variant="h5">
              Link inviato!
            </Typography>
            <Typography>
              Controlla la mail ;)
            </Typography>
            <br/>           
            {/* <div className={classes.root}>
              <div height='50px' width='400px'></div>
              <Button variant="contained" component="span" className={classes.button} onClick={this.tornaHome}>
                Torna in homepage
              </Button>
            </div> */}
          </div>  

        </Container>
    :
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            {/* <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar> */}
            <img src={`images/layout/Logo.png`} width="250px" alt="Padel Rank" className={classes.logo}/>
            {/* <Typography component="h1" variant="h5">
              Login
            </Typography> */}
            <Typography align="justify">
              Questa è un'applicazione riservata ad un <b>circolo di amici</b>. <br/>Inserisci la tua email e, se sei tra questi, riceverai il <b>link diretto</b> per accedervi, senza iscrizione!
            </Typography>
            <form className={classes.form} onSubmit={this.onPressLogin} >
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                value={this.state.email}
                onChange={this.onCambioEmail}
                classes= {{
                  root: classes.textField,
                }}
              />
            {/* <div className={classes.privacyBox}> 
              <FormControlLabel
                control={
                  <Checkbox
                    name="privacy"
                    value={this.state.privacy}
                    color="default"
                    inputProps={{ 'aria-label': 'checkbox with default color' }}
                    onChange={this.onModificaCheckBox}
                  />}
                />
                <Privacy/>
              </div> */}
              <Button
                disabled = {!(this.state.validazione)}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Ricevi il link diretto per entrare
              </Button>

            </form>
          </div>
          <Snackbar  open={this.state.messaggioErrore !== ''? true : false} autoHideDuration={4000} className={classes.snackbar}  onClose={this.onChiusuraMessaggioErrore} >
                  <Alert
                    variant="filled"
                    elevation={6}
                    severity="error"
                    action={
                      <CloseIcon />
                    }
                    onClick={this.onChiusuraMessaggioErrore}
                    >
                    {this.state.messaggioErrore}
                  </Alert>
          </Snackbar>
          {/* <Box mt={8} style={{position: 'fixed', bottom: 10,  width:'100%', }}>
            <Copyright />
          </Box> */}
        </Container>
    );
  }
}

// Esporto anche gli stili presenti in questo file, con la classe Header
export default compose(
  withStyles(styles),
  withRouter, 
) (Login)