// Componenti React
import React,{Component} from 'react';
import sortBy from 'sort-by';
import escapeRegExp from 'escape-string-regexp'

// Componenti per azioni su REST API
import API from "../../helpers/API/apiCaller/APIManager"

// Componenti Padel Rank
import ClassificaCard from './ClassificaCard';

// Componenti Material UI
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Container from '@material-ui/core/Container';

// Stile Material UI
import { withStyles } from '@material-ui/core/styles';
const styles = {

  ricerca: {
      display: 'flex',

      '& label.Mui-focused': {
         color: '#19d3da',
       },

       '& .MuiInput-underline:after': {
         borderBottomColor: '#19d3da',
       },

    },
  
  snackbar: {
    bottom: 90,
  }

};


class Classifica extends Component {

  constructor(props) {
    super(props);
    this.state = {
    stringaDiRicerca: '',
    dbClassifica: [],
    messaggioErrore:'',
    }

    // Istanzio una nuova occorrenza del servizio API
    // this.API = new API();
 
  }

  componentDidMount() {
    this.ricavaDatiGiocatoriClassifica(this.props.token);
  }


  // Ricavo tutti i giocatori preferiti dell'utente, + l'utente stesso
  ricavaDatiGiocatoriClassifica = (token) => {

    let payload = ''
    let header = {token: token}    

    //Effettuo la chiamata al servizio API e aggiorno lo stato in primis con i giocatori preferiti
    API.get(
      'preferiti',
      'preferiti', 
      payload, 
      (datiGiocatori) => {
        // Subito dopo, aggiungo in classifica anche l'utente stesso
        // Sarebbe molto meglio aggiungere l'utente stesso tra i preferiti di default, al momento dell'iscrizione
        this.setState({dbClassifica: datiGiocatori}, () => this.aggiungiUtenteInClassifica(token) )  
      }, 
      (errorData) => {this.setState({messaggioErrore: errorData.error})},
      header
    )
    
  }

  // Funzione che aggiunge i miei giocatori preferiti in classifica
  aggiungiUtenteInClassifica = (token) => {

    let payload = ''
    let header = {token: token}    

    // In primis, aggiungo me stesso alla classifica
    API.get(
      'utente', // dataType (econde/decode API)
      'utente', 
      payload, 
      (datiUtenteAutenticato) => {
        let utenteInClassifica = {
          ID: datiUtenteAutenticato.id,
          Nome: datiUtenteAutenticato.nome,
          Cognome: datiUtenteAutenticato.cognome,
          Email: datiUtenteAutenticato.email,
          Rank: datiUtenteAutenticato.rank,
          Genere: datiUtenteAutenticato.genere,
          Avatar: datiUtenteAutenticato.avatarUrl,
        }
        
        this.setState((prevState) => ({
          dbClassifica: [...prevState.dbClassifica, utenteInClassifica]
          })
        )  
      }, 
      (errorData) => {this.setState({messaggioErrore: errorData.error})},
      header
    )

  }
  // Funzione per la gestione della ricerca all'interno della lista Giocatori (aggiorno lo stato con la nuova stringa di ricerca)
  gestioneRicerca = (newStringaDiRicerca) => {
    this.setState({
      stringaDiRicerca:newStringaDiRicerca
    })
  }

  // Funzione per la gestione della chiusura pop-over di credenziali errate
  onChiusuraMessaggioErrore = (event, reason) => {
    this.setState({messaggioErrore: ''})
  };

  render(){

    // Variabili di comodo
    const {classes} = this.props;
    const {dbClassifica} = this.state;

   
    // Ordino i giocatori preferiti per Ranking
    dbClassifica.sort(sortBy('-Rank','Nome'))

    // Filtro solo i giocatori preferiti che corrispondono all'eventuale ricerca
    const espressioneDiRicerca = new RegExp(escapeRegExp(this.state.stringaDiRicerca),'i')
    let dbClassificaFiltrato=''
    dbClassificaFiltrato=dbClassifica.filter((giocatore)=>espressioneDiRicerca.test(giocatore.Nome+' '+ giocatore.Cognome+' '+ giocatore.Email))

    return (
      <Container component="main" maxWidth="sm">
          <TextField
            id="standard-search"
            label="Ricerca.."
            type="search"
            className={classes.ricerca}
            onChange={(event) => this.gestioneRicerca(event.target.value)}
          />

          <br/>
          {
            // Mappo il singolo giocatore
            dbClassificaFiltrato.map((giocatore, index) => (
                <ClassificaCard key={index} rank={index} giocatore={giocatore} />
              )
            )
          }
          
          <Snackbar  open={this.state.messaggioErrore !== ''? true : false} autoHideDuration={4000} className={classes.snackbar}  onClose={this.onChiusuraMessaggioErrore} >
                <Alert
                  variant="filled"
                  elevation={6}
                  severity="error"
                  action={
                    <CloseIcon />
                  }
                  onClick={this.onChiusuraMessaggioErrore}
                  >
                  {this.state.messaggioErrore}
                </Alert>
            </Snackbar>
      </Container>
    )
  }
}

export default withStyles(styles)(Classifica)
