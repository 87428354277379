import React,{Component} from 'react';
import {Link} from 'react-router-dom'
import queryString from 'query-string'
import { withRouter } from 'react-router-dom';

// Compose per utilizzare multipli Wrapper (withstyle e withcookies)
import { compose } from 'recompose'

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';

import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import CloseIcon from '@material-ui/icons/Close';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import LinkUI from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';

// Componenti per azioni su REST API
import API from "../../../helpers/API/apiCaller/APIManager"

// Utilizzo tema standard
const theme = createMuiTheme();

// Stili
const styles = {
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#19d3da',
  },

  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor:'#373a40',
    "&:hover": {
      backgroundColor: "#373a40"
    },
    color: 'white',

  },

  textField: {
    '& label.Mui-focused': {
      color: '#19d3da',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {

      },
      '&.Mui-focused fieldset': {
        borderColor: '#19d3da',
      },
   },
  },

  link: {
    color:'#373a40',
  },

  snackbar: {
    bottom: 30,
  }
};

// Functional component per visualizzazione Copyright
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <LinkUI color="inherit" href="https://PadelRank.it">
        Stealth Srl
      </LinkUI>{' '}
    </Typography>
  );
}


class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      mostraPassword: false,
      ricordami: false,
      messaggioErrore:'',
    }
   
 }


  // Verifico se presente l'utente da token
  componentDidMount(){
  
    // Leggo i parametri dell'URL 
    //...ResetPassword?k=tokenResetPassword
    const parametriURL = queryString.parse(this.props.location.search)
    
    if(parametriURL.k)
      // Ricavo i dati dell'utente
     this.ricavaDatiUtente(parametriURL.k);
  }

  //Funzione di gestione campo di input email
  onCambioEmail = (event) => {
    this.setState(
      {
        email: event.target.value,
        messaggioErrore:'',
      })
  }
  
  //Funzione di gestione campo di input password
  onCambioPassword = (event) => {
    this.setState(
      {
        password: event.target.value,
        messaggioErrore:'',
      })
  }

  // Funzione di gestione cambio visibilità password
  onCambioVisibilitaPsw = () => {
    this.setState( (prevState) => (
      {
        mostraPassword: !prevState.mostraPassword,
      }
    ))
  };

  // Funzione per evitare l'utente clicci sul campo password.. forse per motivi di sicurezza?!
  gestioneMouseDownPassword = (event) => {
    event.preventDefault();
  };

  //Funzione di gestione campo Ricordami
  onCambioRicordami = (event) => {
    this.setState(
      {
        ricordami: event.target.checked,
      })
  }

  // Funzione di gestione pulsante "Accedi" (tramite API)
  onPressLogin = (event) => {
  
    // Evito che il form segua il suo comportamento di default, ovvero aprire una pagine di destinazione (<Form action="...">)
    event.preventDefault();
    
    //Effettuo la chiamata al servizio API
    let payload = {
                    email:this.state.email, 
                    password: this.state.password
                  }

    API.post('login', 'login', payload, this.loginAvvenuto, this.loginErrore) 
  }

  // Funzione di gestione login effettuato
  loginAvvenuto = (datiLogin) => {
    // Passo anche l'informazione di ricordare l'utente al login successivo
    datiLogin.ricordami = this.state.ricordami  
    this.props.onLogin(datiLogin)
  }

  // Funzione di gestione login errato
  loginErrore = (datiErrore) => {
    this.setState({messaggioErrore: datiErrore.error})
  }

  // Funzione per la gestione della chiusura pop-over di credenziali errate
  onChiusuraMessaggioErrore = (event, reason) => {
    this.setState({messaggioErrore: ''})
  };


   
  // Aggiorno i dati utente, in funzione del token 
  ricavaDatiUtente = (token) => {
    let payload = {} 
    let header = {token: token}

    //Effettuo la chiamata al servizio API e aggiorno lo stato con i dati dell'utente
    // Ritorno true se l'utente esiste ed ho aggiornato lo stato con i suoi dati, false se non esiste
    API.get(
      'utente', // dataType (econde/decode API)
      'utente', 
      payload, 
      (datiUtenteAutenticato) => {
        // console.log("Utente esistente")
        this.props.onLogin({token:token})
      }, 
      (error) => {
        // Se l'utente non esiste, non fare nulla
        // Rimani nella schermata di login classica
        return 
      }, 
      header
    )
      
  }

  render(){
    const {classes} = this.props;
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          
          <Typography component="h1" variant="h5">
            Login
          </Typography>
          <form className={classes.form} onSubmit={this.onPressLogin} >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              value={this.state.email}
              onChange={this.onCambioEmail}
              classes= {{
                root: classes.textField,
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="password"
              label="Password"
              type={this.state.mostraPassword ? 'text' : 'password'}
              id="password"
              value={this.state.password}
              onChange={this.onCambioPassword}
              autoComplete="current-password"
              classes= {{root: classes.textField,}}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                        aria-label="Vedi o nascondi password"
                        onClick={this.onCambioVisibilitaPsw}
                        onMouseDown={this.gestioneMouseDownPassword}
                        edge="end"
                    ></IconButton>
                    {this.state.mostraPassword ? <VisibilityOff/> : <Visibility />}
                  </InputAdornment>
                ),
              }}
            />

            <FormControlLabel
              control={<Checkbox color="default" onChange={this.onCambioRicordami}/>}
              label="Ricordami"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Accedi
            </Button>

            <Grid container>
              <Grid item xs >
                <LinkUI href="#" variant="body2" classes={{ root: classes.link}} component={Link} to="/passworddimenticata">
                  Password dimenticata?
                </LinkUI>
              </Grid>
              <Grid item>
                <LinkUI href="#" variant="body2" classes={{ root: classes.link}} component={Link} to="/iscrizione">
                  Nuova iscrizione?
                </LinkUI>
              </Grid>
            </Grid>
          </form>
        </div>
        <Snackbar  open={this.state.messaggioErrore !== ''? true : false} autoHideDuration={4000} className={classes.snackbar}  onClose={this.onChiusuraMessaggioErrore} >
                <Alert
                  variant="filled"
                  elevation={6}
                  severity="error"
                  action={
                    <CloseIcon />
                  }
                  onClick={this.onChiusuraMessaggioErrore}
                  >
                  {this.state.messaggioErrore}
                </Alert>
        </Snackbar>
        <Box mt={8} style={{position: 'fixed', bottom: 10,  width:'100%', }}>
          <Copyright />
        </Box>
      </Container>
    );
  }
}

// Esporto anche gli stili presenti in questo file, con la classe Header
export default compose(
  withStyles(styles),
  withRouter, 
) (Login)