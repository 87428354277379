// Componenti React
import React, {Component} from 'react';


import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

// Utilizzo tema standard
const theme = createMuiTheme();

const styles = {
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },

  
  button: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor:'#373a40',
    "&:hover": {
      backgroundColor: "#373a40"
    },
    color: 'white',
  },

  link: {
    color:'#373a40',
  },


};


class IscrizioneAvvenuta extends Component{
 

  render(){

    const {classes} = this.props;

    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            Ottimo!
          </Typography>
          <Typography>
            Grazie per esserti iscritto.
          </Typography>
                    
          <div className={classes.root}>
            <div height='50px' width='400px'></div>
            <Button variant="contained" component="span" className={classes.button} onClick={this.props.onloginRedirect(this.props.token)}>
              Clicca qui per entrare!
            </Button>
          </div>
        </div>  

      </Container>  
    )
  }
}

export default withStyles(styles) (IscrizioneAvvenuta)
