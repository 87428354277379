// Classe per  nominare correttamente i campi da/verso le chiamate API
class dataMask {

    encode = (payload) => {
        return {};
    }

    decode = (payload) => {
        let nuoviGiocatori = [];
        let nuovoGiocatore = {}
        payload.data.Preferiti.map((giocatore, index) => {
                nuovoGiocatore = {          
                    ID: giocatore.id,
                    Nome: giocatore.nome,
                    Cognome: giocatore.cognome,
                    Email: giocatore.email,
                    Cel: giocatore.cellulare,
                    Rank: giocatore.Rank_Attuale > 0 ? giocatore.Rank_Attuale : "-",
                    Genere: giocatore.genere,
                    Avatar: giocatore.Immagine,
                }
                // Ricreo l'array delle partite, secondo la struttura prevista nel frontend
                nuoviGiocatori = [...nuoviGiocatori, nuovoGiocatore]    
                return true               
        })           
        return nuoviGiocatori
        }
    
       
}

var giocatori = new dataMask();
export default giocatori


