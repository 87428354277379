import React,{Component} from 'react';

// Chiamate API Rest
import API from "../../../helpers/API/apiCaller/APIManager"

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import LinkUI from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import SportsTennisIcon from '@material-ui/icons/SportsTennis';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Slider from '@material-ui/core/Slider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

// import { useEventCallback } from '@material-ui/core';

// Functional component per visualizzazione Copyright
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <LinkUI color="inherit" href="https://PadelRank.it">
        PadelRank.it
      </LinkUI>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// Utilizzo tema standard
const theme = createMuiTheme();

const styles = {
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },


  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor:'#373a40',
    "&:hover": {
      backgroundColor: "#373a40"
    },
    color: 'white',

  },

  textField: {
    '& label.Mui-focused': {
      color: '#19d3da',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {

      },
      '&.Mui-focused fieldset': {
        borderColor: '#19d3da',
      },
      
   },
  },

  textFieldSuccess: {
    // Label on focus on
    '& label.Mui-focused': {
      color: '#95d5b2',
    },
    // Campo input 
    '& .MuiOutlinedInput-root': {

      // Default
      '& fieldset': {
        borderColor: '#95d5b2',
        borderWidth: 2,
      },

      // Default mouse over
      '&:hover fieldset': {
        borderColor: '#95d5b2',
        borderWidth: 2,
      },
      // Quando sono dentro al campo
      '&.Mui-focused fieldset': {
        borderColor: '#95d5b2',
        borderWidth: 2,
      },

   },
  },

  titolo: {
    margin: theme.spacing(2, 0, 1),
  },

  link: {
    color:'#373a40',
  },

  snackbar: {
    bottom: 30,
  }

};


const RadioMaschio = withStyles({
  root: {
    color:  '#19d3da',
    '&$checked': {
      color:  '#19d3da',
    },
  },
})(Radio);

const RadioFemmina = withStyles({
  root: {
    color: '#19d3da',
    '&$checked': {
      color: '#19d3da',
    },
  },
})(Radio);

const LevelSlider =  withStyles({
  root: {
    color: '#19d3da',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 0,
  },
  rail: {
    height: 8,
    borderRadius: 0,
  },
})(Slider);


class DatiTecnici extends Component {

  constructor(props) {
    super(props);
    this.state = {
      input: {
        genere: 'M',
        livello: 3,
      }, 
      errori: {
      },
      validazione: {
        genere: true,
        livello: true,
      },
      utility: {
        mostraHelper: false,
      },
      messaggioErrore: '',
    }

    // Istanzio una nuova occorrenza del servizio API
    // this.API = new API();
    
 }

   //Funzione di gestione modifica valore di campo di input 
  onModificaInput = (event) => {
    let {input} = this.state
    input[event.target.name] = event.target.value
    this.setState({input})   
  }

  //Funzione di gestione modifica slider
  onModificaSlider = nomeSlider => (event, valoreSlide) => {
    let {input} = this.state
    input[nomeSlider] = valoreSlide
    this.setState({input})   
  }



  // Funzione di gestione pulsante "Conferma Iscrizione" (tramite API)
  onPressProsegui = (event) => {

     // Evito che il form segua il suo comportamento di default, ovvero aprire una pagine di destinazione (<Form action="...">)
    event.preventDefault();
    
    // Verifico che tutti i campi siano correttamente compilati
    // Converto l'oggetto "validazione" in un array
    const validazioneArray = Object.values(this.state.validazione);
    // Riduco l'array ad un unico valore booleano (combinazione &&)
    const reducer = (accumulatore, valore) => accumulatore && valore
  
    // Se tutti i campi sono validati
    if(validazioneArray.reduce(reducer,true)) {
      let {input} = this.state
      //Effettuo la chiamata al servizio API
      let payload = {
        genere: input.genere,
        livello: input.livello,
      }

      let header = {token: this.props.token}
      API.post('utente', 'utenteModifica', payload, this.modificaAvvenuta, this.modificaErrore, header) 
    } else
    {
      // Non tutti i campi sono validati, quindo mostro tutti gli Helper e messaggio generale
      let {utility} = this.state
      utility['mostraHelper'] = true;
      this.setState({utility})
      this.setState({messaggioErrore: "Compilare correttamente tutti i campi"})
    }

  }

  // Funzione per la gestione della chiusura pop-over di credenziali errate
  onChiusuraMessaggioErrore = (event, reason) => {
    this.setState({messaggioErrore: ''})
  };

  // Funzione di gestione iscrizione effettuata
  modificaAvvenuta = (datiUtente) => {
    this.props.onInserimentoDatiTecnici(datiUtente)
  }

  // Funzione di gestione iscrizione errata
  modificaErrore = (datiErrore) => {
    this.setState({messaggioErrore: datiErrore.error})
  }


  render(){

    const {classes} = this.props;
    const {input}   = this.state;

    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <SportsTennisIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Dati giocatore
          </Typography>
          <form className={classes.form} onSubmit={this.onPressProsegui} >

            {/* Genere */}            
            <Typography className={classes.titolo}>
              Genere
            </Typography>
            <RadioGroup row aria-label="genere" name="genere" defaultValue="M" onChange={this.onModificaInput}>
              <FormControlLabel value="M" control={<RadioMaschio  color="default" />} label="Maschietto" />
              <FormControlLabel value="F" control={<RadioFemmina  color="default" />} label="Femminuccia" />
            </RadioGroup>

            {/* Livello */}
            <Typography className={classes.titolo}>
              Livello di partenza ({input.livello})
            </Typography>            
            <LevelSlider
              name="livello"
              defaultValue={3}
              aria-labelledby="discrete-slider"
              valueLabelDisplay="auto"
              step={0.5}
              min={1}
              max={7}
              onChangeCommitted={this.onModificaSlider("livello")}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Prosegui
            </Button>
          </form>
        </div>
        <Snackbar  open={this.state.messaggioErrore !== ''? true : false} autoHideDuration={4000} className={classes.snackbar}  onClose={this.onChiusuraMessaggioErrore} >
                <Alert
                  variant="filled"
                  elevation={6}
                  severity="error"
                  action={
                    <CloseIcon />
                  }
                  onClick={this.onChiusuraMessaggioErrore}
                  >
                  {this.state.messaggioErrore}
                </Alert>
        </Snackbar>
        <Box mt={8} style={{position: 'fixed', bottom: 10,  width:'100%', }}>
          <Copyright />
        </Box>
      </Container>
    );
  }
}

export default withStyles(styles) (DatiTecnici)