// Classe per  nominare correttamente i campi da/verso le chiamate API
class dataMask {
    
    encode = (payload) => {
            let newPayload = {
                username: payload.email, 
                password: payload.password,
            }
            return newPayload;
        }
    
    decode = (payload) => {
            let newPayload = {
                token: payload.data.token, 
            }
            return newPayload;
        }    
}

var login = new dataMask();
export default login


