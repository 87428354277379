import React, {Component} from 'react';

// Compose per utilizzare multipli Wrapper (withstyle e withcookies)
import { compose } from 'recompose'

// Componenti di Material UI
import { createMuiTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
//import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import Button from '@material-ui/core/Button';


// Drawer
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';

import FaceIcon from '@material-ui/icons/Face';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import CloseIcon from '@material-ui/icons/Close';
import LockIcon from '@material-ui/icons/Lock';

import Help from "../common/Help"

// Componenti Router Dom
//import {Link} from 'react-router-dom'

const TemaPersonalizzato = createMuiTheme({
  palette: {
    //type: 'dark',

    primary: {
      main: '#373a40',
      // light: will be calculated from palette.primary.main,
      // dark: will be calculated from palette.primary.main,
      contrastText: '#FFF',
    },
    secondary: {
      //main: '#ef767a',
      main: '#19d3da', // Azzurro
    },

    background: {
      //default: "#303030", 
    },
  },
});

const styles = {
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: 20,
  },
  title: {
    flexGrow: 1,
  },

  avatar:{
    borderColor: '#19d3da',
    borderStyle: 'solid',
    },
  
  button: {
    color: '#19d3da',
    borderColor: '#19d3da',
  },

}

//Header
class Header extends Component {
  state = {
    menuAperto: false,
    helpAperto: false,
  }


  gestioneMenu = (apertura) => (event) => {
    this.setState({menuAperto: apertura});
  };

  gestioneHelp = (apertura) => (event) => {
    this.setState({helpAperto: apertura});
  };

  gestioneLogout = (event) => {
     this.setState({menuAperto: false});
     this.props.history.push("/");
     this.props.onLogout();
    }
  
  profiloUtente = (event) => {
    this.setState({menuAperto: false});
    this.props.aggiornaMenuSelezionato(0);
    this.props.history.push("/profiloutente");
  }

  cambioPassword = (event) => {
    const {datiUtente} = this.props;
    this.setState({menuAperto: false});
    this.props.history.push("/cambiopassword?k=" + datiUtente.token);
  }

  render(){

        const {classes} = this.props;
        const {login} = this.props;
        const {datiUtente} = this.props;
        const {loginbymail} = this.props;
        const helper = login ? this.props.helper : -1

        // Drawer / Menu laterale
        const menuLaterale =
              <List>
                  {/* <ListItem button key="1">
                    <img src={`images/layout/Logo.png`} width="150px" alt="Padel Rank" className={classes.logo}/>
                  </ListItem>
                  <Divider />             */}
                  <ListItem button key="2">
                    <ListItemAvatar>
                       <Avatar className={classes.avatar} alt="" src={datiUtente.avatarUrl} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={`${datiUtente.nome} ${datiUtente.cognome[0]}.`}
                      secondary={'Rank ' + datiUtente.rank}
                    />
                  </ListItem>    
                  <Divider />            
                  <ListItem button key="3" onClick={this.profiloUtente}>
                    <ListItemIcon><FaceIcon /></ListItemIcon>
                    <ListItemText primary="I miei dati" />
                  </ListItem>

                  {
                  !loginbymail ? 
                    <ListItem button key="4" onClick={this.cambioPassword}>
                      <ListItemIcon><LockIcon /></ListItemIcon>
                      <ListItemText primary="Cambio password" />
                    </ListItem>   
                  :
                    ''
                  }            
                  
                  <ListItem button key="4" onClick={this.gestioneLogout}>
                    <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                    <ListItemText primary="Logout" />
                  </ListItem>

                  <ListItem button key="5" onClick={this.gestioneMenu(false)}>
                    <ListItemIcon><CloseIcon /></ListItemIcon>
                    <ListItemText primary="Chiudi menu" />
                  </ListItem>
              </List>
                 

        return(
          <ThemeProvider theme={TemaPersonalizzato}>
            <CssBaseline />

            <div className={classes.root}>
            <AppBar position="static" elevation={0}>
              <Toolbar>
                {
                login ?  
                  <IconButton edge="start" color="inherit" size="medium" onClick={this.gestioneMenu(true)}>
                    <MenuIcon />
                  </IconButton>
                : 
                  ''
                }
                <Typography variant="h6" style={{flexGrow: 1,}}>
                  PadelRank
                </Typography>

                <Button variant="outlined" edge="end" size="small" className={classes.button} onClick={this.gestioneHelp(true)}>Aiuto?</Button>

              </Toolbar>            

              <Drawer anchor={'left'} open={this.state.menuAperto}  onClose={this.gestioneMenu(false)}>
                {menuLaterale}
              </Drawer>

              <Help  contenuto={helper} helpAperto={this.state.helpAperto} gestioneHelp={this.gestioneHelp} buttonClass={classes.button} />  

            </AppBar>
            </div>
          </ThemeProvider>
         )

  }
}

// Esporto anche gli stili presenti in questo file, con la classe Header
export default compose(
  withStyles(styles),
  withRouter, 
) (Header)