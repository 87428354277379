// Classe per  nominare correttamente i campi da/verso le chiamate API
class dataMask {
    
    encode = (payload) => {
            let newPayload = {
                email: payload.email                    
            }
            return newPayload;
        }

    decode = (payload) => {

            let newPayload = {
                richiestaInviata: payload.data.message,
            }
            return newPayload;
        }       

}

var recuperoPassword = new dataMask();
export default recuperoPassword


