// Componenti React
import React,{Component} from 'react';
import sortBy from 'sort-by';
import escapeRegExp from 'escape-string-regexp'

// Componenti Padel Rank
import GiocatoreCard from './GiocatoreCard';

// Componenti Material UI
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

// Componenti per azioni su REST API
import API from "../../helpers/API/apiCaller/APIManager"


const styles = {

  ricerca: {
      display: 'flex',

      '& label.Mui-focused': {
         color: '#19d3da',
       },

       '& .MuiInput-underline:after': {
         borderBottomColor: '#19d3da',
       },

    },
    
  snackbar: {
    bottom: 90,
  }

};


class Giocatori extends Component {

  constructor(props) {
    super(props);
    // Definizione dello stato
    this.state = {
      userID: '',
      token: '',
      dbGiocatori: [],
      //dbGiocatoriPreferiti: [],
      stringaDiRicerca: '',
      messaggio: '',
      messaggioErrore:'',

    }
    
 }

   componentDidMount(){

    this.setState({
      userID: this.props.userID,
      token: this.props.token,
      })     

    // Precarico il DB giocatori
    this.ricavaDatiGiocatori(this.props.token);

    // In precedenza, l'API non tornava l'informazione se un giocatore era o meno tra i preferiti, bisognava
    // effettuare una seconda chiamata, per ricavare solo l'elenco dei preferiti, e poi incrociare i due dati
    // Lascio il codice per "formazione"
    //this.ricavaDatiGiocatoriPreferiti(this.props.token);
    
  }


  // Ricavo tutti i giocatori presenti nel DB
  ricavaDatiGiocatori = (token) => {
 
    let payload = ''
    let header = {token: token}    

    //Effettuo la chiamata al servizio API e aggiorno lo stato con i dati giocatori preferiti
    API.get(
      'giocatori', // dataType (econde/decode API)
      'giocatori', 
      payload, 
      (datiGiocatori) => {
        this.setState({
          dbGiocatori: datiGiocatori,
          })      
        return true
      }, 
    (errorData) => {this.setState({messaggioErrore: errorData.error})},
    header
    )      
  }

  // // Ricavo tutti i giocatori preferiti dell'utente
  // ricavaDatiGiocatoriPreferiti = (token) => {

  //   let payload = ''
  //   let header = {token: token}    

  //   //Effettuo la chiamata al servizio API e aggiorno lo stato con i dati giocatori preferiti
  //  API.get(
  //     'preferiti', 
  //     payload, 
  //     (datiGiocatori) => {
  //       this.setState({
  //         dbGiocatoriPreferiti: datiGiocatori,
  //         })      
  //       return true
  //     }, 
  //   (errorData) => {this.setState({messaggioErrore: errorData.error})},
  //   header
  //   )
      
  // }

  // // Questa funzione mi restituisce true se il giocatore passato come parametro è uno dei preferiti dell'utente
  // isPreferito = (idGiocatore) => {
  //   let isPreferito = false;
  //   this.state.dbGiocatoriPreferiti.map(
  //     (giocatorePreferito) => {
  //             if(giocatorePreferito.ID === idGiocatore) 
  //               isPreferito = true
  //             }
  //   )
  //   return isPreferito
  // }
 
    
  // Funzione per la gestione della ricerca all'interno della lista Giocatori (aggiorno lo stato con la nuova stringa di ricerca)
  gestioneRicerca = (newStringaDiRicerca) => {
    this.setState({
      stringaDiRicerca:newStringaDiRicerca
    })
  }

  // Funzione per la gestione del flag "Preferito" attivo 
  onAggiungiPreferito = (giocatorePreferito) => {
    let payload = {
      IDUtente: this.state.userID,
      IDPreferito: giocatorePreferito.ID,
    }

    let header = {token: this.state.token}    

    // Effettua la chiamata API per aggiungere il preferito
    API.post(
      'modificaPreferito',
      'aggiungiPreferito', 
      payload, 
      () => {        
        // Anzichè rifare la query, aggiorno il database Giocatori presente nello stato, aggiornato il campo "IS_Preferito" del giocatore selezionato
        // Le due cose dovrebbero coincidere

        let dbGiocatoriAggiornati = []
        let giocatoreTemp

        this.state.dbGiocatori.map((giocatore) => {
          giocatoreTemp = giocatore
          if(giocatoreTemp.ID === giocatorePreferito.ID ){
            giocatoreTemp["Preferito"] = 1
          }
          dbGiocatoriAggiornati.push(giocatoreTemp)
          return true;
        })
        this.setState({
          dbGiocatori: dbGiocatoriAggiornati,
          messaggio: giocatorePreferito.Nome + " " + giocatorePreferito.Cognome[0]+ ". aggiunto ai preferiti",
        })
      }, 
      (errorData) => {
        this.setState({messaggioErrore: errorData.error})
      },
      header
    )

    // Azzero i criteri di ricerca
    this.setState({
      stringaDiRicerca:''
    })
  }

  // Funzione per la gestione del flag "Preferito" disattivato
  onRimuoviPreferito = (giocatorePreferito) => {
    let payload = {
      IDUtente: this.state.userID,
      IDPreferito: giocatorePreferito.ID,
    }

    let header = {token: this.state.token}    

    // Effettua la chiamata API per cancelare il preferito
    API.delete(
      'modificaPreferito',
      'preferiti', 
      payload, 
      () => {
        // Aggiorno lo Stato relativo ai giocatori (cosi si aggiorna anche l'interfaccia)
        var dbGiocatoriAggiornati = []
        var giocatoreTemp
        this.state.dbGiocatori.map((giocatore) => {
          giocatoreTemp = giocatore
          if(giocatoreTemp.ID === giocatorePreferito.ID ){
            giocatoreTemp["Preferito"] = 0
          }
          dbGiocatoriAggiornati.push(giocatoreTemp)
          return true;
        })
        this.setState({
          dbGiocatori: dbGiocatoriAggiornati,
          messaggio: giocatorePreferito.Nome + " " + giocatorePreferito.Cognome[0]+ ". tolto dai preferiti",
        })
      }, 
      (errorData) => {this.setState({messaggioErrore: errorData.error})},
      header
    )
    // Azzero i criteri di ricerca
    this.setState({
      stringaDiRicerca:''
    })
  }

  // Funzione per la gestione della chiusura pop-over di credenziali errate
  onChiusuraMessaggio = (event, reason) => {
    this.setState({messaggio: '', messaggioErrore:'',})
  };
  
  
  render(){

    const {classes} = this.props;
    const {dbGiocatori} = this.state;

    // Ordino i giocatori per Cognome, Nome
    dbGiocatori.sort(sortBy('Cognome', 'Nome'))

    // Filtro i giocatori che corrispondono all'eventuale ricerca (nome, cognome, email o cel).
    let dbGiocatoriFiltrato = [];

    if(this.state.stringaDiRicerca.trim() !== '' & this.state.stringaDiRicerca.trim().length > 2) {
      // Perfettamente uguale
      // const espressioneDiRicerca = new RegExp('^'+escapeRegExp(this.state.stringaDiRicerca)+'$','i')
      const espressioneDiRicerca = new RegExp(escapeRegExp(this.state.stringaDiRicerca),'i')
      dbGiocatoriFiltrato=dbGiocatori.filter(((giocatore)=>espressioneDiRicerca.test(giocatore.Nome+' '+ giocatore.Cognome+' '+ giocatore.Email + ' ' + giocatore.Cel)))
    }
    else
      dbGiocatoriFiltrato = dbGiocatori.filter(giocatore => giocatore.Preferito)
    

    return (
      <Container component="main" maxWidth="sm">
        <TextField
          id="standard-search"
          label="Cerca qui per nome, cognome o email.."
          type="search"
          value={this.state.stringaDiRicerca}
          className={classes.ricerca}
          onChange={(event) => this.gestioneRicerca(event.target.value)}
        />

        <br/>
        {
          // Mappo il singolo giocatore sulla card
          dbGiocatoriFiltrato.map((giocatore, index) => (
              <GiocatoreCard key={index} giocatore={giocatore} onAggiungiPreferito={this.onAggiungiPreferito} onRimuoviPreferito={this.onRimuoviPreferito}/>
            )
          )
        }
        <Snackbar  open={this.state.messaggio !== ''? true : false} autoHideDuration={4000} className={classes.snackbar}  onClose={this.onChiusuraMessaggio} >
          <Alert
            variant="filled"
            elevation={6}
            sseverity="success"
            action={
              <CloseIcon />
            }
            onClick={this.onChiusuraMessaggio}
            >
            {this.state.messaggio}
          </Alert>
        </Snackbar>

        <Snackbar  open={this.state.messaggioErrore !== ''? true : false} autoHideDuration={4000} className={classes.snackbar}  onClose={this.onChiusuraMessaggio} >
          <Alert
            variant="filled"
            elevation={6}
            severity="error"
            action={
              <CloseIcon />
            }
            onClick={this.onChiusuraMessaggio}
            >
            {this.state.messaggioErrore}
          </Alert>
        </Snackbar>
    </Container>
    )
  }
}

export default withStyles(styles)(Giocatori)
