// Componenti React
import React,{Component} from 'react';

// Componenti Material-UI
import Card from '@material-ui/core/Card';
//import CardActionArea from '@material-ui/core/CardActionArea';
//import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import StarIcon from '@material-ui/icons/Star';

import { withStyles } from '@material-ui/core/styles';

const styles = {

  card: {
    height: 80,
    marginTop: 10,
    marginBottom: 10,
    display:'flex',
    flexGrow: 0,

  },

  card_image: {
    width: 80,
    flexShrink: 0,
  },

  card_datigiocatore:{
    flexGrow: 1,
  },

  card_datigiocatore_titolo:{
    overflow:'hidden',
  },

  card_datigiocatore_sottotitolo: {
    fontSize: 12,
  },

  card_rank_M: {
    width: 70,
    backgroundColor: '#eeeeee',
  },

  card_rank_F: {
    width: 70,
    backgroundColor: '#fff0f0'
  },

};

class GiocatoreCard extends Component {

  render(){

    // Variabili props
    const {giocatore} = this.props;
    const {classes} = this.props;
    const {onAggiungiPreferito} = this.props;
    const {onRimuoviPreferito} = this.props;

    return (
              <Card className={classes.card} variant="outlined">
                <CardMedia
                  className={classes.card_image}
                  image={`${giocatore.Avatar}`}
                  title={`${giocatore.Nome} ${giocatore.Cognome}`}
                />
                <CardContent className={classes.card_datigiocatore}>
                  <Typography className={classes.card_datigiocatore_titolo}>{`${giocatore.Nome} ${giocatore.Cognome[0]}.`}</Typography>
                  <Typography className={classes.card_datigiocatore_sottotitolo} color="textSecondary">Livello attuale: <strong>{giocatore.Rank}</strong></Typography>
                </CardContent>
                <CardContent className={giocatore.Genere === 'F' ? classes.card_rank_F : classes.card_rank_M}>
                  {
                    giocatore.Preferito ?
                    <StarIcon fontSize="large" style={{ color: '#373a40'}} onClick={()=>onRimuoviPreferito(giocatore)}/>
                    :
                    <StarIcon fontSize="large" style={{ color: '#fff' }} onClick={()=>onAggiungiPreferito(giocatore)}/>
                  }
                </CardContent>
              </Card>
    )
  }
}


export default withStyles(styles)(GiocatoreCard)
