import React, {Fragment, Component} from 'react';

// Componenti Router DOM
import {Link} from 'react-router-dom'

// Componenti di Material UI
import { withStyles } from "@material-ui/core/styles";
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Home from '@material-ui/icons/Home';
//import Stars from '@material-ui/icons/Stars';
import SportsTennis from '@material-ui/icons/SportsTennis';
//import Group from '@material-ui/icons/Group';
//import StarIcon from '@material-ui/icons/Star';
import BarChartIcon from '@material-ui/icons/BarChart';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';

import Spacer from "../common/Spacer"

// Stile
const styles = {
  footer_container: {
    position: 'fixed',
    bottom: 0,
    width:'100%',
    height:70,
    background: '#373a40',
    alignItems: "flex-start",
  },

  footer_icon: {
    color: '#f6f5f5',
    '&$selected': {
      color: '#19d3da'
    },
  },

  // NOTE: You need to include a `.selected` class in your
  // styles rules for the "&$selected" selector to work.
  selected: {}

}

// Footer
class Footer extends Component {

    // Cambio di voce del menu
    cambiaMenu = (event, value) => {
      this.props.aggiornaMenuSelezionato(value)
    }


    render(){
          const {classes} = this.props;
          const {login} = this.props;
          const {menuSelezionato} = this.props;

          return(
            <Fragment>
              <Spacer />
              {
                login ? 
                    <BottomNavigation
                        className={classes.footer_container}
                        showLabels
                        value={menuSelezionato}
                        onChange={this.cambiaMenu}
                    >
                      {/* 1- Home */ }
                      <BottomNavigationAction
                          classes={{
                            root: classes.footer_icon,
                            selected: classes.selected
                          }}
                          label="Home"
                          icon={<Home />}
                          component={Link}
                          to="/"
                      />

                      {/*  2- Giocatori */ }
                      <BottomNavigationAction
                          classes={{
                            root: classes.footer_icon,
                            selected: classes.selected
                          }}
                          label="Giocatori"
                          icon={<EmojiPeopleIcon />}
                          component={Link}
                          to="/giocatori"
                      />

                      {/*  3- Partite */ }
                      <BottomNavigationAction
                          classes={{
                            root: classes.footer_icon,
                            selected: classes.selected
                          }}
                          label="Partite"
                          icon={<SportsTennis />}
                          component={Link}
                          to="/partite"
                      />

                    {/*  4- Classifica */ }
                      <BottomNavigationAction
                          classes={{
                            root: classes.footer_icon,
                            selected: classes.selected
                          }}
                          label="Rank"
                          icon={<BarChartIcon />}
                          component={Link}
                          to="/classifica"
                      />

                    {/*  5- Campionati */ }
                      {/* <BottomNavigationAction
                          classes={{
                            root: classes.footer_icon,
                            selected: classes.selected
                          }}
                          label="Campionati"
                          icon={<BarChartIcon />}
                          component={Link}
                          to="/tornei"
                      /> */}


                    </BottomNavigation>
                :
                    ''
              }    
            </Fragment>
           )

    }
}

export default withStyles(styles)(Footer)
