// Classe per  nominare correttamente i campi da/verso le chiamate API
class dataMask {
    
    encode = (payload) => {
            let newPayload = {
                genere: payload.genere, 
                Rank_Iniziale: payload.livello,
            }
            return newPayload;
        }
    
    decode = (payload) => {
        let newPayload = {
            id: payload.data.id,
            nome: payload.data.nome,
            cognome:payload.data.cognome,
            email: payload.data.email,
            genere: payload.data.genere,
            avatarUrl:payload.data.Immagine,
            stato: payload.data.IDStato, 
        }
        return newPayload;
    }
}

var utenteDatiTecnici = new dataMask();
export default utenteDatiTecnici


