// Classe per  nominare correttamente i campi da/verso le chiamate API
class dataMask {
    
    encode = (payload) => {
            let newPayload = {
                email: payload.email, 
                password: payload.password,
                nome: payload.nome,
                cognome: payload.cognome,
                genere: payload.genere,
                livello: payload.livello
            }
            return newPayload;
        }
    
    decode = (payload) => {
            let newPayload = {
                token: payload.data.token,
            }
            return newPayload;
        } 
}

var iscrizione = new dataMask();
export default iscrizione


