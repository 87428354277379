// Componenti React
import React, {Component, Fragment} from 'react';
import DatiUtente from './DatiUtente';
import DatiTecnici from './DatiTecnici';
import Avatar from './Avatar';
import IscrizioneAvvenuta from './IscrizioneAvvenuta'



class GestioneIscrizione extends Component{
 
  constructor(props) {
    super(props);
    this.state = {
      //  0 = Utente nuovo, 1 = Inserimento dati tecnici, 2 =  Inserimento Avatar, 3 = iscrizione completa
      faseIscrizione :0,
      datiUtente: {
        token: '',
        avatarUrl:'',
        ricordami: false,
      },
    }
  }

  // Gestione del completamento dati (quando l'utente è stato invitato da altri)
  onCompletamentoInvito = (datiNuovoUtente) => 
  {

  }


  // Gestione dell'avvenuto inserimento utente con relativi dati
  onInserimentoUtente = (datiNuovoUtente) => {
    let datiUtente = this.state.datiUtente;
    datiUtente['token'] = datiNuovoUtente.token;
    console.log(datiNuovoUtente.token)
    this.setState({datiUtente})
    this.setState({faseIscrizione:1})
  }


  // Gestione dell'avvenuto inserimento dell'avatar
  onInserimentoDatiTecnici = () => {
    this.setState({faseIscrizione:2})
  }

  // Gestione dell'avvenuto inserimento dell'avatar
  onInserimentoAvatar = (avatarData) => {
    let datiUtente = this.state.datiUtente;
    datiUtente['avatarUrl'] = avatarData.avatarUrl;
    this.setState({datiUtente})
    this.setState({faseIscrizione:3})
  }


  // Gestione del login dopo l'iscrizione completata
  loginRedirect = () => {
    let datiUtente = this.state.datiUtente;
    datiUtente['ricordami'] = true;
    this.props.onLogin(datiUtente)
  }

  // Mostro lo stato avanzamento 
  render(){
    return (
          <Fragment>
            {this.state.faseIscrizione === 0 &&  <DatiUtente  onInserimentoUtente = {this.onInserimentoUtente} />}
            {this.state.faseIscrizione === 1 &&  <DatiTecnici token = {this.state.datiUtente.token} onInserimentoDatiTecnici = {this.onInserimentoDatiTecnici}/>}
            {this.state.faseIscrizione === 2 &&  <Avatar      token = {this.state.datiUtente.token} onInserimentoAvatar = {this.onInserimentoAvatar}/>}
            {this.state.faseIscrizione === 3 &&  <IscrizioneAvvenuta token = {this.state.datiUtente.token} onloginRedirect = {this.loginRedirect}/>}
          </Fragment>
    )
  }
}

export default GestioneIscrizione
