// Classe per  nominare correttamente i campi da/verso le chiamate API
class dataMask {
    
    encode = (payload) => {
            let newPayload = {
                password: payload.password                    
            }
            return newPayload;
        }
    
    decode = (payload) => {
            let newPayload = {
                richiestaInviata: "ok",
            }
            return newPayload;
        }   

    
}

var modificaPassword = new dataMask();
export default modificaPassword


