
// Classe per  nominare correttamente i campi da/verso le chiamate API
class dataMask {
    
    encode = (payload) => {
            let formData = new FormData(); // instantiate it
            if(payload.nome != null)        formData.append('nome', payload.nome)
            if(payload.cognome != null)     formData.append('cognome', payload.cognome)
            if(payload.email != null)       formData.append('email', payload.email)
            if(payload.genere != null)      formData.append('genere', payload.genere)
            if(payload.cellulare != null)   formData.append('cellulare', payload.cellulare)
            if(payload.livello != null)     formData.append('Rank_Iniziale', payload.livello)
            if(payload.avatarFile != null)  formData.append('Immagine', payload.avatarFile)         
            return formData;
        }

    decode = (payload) => {
            let newPayload = {
                id: payload.data.id,
                nome: payload.data.nome,
                cognome:payload.data.cognome,
                email: payload.data.email,
                genere: payload.data.genere,
                avatarUrl:payload.data.Immagine,
                stato: payload.data.IDStato,
                rank: payload.data.Rank_Attuale > 0 ? payload.data.Rank_Attuale : "in attesa",
                messaggio: payload.data.messaggio,
                partiteMancanti: payload.data.cellulare,
                token: payload.data.token,

            }
            return newPayload;
        }
   
}


var utente = new dataMask();
export default utente


