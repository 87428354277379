import axios from 'axios';
import apiUrls from "./apiUrls"
import apiMask from '../dataMasker/apiDataMask';

class APIManager {
    
    // Funzione di chiamata POST
    post = (dataType, endpoint, payload, onSuccess, onError, header) => {

      const url = apiUrls[endpoint]
      // Verifico i dati passati come header/token
      let parameters = null   
      if(header)
        parameters = { 
          Authorization: 'Bearer ' + header.token,
          }
 
      const options = {
        method: 'post',
        url: url,
        headers: parameters,
        data: apiMask.encode(dataType,payload)
      };
      
      axios(options)          
      .then ((result) => {onSuccess(apiMask.decodeOK(dataType,result))})
      .catch((error)  => {console.log(error); 
        //onError(apiMask.decodeKO('',error))
      })
      return;

    }


    // Funzione di chiamata GET
    get = (dataType, endpoint, payload, onSuccess, onError, header = null) => {
      let url =  apiUrls[endpoint]
      
      // Non mi piace...
      if(payload.appendURL)
        url = url + "/" + payload.appendURL

      // Verifico i dati passati come header/token
      let parameters = null      
      if(header)
        parameters = { Authorization: 'Bearer ' + header.token,}
        
      const options = {
        method: 'get',
        url: url,
        headers: parameters,
        data: apiMask.encode(dataType, payload)
      };

      axios(options)          
      .then ((result) => {onSuccess(apiMask.decodeOK(dataType,result))})
      .catch((error)  => {onError(apiMask.decodeKO('',error))})
      return;

    }

    // Funzione di chiamata PUT
    put = (dataType, endpoint, payload, onSuccess, onError, header) => {

      let url = apiUrls[endpoint]
      
      // Non mi piace...
      if(payload.appendURL)
        url = url + "/" + payload.appendURL
      
      // Verifico i dati passati come header/token
      let parameters = null   
      if(header)
        parameters = { Authorization: 'Bearer ' + header.token,}

      const options = {
        method: 'put',
        url: url,
        headers: parameters,
        data: apiMask.encode(dataType,payload)
      };

      axios(options)          
      .then ((result) => {onSuccess(apiMask.decodeOK(dataType,result))})
      .catch((error)  => {onError(apiMask.decodeKO('',error))})
      return;

    }


    // Funzione di chiamata GET
    delete = (dataType, endpoint, payload, onSuccess, onError, header) => {

      const url = apiUrls[endpoint] 

      // Verifico i dati passati come header/token
      let parameters = null   
      if(header)
        parameters = { Authorization: 'Bearer ' + header.token,}

      const options = {
        method: 'delete',
        url: url,
        headers: parameters,
        data: apiMask.encode(dataType,payload)
      };

      axios(options)          
      .then ((result) => {onSuccess(apiMask.decodeOK(dataType,result))})
      .catch((error)  => {onError(apiMask.decodeKO('',error))})
      return;

    }
}

// Esporto una istanza della classe API
var API = new APIManager();
export default API