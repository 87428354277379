// Componenti React
import React,{Fragment, Component} from 'react';
import sortBy from 'sort-by';
import escapeRegExp from 'escape-string-regexp'

// Componenti Padel Rank
import RicercaGiocatoreCard from './RicercaGiocatoreCard';
import NuovoGiocatoreCard from './NuovoGiocatoreCard';

// Componenti Material UI
import TextField from '@material-ui/core/TextField';

import { withStyles } from '@material-ui/core/styles';
const styles = {

  ricerca: {
      display: 'flex',

      '& label.Mui-focused': {
         color: '#19d3da',
       },

       '& .MuiInput-underline:after': {
         borderBottomColor: '#19d3da',
       },

    },

};


class RicercaGiocatore extends Component {

  // Definizione dello stato
  state={
    stringaDiRicerca: '',
  }

  // Funzione per la gestione della ricerca all'interno della lista Giocatori (aggiorno lo stato con la nuova stringa di ricerca)
  gestioneRicerca = (newStringaDiRicerca) => {
    this.setState({
      stringaDiRicerca:newStringaDiRicerca
    })
  }

  render(){

    // Variabili di comodo
    const {classes} = this.props;
    const {dbGiocatori} = this.props;
    const {onSelezionaGiocatore} = this.props;

    // Prendo solo i giocatori aggiunti ai preferiti
    //const dbPreferiti = dbGiocatori.filter((prevGiocatore) => prevGiocatore.Preferito)
    const dbPreferiti = dbGiocatori.filter(giocatore => giocatore.Preferito)
    
    // Ordino i giocatori preferiti per Ranking
    dbPreferiti.sort(sortBy('Cognome', 'Nome'))

    // Inizialmente mostro solo i giocatori preferiti
    // Se però effettuo una ricerca, mostro il risultato della ricerca tra tutti i giocatori
    let dbGiocatoriVisualizzati = dbPreferiti
    if(this.state.stringaDiRicerca !== ''){
      const espressioneDiRicerca = new RegExp(escapeRegExp(this.state.stringaDiRicerca),'i')
      dbGiocatoriVisualizzati=dbGiocatori.filter((giocatore)=>espressioneDiRicerca.test(giocatore.Nome+' '+ giocatore.Cognome+' '+ giocatore.Email))
    }
    

    return (
      <Fragment>
        <TextField
          id="standard-search"
          label="Ricerca per nome, cognome o email.."
          type="search"
          className={classes.ricerca}
          onChange={(event) => this.gestioneRicerca(event.target.value)}
        />

        <br/>
        {
          // Mappo il singolo giocatore
          dbGiocatoriVisualizzati.map((giocatore, index) => (
              <RicercaGiocatoreCard key={index} rank={index} giocatore={giocatore} onSelezionaGiocatore={onSelezionaGiocatore} />
            )
          )
        }
        {this.state.stringaDiRicerca !== '' ?  <NuovoGiocatoreCard onAggiungiGiocatore = {onSelezionaGiocatore}/> : '' }
      </Fragment>
    )
  }
}

export default withStyles(styles)(RicercaGiocatore)
