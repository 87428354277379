
//Definizione URLs per chiamate APP
const baseUrl = 'https://padel.stealth.farm/api/'

const apiUrls = {
   
  login:              baseUrl + 'auth/login',
  fastlogin:          baseUrl + 'auth/fastlogin',
  iscrizione:         baseUrl + 'auth/register',
  avatar:             baseUrl + 'user/edit',
  recuperoPassword:   baseUrl + 'auth/recover',
  utente:             baseUrl + 'user',
  iscrizionesuinvito: baseUrl + 'user',
  utenteModifica:     baseUrl + 'user/edit',
  cambioPassword:     baseUrl + 'auth/reset',
  giocatori:          baseUrl + 'user/search',
  preferiti:          baseUrl + 'user/preferiti',
  aggiungiPreferito:  baseUrl + 'user/preferiti', 
  partite:            baseUrl + 'partite',
  
};

export default apiUrls