// Componenti React
import React, {Component} from 'react';
// Compose per utilizzare multipli Wrapper (withstyle e withcookies)
import { compose } from 'recompose'

// Componenti Material UI
//import Fab from '@material-ui/core/Fab';
//import LiveHelp from '@material-ui/icons/LiveHelp';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import {withCookies} from 'react-cookie';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import Chip from '@material-ui/core/Chip';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

// Utilizzo tema standard
const theme = createMuiTheme();


// Stili
const styles = {

  fab: {
    position: 'fixed',
    bottom: 85,
    right: 15,
    backgroundColor:'#19d3da',
    color: 'white',
  },
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  root: {
    marginBottom: 10,
    marginTop: 10,
    maxWidth: 600,
  },

  chip: {
    backgroundColor:'#19d3da',
  },

  
  button: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor:'#373a40',
    "&:hover": {
      backgroundColor: "#373a40"
    },
    color: 'white',
  },

  link: {
    color:'#373a40',
  },

  logo: {
    maxWidth:400,
  }


};


// Class Component
class Home extends Component {

  render(){
    const {classes} = this.props;

    return <Container component="main" maxWidth="sm"> 
          <div align="center">
            <img src={`images/layout/Logo.png`} width="250px" alt="Padel Rank" className={classes.logo}/>
          </div>

          {/* <Fab aria-label="add" className={classes.fab} >
            <LiveHelp />
          </Fab> */}
          {/* <div height='50px' width='400px'></div>
            <Button variant="contained" component="span" className={classes.button} onClick={this.addToHome}>
              TBD > Aggiungi ad home
            </Button> */}
        {/* <Card className={classes.root}>
            <CardActionArea>
              <CardMedia
                component="img"
                alt="Contemplative Reptile"
                height="140"
                image="/images/cards/Home.jpg"
                title="Contemplative Reptile"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Benvenuto in Padel Rank!
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Scopri come funziona, guarda la guida.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card> */}

          <Card className={classes.root}>
            <CardActionArea>
              <CardMedia
                component="img"
                alt="Contemplative Reptile"
                height="140"
                image="/images/cards/Rank.jpg"
                title="Contemplative Reptile"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Ti mancano {this.props.partiteMancanti} partite!
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  E poi vedrai il tuo rank aggiornarsi!
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>

      </Container>
  }
}


export default compose(
    withStyles(styles),
    withCookies,  
  )   (Home)