// Componenti React
import React,{Fragment, Component} from 'react';
import { withRouter } from 'react-router-dom';

// Compose per utilizzare multipli Wrapper (withstyle e withcookies)
import { compose } from 'recompose'

// Componenti PadelRank
import DataItaliana from "../../common/DataItaliana"

// Componenti Material UI
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
//import LockIcon from '@material-ui/icons/Lock';
//import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';


import { withStyles } from '@material-ui/core/styles';


const styles = {

  card: {
    marginTop: 10,
    marginBottom: 10,
  },

  card_header: {
    backgroundColor: '',
  },

  avatar:{
    borderColor: 'grey',
    borderStyle: 'solid',
    height: '50px',
    width: '50px',
  },
  avatar_win:{
    borderColor: '#19d3da',
    borderStyle: 'solid',
    height: '50px',
    width: '50px',
  },

  gridItem_giocatore: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection:'column',
    backgroundColor:'',
  },

  gridItem_giocatore_nome: {
    fontSize: 12,
  },

  gridItem_punteggio: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection:'column',
    // backgroundColor:'lightgrey',
  },

  gridItem_paper: {
    width: "100%",
    height: "100%",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderStyle: 'dashed',
  },

  gridItem_paper_win: {
    width: "100%",
    height: "100%",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderStyle: 'dashed',
    borderColor: '#373a40',
  },

  gridItem_paper_testo: {
    fontWeight: "lighter",
  },
};


// Class Component
class PartitaCard extends Component {
    
  // Se voglio modificare la partita, vengo redirezionato al componente corrispondente
  onModificaPartita = () => {
    this.props.history.push("/modificapartita/" + this.props.partita.ID);
  }
  
    // Ricavo i dati di un giocatore
  ricavaDatiGiocatore = (dbGiocatori, idGiocatore) => {
      let datiGiocatore = {}
      // Devo usare Map per evitare ritorni un array
      dbGiocatori.filter(giocatore => giocatore.ID === idGiocatore).map((giocatore,index) => {return datiGiocatore = giocatore})
      return datiGiocatore
  }
  
  render(){
    const {classes} = this.props;
    const {partita} = this.props;
    return(
      <Fragment>
      <Card className={classes.card} variant="outlined">
        <CardHeader
            className={classes.card_header}
            action={
              <IconButton aria-label="settings" onClick={partita.IS_Confermata ? this.onPartitaBloccata : this.onModificaPartita}>
                {partita.IS_Confermata ? '' : <EditIcon/>}
              </IconButton>
            }
            title={ <Typography className={classes.card_datigiocatore_titolo}> <DataItaliana data={partita.DataPartita} tipo="TXT"/> </Typography>}
        />
        <CardContent>
        <Grid container spacing={1}>
          <Grid xs={3} className={classes.gridItem_giocatore} item>
            <Avatar className={partita.Vincitore===1 ? classes.avatar_win : classes.avatar} src={partita.Giocatori.Avatar1} />
            <Typography className={classes.gridItem_giocatore_nome}> {`${partita.Giocatori.Nome1} ${String(partita.Giocatori.Cognome1)[0]}.`}  </Typography>
          </Grid>
          <Grid xs={3} className={classes.gridItem_giocatore} item>
            <Avatar className={partita.Vincitore===1 ? classes.avatar_win : classes.avatar} src={partita.Giocatori.Avatar2} />
              <Typography className={classes.gridItem_giocatore_nome}> {`${partita.Giocatori.Nome2} ${String(partita.Giocatori.Nome2)[0]}.`}  </Typography>
          </Grid>
          {
            partita.Games.map((game, index) => (
              <Grid key={index} xs={2} className={classes.gridItem_punteggio} item>
                <Paper variant="outlined" className={game.Squadra1 > game.Squadra2 ? classes.gridItem_paper_win : classes.gridItem_paper}> <Typography variant="h4" className={classes.gridItem_paper_testo}>{game.Squadra1}</Typography></Paper>
              </Grid>
              )
            )
          }
        </Grid>

        <Grid container spacing={1}>
            <Grid xs={3} className={classes.gridItem_giocatore} item>
              <Avatar className={partita.Vincitore===2 ? classes.avatar_win : classes.avatar} src={partita.Giocatori.Avatar3} />
                <Typography className={classes.gridItem_giocatore_nome}> {`${partita.Giocatori.Nome3} ${String(partita.Giocatori.Cognome3)[0]}.`} </Typography>
            </Grid>
            <Grid xs={3} className={classes.gridItem_giocatore} item>
              <Avatar className={partita.Vincitore===2 ? classes.avatar_win : classes.avatar} src={partita.Giocatori.Avatar4} />
                <Typography className={classes.gridItem_giocatore_nome}> {`${partita.Giocatori.Nome4} ${String(partita.Giocatori.Cognome4)[0]}.`} </Typography>
            </Grid>
            {
              partita.Games.map((game, index) => (
                <Grid key={index} xs={2} className={classes.gridItem_punteggio} item>
                  <Paper variant="outlined" className={game.Squadra2 > game.Squadra1 ? classes.gridItem_paper_win : classes.gridItem_paper}> <Typography variant="h4" className={classes.gridItem_paper_testo}>{game.Squadra2}</Typography></Paper>
                </Grid>
                )
              )
            }
          </Grid>
        </CardContent>
      </Card>

      </Fragment>
    )
  }
}

export default compose(
  withStyles(styles),
  withRouter, 
) (PartitaCard)
